export const SeguridadelEctronicaIcon = (props) => {
    return (
        <svg
            width={38}
            height={30}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g clipPath="url(#icono-seguridadelectronica_svg__clip0)">
                <path
                    d="M34.663 13.744L16.58 3.4c-1.393-.8-3.3-.368-4.218.95l-3.5 5.007a2.91 2.91 0 00.931 4.187l3.812 2.2-.687.919a2.905 2.905 0 00-.582 1.737c0 .638.232 1.244.613 1.738l-1.012 1.525H7v-2.006c0-.37-.3-.663-.669-.663H3.67a.674.674 0 00-.669.669v6.675c0 .369.3.669.669.669h2.669a.67.67 0 00.668-.67v-.668h4.931a4.009 4.009 0 003.332-1.781l1.094-1.644c.212.044.425.088.637.088a3.045 3.045 0 002.606-1.475l.731-1.225 5.675 3.275a.69.69 0 00.526.062.672.672 0 00.406-.344l.494-.987c.08.006.156.025.243.025a4.673 4.673 0 004.669-4.669c0-.281-.044-.575-.106-.875l2.4-1.2a.671.671 0 00.369-.581.702.702 0 00-.35-.594zM5.669 25.67H4.33v-5.331h1.331l.007 5.331zm8.481-2.519a2.658 2.658 0 01-2.219 1.188H7V23h4.931c.45 0 .863-.225 1.113-.593l.925-1.388 1.144.688-.963 1.443zm4.3-2.975c-.469.775-1.563 1.05-2.337.588l-1.694-1.013a1.566 1.566 0 01-.757-1.337c0-.338.113-.669.313-.931l.787-1.05 4.413 2.543-.725 1.2zm7.606 1.232l-15.6-9a1.572 1.572 0 01-.787-1.363c0-.325.1-.637.287-.9l1.669-2.4 16.5 9.519-2.069 4.144zm2.044-1.113l1.394-2.794 1.481-.737c.006.081.025.169.025.244 0 1.687-1.269 3.068-2.9 3.287zm.925-4.05L12.394 6.65l1.062-1.512a1.873 1.873 0 012.469-.55l17 9.712-3.9 1.944z"/>
                <path d="M25 19a.669.669 0 100-1.337A.669.669 0 0025 19z"/>
            </g>
            <defs>
                <clipPath id="icono-seguridadelectronica_svg__clip0">
                    <path transform="translate(3 3)" d="M0 0h32v24H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};
