export const BankDepositIcon = (props) => {
    return (
        <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M24.6111 13.9721H1.38893C0.805534 13.9721 0.333313 14.4446 0.333313 15.0277V27.6944C0.333313 28.2773 0.805522 28.75 1.38893 28.75H24.6111C25.1945 28.75 25.6667 28.2775 25.6667 27.6944V15.0277C25.6667 14.4446 25.1945 13.9721 24.6111 13.9721ZM20.5379 26.6387H5.46173C5.07885 25.1603 3.92314 24.0045 2.44417 23.6212V19.1008C3.92263 18.7177 5.0784 17.5622 5.46173 16.0833H20.5379C20.921 17.5617 22.0765 18.7175 23.5555 19.1008V23.6217C22.0765 24.0043 20.9207 25.16 20.5374 26.6388L20.5379 26.6387Z"
                />
            <path
                d="M16.1667 21.361C16.1667 23.1073 14.7464 24.5275 13.0001 24.5275C11.2539 24.5275 9.83359 23.1073 9.83359 21.361C9.83359 19.6148 11.2539 18.1945 13.0001 18.1945C14.7464 18.1945 16.1667 19.6148 16.1667 21.361Z"
                />
            <path
                d="M17.9688 7.32968L13.7466 11.5518C13.3345 11.9639 12.666 11.9639 12.2541 11.5518L8.03199 7.32968C7.73011 7.0278 7.6393 6.57427 7.80325 6.17913C7.96572 5.78497 8.3513 5.52777 8.77813 5.52777H10.8894V2.36123C10.8894 1.19516 11.8345 0.25 13.0006 0.25C14.1667 0.25 15.1118 1.19516 15.1118 2.36123V5.52777H17.2231C17.6499 5.52777 18.0355 5.78498 18.1982 6.17938C18.3616 6.57428 18.2708 7.02785 17.969 7.32975L17.9688 7.32968Z"
                />
        </svg>
    );
};
