interface IBankCheckIcon {
    className?: string
}

export const BankCheckIcon = (props: IBankCheckIcon) => {
    return (
        <svg className={`${props.className}`} width="41" height="28" viewBox="0 0 41 28" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M3.69995 23.8592V3.73334H37.3243V17.0369V17.378L32.3538 23.8592H3.69995Z"/>
            <path
                d="M29.8197 22.1536L5.55173 21.9262V5.66633H35.8624V15.2176M32.3538 20.9029V17.0369H37.3243M37.3243 17.0369V17.378L32.3538 23.8592H3.69995V3.73334H37.3243V17.0369ZM10.7172 8.39527V19.1973M8.67051 17.0369H11.6918C13.3487 16.9232 13.4461 13.8531 11.6918 13.7394H9.84005C7.89081 13.7394 7.89081 10.6694 9.84005 10.6694H12.6664"
                stroke="white"/>
            <path
                d="M18.418 11.6931H30.2109M16.4688 14.422H27.4819M16.4688 17.0372H24.0708M29.0413 14.422H30.2109M16.4688 11.6931H17.151"
                stroke="white"/>
        </svg>
    );
};
