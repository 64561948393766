export const OrderFilterIcon = (props) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" width="20" height="20" fill="white" fillOpacity="0.01"/>
            <path
                d="M6.23969 9.71056H14.761C15.3972 9.71056 15.7351 8.90493 15.3123 8.39549L11.052 3.26553C10.9829 3.18205 10.898 3.11522 10.803 3.06944C10.708 3.02367 10.6049 3 10.5007 3C10.3965 3 10.2935 3.02367 10.1985 3.06944C10.1034 3.11522 10.0186 3.18205 9.94946 3.26553L5.68769 8.39549C5.26483 8.90493 5.60282 9.71056 6.23969 9.71056ZM9.94873 17.7345C10.0178 17.818 10.1027 17.8848 10.1977 17.9306C10.2928 17.9763 10.3958 18 10.5 18C10.6042 18 10.7072 17.9763 10.8022 17.9306C10.8973 17.8848 10.9821 17.818 11.0513 17.7345L15.3116 12.6045C15.7351 12.0959 15.3972 11.2902 14.7603 11.2902H6.23969C5.60356 11.2902 5.26557 12.0959 5.68843 12.6053L9.94873 17.7345Z"
                />
        </svg>
    );
};
