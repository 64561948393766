import {FilterOptionModel, TypeFilter} from '../models/filter-option.model';
import {PaymentOption} from '../enums/PaymentOption.enum';

const Utils = {
    defineSortLabel: (orderFilter: String) => {
        switch (orderFilter) {
            case 'price_asc':
                return 'Precio de mayor a menor';
            case 'price_desc':
                return 'Precio de menor a mayor';
            case 'recommended':
                return 'Recomendados';
            default:
                return 'Recomendados';
        }
    },
    defineTypeProductLabel: (typeProduct: String) => {
        switch (typeProduct) {
            case 'SIMPLE':
                return 'Simple';
            case 'GRUPO':
                return 'Agrupado';
            case 'KIT':
                return 'Kits';
        }
    },
    defineTypeStockLabel: (stockType: String) => {
        switch (stockType) {
            case 'STOCK_CDS':
                return 'Stock CDS';
            case 'STOCK_STORE':
                return 'Stock en tienda';
            case 'STOCK_NONE':
                return 'Sin Stock';
        }
    },
    getCurrencyFormat: (value) => {
        return (new Intl.NumberFormat(
            'es-CL',
            {
                style: 'currency',
                currency: 'CLP',
            }).format(value));
    },
    transformFilters: (filters: FilterOptionModel[]) => {
        const stockFilter       = filters.filter(filter => filter != null && filter.type === TypeFilter.TYPE_STOCK)?.map(filter => filter.value).join('|');
        const officeFilter      = filters.find(filter => filter != null && filter.type === TypeFilter.TYPE_OFFICE)?.value;
        const typeProductFilter = filters.filter(filter => filter != null && filter.type === TypeFilter.TYPE_PRODUCT)?.map(filter => filter.value).join('|');
        const pageFilter        = filters.find(filter => filter != null && filter.type === TypeFilter.TYPE_PAGE)?.value;
        const categoryFilter    = filters.find(filter => filter != null && filter.type === TypeFilter.TYPE_CATEGORY)?.value;
        const sortFilter        = filters.find(filter => filter != null && filter.type === TypeFilter.TYPE_SORT)?.value;
        const textSearchFilter  = filters.find(filter => filter != null && filter.type === TypeFilter.TYPE_TEXT_SEARCH)?.value;
        const pricesFilters     = filters.filter(filter => filter != null && filter.type === TypeFilter.TYPE_RANGE_PRICE);
        const dynamicFilters    = filters.filter(filter => filter != null && filter.type === TypeFilter.TYPE_DYNAMIC_VALUE);
        const qtyFilter         = filters.find(filter => filter != null && filter.type === TypeFilter.TYPE_QTY)?.value;

        let dynamicF = {};

        dynamicFilters.map(value => {
            if (Object.keys(value.value)[0] in dynamicF) {
                let previousValue                     = dynamicF[Object.keys(value.value)[0]];
                dynamicF[Object.keys(value.value)[0]] = [previousValue, Object.values(value.value)].join('|');
            } else {
                dynamicF[Object.keys(value.value)[0]] = [Object.values(value.value)[0]].join('|');
            }
        });

        let pmax = pricesFilters?.find(filter => filter !== null && filter.key === 'pmax')?.value;
        let pmin = pricesFilters?.find(filter => filter !== null && filter.key === 'pmin')?.value;

        return {
            ...(stockFilter && {stock: stockFilter}),
            ...(officeFilter && {o: officeFilter}),
            ...(qtyFilter && {qty: qtyFilter}),
            ...(typeProductFilter && {type: typeProductFilter}),
            ...(pageFilter && {page: pageFilter}),
            ...(categoryFilter && {cat: categoryFilter}),
            ...(sortFilter && {sort: sortFilter}),
            ...(textSearchFilter && {q: textSearchFilter}),
            ...(pmax && {pmax: pmax}),
            ...(pmin && {pmin: pmin}),
            ...(Object.keys(dynamicF).length > 0 && {ats: dynamicF}),
        };
    },
    getPaymentMethodLabel: (type: string): string => {
        switch (type) {
            case PaymentOption.CASH:
                return 'Efectivo';
            case PaymentOption.DEBIT:
                return 'Tarjeta de Débito';
            case PaymentOption.CREDIT:
                return 'Tarjeta de Crédito';
            case PaymentOption.CREDIT_LINE:
                return 'Linea De Crédito';
            case PaymentOption.BANK_CHECK:
                return 'Cheque';
            case PaymentOption.WIRE_TRANSFER:
                return 'Tranferencia Bancaria';
            case PaymentOption.FUTURE_PAY:
                return 'Documento Por Pagar';
            case PaymentOption.BANK_DEPOSIT:
                return 'Depósito Bancario';
        }
    },
    getNationalsBanksOptions: () => {
        return [
            {value: '001', label: 'Banco de Chile/Edwards-Citi'},
            {value: '009', label: 'Banco Internacional'},
            {value: '012', label: 'Banco Estado'},
            {value: '014', label: 'Scotiabank'},
            {value: '016', label: 'Banco Crédito e Inversiones'},
            {value: '028', label: 'Banco BICE'},
            {value: '031', label: 'HSBC Bank'},
            {value: '033', label: 'Citibank'},
            {value: '037', label: 'Santander'},
            {value: '039', label: 'Banco Itaú'},
            {value: '049', label: 'Banco Security'},
            {value: '051', label: 'Banco Falabella'},
            {value: '053', label: 'Banco Ripley'},
            {value: '055', label: 'Banco Consorcio'},
            {value: '504', label: 'Scotiabank Azul'},
            {value: '059', label: 'Banco BTG Pactual Chile'},
            {value: '027', label: 'Corpbanca'},
            {value: '507', label: 'Banco del Desarrollo'},
            {value: '017', label: 'Banco do Brasil'},
            {value: '040', label: 'Banco Sudameris'},
            {value: '046', label: 'ABN AMRO Bank'},
        ];
    },
    calculateMargin: (priceWithIVA: number, sap_cost: number) => {
        const priceWithOutIVA = priceWithIVA / 1.19;
        return Number(((priceWithOutIVA - sap_cost) / priceWithOutIVA) * 100);
    },
    capitalize: (str: string) => {
        if (!str) {
            return str;
        }
        return str.toLowerCase().replace(/(?:^|\s)\S/g, x => x.toUpperCase());
    },
    roundNumber: (num: number, decimals: number) => {
        if(!num) return 0;
        const factor = Math.pow(10, decimals);
        return Math.round((num + Number.EPSILON) * factor) / factor;
    },
    isAnIOption: (obj: any) => {
        try {
            return 'value' in obj && 'label' in obj;
        } catch (_) {
            return false;
        }
    },
    getStrParams: (params: any) => {
        //filter empty, undefined or null elements
        const filtered = [];
        for (const key in params) {
            let value = params[key];
            if(Utils.isAnIOption(params[key])) {
                value = params[key].value;
            }
            if (value || value === 0) {
                filtered.push([key, value]);
            }
        }
        return new URLSearchParams(filtered).toString();
    },
    get_tax: () => 1.19,
};

export default Utils;
