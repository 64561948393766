export const AlertIconTwo = (props) => {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.5 0C4.084 0 0.5 3.584 0.5 8C0.5 12.416 4.084 16 8.5 16C12.916 16 16.5 12.416 16.5 8C16.5 3.584 12.916 0 8.5 0ZM9.3 12H7.7V10.4H9.3V12ZM9.3 8.8H7.7V4H9.3V8.8Z"
                />
        </svg>
    );
};