import {Auth, ConvertAuth, LoginResponse} from '../../interfaces';
import AxiosInstance from './axios';
import {AxiosPromise} from 'axios';

export default class AuthService {
    async logIn(email: any, password: any) {
        return await AxiosInstance.post(
            '/login',
            {email: email, password: password},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
    }

    async logOut() {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
        try {
            this.removeAuth();
            return await AxiosInstance.post(
                '/logout',
                {},
                {
                    headers: {
                        'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                        'Content-Type': 'application/json',
                    },
                },
            );
        } catch (error) {
            return error.response;
        }
    }

    async me() {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
        return await AxiosInstance.get(
            '/users/me',
            {
                headers: {
                    'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
    }

    async getEmployee(data) {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
        return await AxiosInstance.get(
            '/users/employee',
            {
                params: data,
                headers: {
                    'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
    }

    setAuth(authData: LoginResponse) {
        localStorage.setItem('auth', JSON.stringify(authData));
    }

    removeAuth() {
        localStorage.removeItem('auth');
    }

    authTokenExist() {
        return !!localStorage.getItem('auth');
    }

    getAccessToken() {
        return JSON.parse(localStorage.getItem('auth')).access_token;
    }

    passwordRecovery(email: String): AxiosPromise {
        return AxiosInstance.post('/password-recovery', {email}, {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
    }

    changePassword(password: String, password_confirmation: String, token: String | String[], email: String | String[]): AxiosPromise {
        return AxiosInstance.post('/change-password', {password, password_confirmation, token, email}, {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
    }

    async tokenRefresh() {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
        return AxiosInstance.post<LoginResponse>(
            '/refresh-token',
            {
                refresh_token: auth.refresh_token,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
    }
}
