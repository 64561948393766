export const ContributorDataIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.27203 12.1026H13.6293C16.0967 12.1026 18.1155 14.1194 18.1155 16.5845V18.1851C18.1155 18.6335 17.7487 19 17.2998 19H5.60129C5.15267 19 4.78584 18.6335 4.78584 18.1851V16.5845C4.78584 14.1194 6.80438 12.1026 9.27203 12.1026ZM0 0H8.23153V11.1547H0V0ZM2.40321 4.45663H6.89038V4.938H2.40321V4.45663ZM2.40321 6.03966H6.89038V6.52103H2.40321V6.03966ZM2.40321 7.62219H6.89038V8.10356H2.40321V7.62219ZM2.40321 9.20496H6.89038V9.68608H2.40321V9.20496ZM1.9593 4.938H1.3409V4.45663H1.9593V4.938ZM1.9593 6.52103H1.3409V6.03966H1.9593V6.52103ZM1.9593 8.10356H1.3409V7.62219H1.9593V8.10356ZM1.9593 9.68608H1.3409V9.20496H1.9593V9.68608ZM11.4507 3.72096C13.5917 3.72096 15.3274 5.45478 15.3274 7.59371C15.3274 9.73264 13.5917 11.4665 11.4507 11.4665C10.3321 11.4665 9.32433 10.993 8.6167 10.2358V4.95162C9.32433 4.19441 10.3321 3.72096 11.4507 3.72096Z"
                  fill="#005EB8"/>
        </svg>

    );
};
