export const CategoryFilterIcon = (props) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="20" height="20" transform="translate(0.5)" fill="white" fillOpacity="0.01"/>
            <rect x="0.5" width="20" height="20" fill="white" fillOpacity="0.01"/>
            <path
                d="M9.0185 2.5C9.21495 2.5 9.40336 2.57902 9.54228 2.71967C9.6812 2.86032 9.75924 3.05109 9.75924 3.25V6.25C9.75924 6.44891 9.6812 6.63968 9.54228 6.78033C9.40336 6.92098 9.21495 7 9.0185 7H7.53702V8.5H11.2407V7.75C11.2407 7.55109 11.3188 7.36032 11.4577 7.21967C11.5966 7.07902 11.785 7 11.9815 7H16.4259C16.6224 7 16.8108 7.07902 16.9497 7.21967C17.0886 7.36032 17.1666 7.55109 17.1666 7.75V10.75C17.1666 10.9489 17.0886 11.1397 16.9497 11.2803C16.8108 11.421 16.6224 11.5 16.4259 11.5H11.9815C11.785 11.5 11.5966 11.421 11.4577 11.2803C11.3188 11.1397 11.2407 10.9489 11.2407 10.75V10H7.53702V14.5H11.2407V13.75C11.2407 13.5511 11.3188 13.3603 11.4577 13.2197C11.5966 13.079 11.785 13 11.9815 13H16.4259C16.6224 13 16.8108 13.079 16.9497 13.2197C17.0886 13.3603 17.1666 13.5511 17.1666 13.75V16.75C17.1666 16.9489 17.0886 17.1397 16.9497 17.2803C16.8108 17.421 16.6224 17.5 16.4259 17.5H11.9815C11.785 17.5 11.5966 17.421 11.4577 17.2803C11.3188 17.1397 11.2407 16.9489 11.2407 16.75V16H6.79628C6.59982 16 6.41141 15.921 6.27249 15.7803C6.13358 15.6397 6.05553 15.4489 6.05553 15.25V7H4.57405C4.3776 7 4.18919 6.92098 4.05027 6.78033C3.91136 6.63968 3.83331 6.44891 3.83331 6.25V3.25C3.83331 3.05109 3.91136 2.86032 4.05027 2.71967C4.18919 2.57902 4.3776 2.5 4.57405 2.5H9.0185Z"
                />
        </svg>
    );
};
