import {configureStore} from '@reduxjs/toolkit';
import {ThunkAction} from 'redux-thunk';
import {AnyAction} from 'redux';
import cartsReducer from './slices/carts.slice';
import officeReducer from './slices/office.slice';
import officesReducer from './slices/offices.slice';
import loadingReducer from './slices/loading.slice';
import regionsReducer from './slices/regions.slice';
import citiesReducer from './slices/cities.slice';
import holidaysReducer from './slices/holidays.slice';
import categoriesReducer from './slices/categories.slice';
import productsReducer from './slices/products.slice';
import filtersReducer from './slices/filters.slice';
import groupedFiltersReducer from './slices/grouped-filters.slice';
import paymentOptionsReducer from './slices/payment-options.slice';
import uiReducer from './slices/ui.slice';
import deliveryPricesReducer from './slices/delivery-prices.slice';
import retrieveDaySliceReducer from './slices/retrieve-day.slice';
import cashRegisterSliceReducer from './slices/cash-register.slice';
import modalDiscountSliceReducer from './slices/modal-discount.slice';
import withdrawalReducer from './slices/withdrawal.slice';
import orderListReducer from './slices/orders-list.slice';
import pinpadReducer from './slices/pinpad.slice';

const DEBUG = process.env.NODE_ENV !== 'production';
const store = configureStore({
    reducer: {
        ui: uiReducer,
        carts: cartsReducer,
        office: officeReducer,
        cities: citiesReducer,
        offices: officesReducer,
        regions: regionsReducer,
        loading: loadingReducer,
        filters: filtersReducer,
        holidays: holidaysReducer,
        products: productsReducer,
        categories: categoriesReducer,
        ordersList: orderListReducer,
        retrieveDay: retrieveDaySliceReducer,
        paymentOptions: paymentOptionsReducer,
        deliveryPrices: deliveryPricesReducer,
        groupedFilters: groupedFiltersReducer,
        cashRegister: cashRegisterSliceReducer,
        discountModal: modalDiscountSliceReducer,
        withdrawal: withdrawalReducer,
        pinpadTransaction: pinpadReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            // Ignore these field paths in all actions
            ignoredActionPaths: ['payload.product', 'payload.item', 'payload.cart', 'payload.order', 'payload'],
            // Ignore these paths in the state
            ignoredPaths: ['carts', 'products', 'filters', 'groupedFilters', 'cashRegister', 'discountModal', 'withdrawal'],
        },
    }),
    devTools: DEBUG,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>

export default store;
