export const HomeIcon = (props) => {
    return (
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M15.793 8.26L8.593.252c-.303-.337-.885-.337-1.188 0L.205 8.26A.8.8 0 00.8 9.595h1.6V15.2a.8.8 0 00.8.801h2.4a.8.8 0 00.8-.8v-3.203h3.199V15.2a.8.8 0 00.8.801h2.4a.8.8 0 00.8-.8V9.594h1.6a.798.798 0 00.594-1.336z"
                fill="#005EB8"/>
        </svg>
    );
};
