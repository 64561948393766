import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IHoliday} from '../../interfaces/holiday';

interface RetrieveDaySlice {
    date: {
        year: number;
        month: number;
        day: number;
    };
    deliveryDate: {
        year: number;
        month: number;
        day: number;
    };
    scheduleDate: {
        year: number;
        month: number;
        day: number;
    };
}

const initialState: RetrieveDaySlice = {
    date: null,
    deliveryDate: null,
    scheduleDate: null,
};

export const retrieveDaySlice = createSlice({
    name: 'retrieveDay',
    initialState,
    reducers: {
        setRetrieveDate(state, action: PayloadAction<{ date: { year: number, month: number, day: number } }>) {
            if (action.payload.date != null) {
                state.date = {
                    day: action.payload.date.day,
                    month: action.payload.date.month,
                    year: action.payload.date.year,
                };
            } else {
                state.date = null;
            }
            return state;
        },
        setDeliveryDate(state, action: PayloadAction<{ days: number, isProvisioning: boolean, provisioningDays: number, holidays: IHoliday[] }>) {
            const date        = new Date();
            let days          = action.payload.days;
            days += action.payload.isProvisioning ? action.payload.provisioningDays : 0;
            let formattedDate = date.toISOString().slice(0, 10);
            let i             = 0;
            while (i < days) {
                date.setDate(date.getDate() + 1);
                formattedDate = date.toISOString().slice(0, 10);
                if (date.getDay() !== 0 && date.getDay() !== 6 && !action.payload.holidays.some(obj => obj.date && obj.date === formattedDate)) {
                    i++;
                }
            }
            state.deliveryDate = {
                year: date.getFullYear(),
                month: date.getMonth(),
                day: date.getDate(),
            };
        },
        setScheduleDate(state, action: PayloadAction<{ date: { year: number, month: number, day: number } }>) {
            if (action?.payload?.date) {
                state.scheduleDate = {
                    day: action.payload.date.day,
                    month: action.payload.date.month,
                    year: action.payload.date.year,
                };
            } else {
                state.scheduleDate = null;
            }
            return state;
        }
    },
});

export const {setRetrieveDate, setDeliveryDate, setScheduleDate} = retrieveDaySlice.actions;
export default retrieveDaySlice.reducer;
