export const ValidityIcon = (props) => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.2019 17.9874C15.3346 18.1201 15.486 18.177 15.6566 18.177C15.8271 18.177 15.9787 18.1201 16.1114 17.9874L17.8546 16.2443C18.1009 15.9979 18.1009 15.581 17.8546 15.3348C17.6082 15.0885 17.1913 15.0885 16.9451 15.3348L15.6568 16.6232L15.1831 16.1495C14.9367 15.9031 14.5198 15.9031 14.2736 16.1495C14.0273 16.3959 14.0273 16.8127 14.2736 17.0589L15.2019 17.9874Z"
                fill="#005EB8"/>
            <path
                d="M18.4418 4.76216H17.6271V4.57263C17.6271 3.70099 16.9261 3 16.0545 3C15.1829 3 14.4819 3.70102 14.4819 4.57263V4.76216H13.2504V4.57263C13.2504 3.70099 12.5493 3 11.6777 3C10.8061 3 10.1051 3.70102 10.1051 4.57263V4.76216H8.87359V4.57263C8.87359 3.70099 8.17257 3 7.30095 3C6.42934 3 5.72832 3.70102 5.72832 4.57263V4.76216H4.91365C3.85263 4.76216 3 5.61476 3 6.67581V19.0863C3 20.1474 3.8526 21 4.91365 21H18.4042C19.4652 21 20.3178 20.1474 20.3178 19.0863V6.69476C20.3558 5.63374 19.503 4.76209 18.442 4.76209L18.4418 4.76216ZM15.4861 4.59166C15.4861 4.26958 15.7513 4.02321 16.0545 4.02321C16.3766 4.02321 16.623 4.28847 16.623 4.59166V5.95588C16.623 6.27797 16.3577 6.52433 16.0545 6.52433C15.7324 6.52433 15.4861 6.25908 15.4861 5.95588V4.59166ZM11.1282 4.59166C11.1282 4.26958 11.3934 4.02321 11.6966 4.02321C12.0187 4.02321 12.2651 4.28847 12.2651 4.59166V5.95588C12.2651 6.27797 11.9998 6.52433 11.6966 6.52433C11.3745 6.52433 11.1282 6.25908 11.1282 5.95588V4.59166ZM6.75131 4.59166C6.75131 4.26958 7.01657 4.02321 7.31977 4.02321C7.64186 4.02321 7.88822 4.28847 7.88822 4.59166V5.95588C7.88822 6.27797 7.62297 6.52433 7.31977 6.52433C6.99768 6.52433 6.75131 6.25908 6.75131 5.95588V4.59166ZM19.3703 19.1054C19.3703 19.617 18.9534 20.0338 18.4418 20.0338L4.95128 20.0337C4.43966 20.0337 4.02282 19.6168 4.02282 19.1052V9.06312H19.3513V19.1052L19.3703 19.1054Z"
                fill="#005EB8"/>
            <path
                d="M6.44817 13.9327H8.39975C8.665 13.9327 8.89235 13.7054 8.89235 13.4401L8.89248 11.4696C8.89248 11.2044 8.66515 10.977 8.39988 10.977L6.44816 10.9769C6.18291 10.9769 5.95557 11.2042 5.95557 11.4695V13.44C5.95557 13.7054 6.1829 13.9327 6.44817 13.9327Z"
                fill="#005EB8"/>
            <path
                d="M10.7303 13.9327H12.6819C12.9471 13.9327 13.1745 13.7054 13.1745 13.4401V11.4696C13.1745 11.2044 12.9471 10.977 12.6819 10.977L10.7303 10.9769C10.465 10.9769 10.2377 11.2042 10.2377 11.4695V13.44C10.2187 13.7054 10.446 13.9327 10.7303 13.9327Z"
                fill="#005EB8"/>
            <path
                d="M14.9933 13.9327H16.9449C17.2102 13.9327 17.4375 13.7054 17.4375 13.4401V11.4696C17.4375 11.2044 17.2102 10.977 16.9449 10.977L14.9933 10.9769C14.7281 10.9769 14.5007 11.2042 14.5007 11.4695V13.44C14.5006 13.7054 14.7281 13.9327 14.9933 13.9327Z"
                fill="#005EB8"/>
            <path
                d="M6.44817 18.139H8.39975C8.665 18.139 8.89235 17.9117 8.89235 17.6464L8.89248 15.6758C8.89248 15.4106 8.66515 15.1832 8.39988 15.1832H6.44816C6.18291 15.1832 5.95557 15.4105 5.95557 15.6758V17.6463C5.95557 17.9117 6.18289 18.139 6.44816 18.139L6.44817 18.139Z"
                fill="#005EB8"/>
            <path
                d="M10.7303 18.139H12.6819C12.9471 18.139 13.1745 17.9117 13.1745 17.6464V15.6758C13.1745 15.4106 12.9471 15.1832 12.6819 15.1832H10.7303C10.465 15.1832 10.2377 15.4105 10.2377 15.6758V17.6463C10.2187 17.9117 10.446 18.139 10.7303 18.139V18.139Z"
                fill="#005EB8"/>
        </svg>

    );
};