import {IOffice} from '../../interfaces';
import {createSlice} from '@reduxjs/toolkit';
import {setOffice} from '../../services/office.service';

const initialState: IOffice = {
    id: null,
    code: null,
    city: null,
    name: null,
    provisioning_days: null,
    address: null,
    contact_phones: null,
};

export const officeSlice = createSlice({
    name: 'office',
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder.addCase(setOffice.fulfilled, (state, action) => {
            return {...state, ...action.payload};
        });
    }),

});

export default officeSlice.reducer;
