export const CreditLineIcon = (props) => {
    return (
        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="14.5" cy="14" r="14" fill="#CFD3D6"/>
            <path
                d="M3.74758 13.1273L6.52236 10.3525M3.74758 13.1273L1.6665 9.6588M3.74758 13.1273C2.24984 1.63325 22.0832 -3.61676 25.9458 12.4336"
                stroke="white" strokeWidth="1.5"/>
            <path
                d="M25.583 14.4668L22.6663 17.5002M25.583 14.4668L27.333 18.0835M25.583 14.4668C26.7497 27.4169 5.16634 30.9169 3.74742 15.2086"
                stroke="white" strokeWidth="1.5"/>
            <path
                d="M16.3345 20.3426V19.1361C17.8813 18.7958 19.026 17.5274 19.1497 16.0424C19.2734 14.7431 18.7166 13.5366 17.6029 12.7322C16.9841 12.2991 16.2416 12.1135 15.5301 12.1135H14.5711C14.2617 12.1135 13.9833 11.9897 13.7667 11.7422C13.5501 11.4947 13.4573 11.2163 13.4883 10.9069C13.5501 10.3501 14.0451 9.91696 14.6639 9.91696H15.9013C16.2107 9.91696 16.4891 10.1645 16.551 10.4429C16.6129 10.6904 16.8294 10.876 17.0769 10.876H18.4382C18.5928 10.876 18.7475 10.8141 18.8403 10.6904C18.9331 10.5666 18.995 10.4429 18.9641 10.2882C18.8094 8.83418 17.7266 7.72045 16.3035 7.5039V6.35924C16.3035 6.08081 16.056 5.83331 15.7776 5.83331H14.3545C14.0761 5.83331 13.8286 6.08081 13.8286 6.35924V7.53483C12.0652 7.90607 10.8587 9.54572 11.0133 11.371C11.199 13.1653 12.8077 14.5884 14.6948 14.5884H15.592C15.9013 14.5884 16.1798 14.7122 16.3963 14.9597C16.6129 15.2071 16.7057 15.4856 16.6748 15.7949C16.6129 16.3518 16.1179 16.7849 15.4992 16.7849H14.2617C13.9833 16.7849 13.6739 16.5374 13.612 16.259C13.5501 16.0115 13.3336 15.8259 13.0861 15.8259H11.6939C11.5393 15.8259 11.3846 15.8878 11.2918 16.0115C11.199 16.1352 11.1371 16.259 11.168 16.4137C11.3227 17.8677 12.4055 18.9814 13.8286 19.198V20.3426C13.8286 20.6211 14.0761 20.8686 14.3545 20.8686H15.7776C16.087 20.8686 16.3345 20.6211 16.3345 20.3426Z"
                fill="white"/>
        </svg>
    );
};
