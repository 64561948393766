export const ArrowTop = (props) => {
    const w = props.width || 54;
    const h = props.height || 200;
    return (
        <svg width={w} height={h} viewBox="0 0 54 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M41.647 62.1756C41.4421 54.0753 40.7934 46.3163 40.2033 38.1532C39.6133 29.9901 38.4036 23.3808 37.9182 15.8552L36.1431 17.2207C35.1313 20.7453 33.7142 24.1262 31.9231 27.2888C31.4041 27.9783 30.6847 28.4708 29.8738 28.6918C29.063 28.9127 28.2048 28.85 27.4293 28.5132C26.6538 28.1764 26.0032 27.5838 25.5759 26.8252C25.1487 26.0666 24.9682 25.1832 25.0617 24.3089C26.4068 20.768 28.2161 17.4428 30.4372 14.4291L34.9964 3.38207C35.2669 2.7347 35.6944 2.17359 36.2365 1.75464C36.7785 1.33569 37.4162 1.07355 38.0858 0.994344C38.7554 0.915146 39.4337 1.02164 40.0529 1.30323C40.6721 1.58482 41.2108 2.03166 41.6151 2.59921C44.2594 6.60264 46.5464 10.853 48.4468 15.296C50.6175 19.4773 52.1581 23.9848 53.0138 28.6578C53.1132 29.2601 53.0077 29.8793 52.7158 30.407C52.4239 30.9346 51.9642 31.3371 51.4172 31.5438C50.8701 31.7506 50.2706 31.7485 49.7237 31.538C49.1767 31.3274 48.7172 30.9218 48.4255 30.3921C45.6533 27.1522 43.1924 23.6332 41.0788 19.8867L44.1177 31.5516C45.4948 38.0448 46.4499 44.6275 46.9765 51.2541C48.1225 64.6261 48.2817 78.068 47.4527 91.4595C45.4915 117.247 38.7006 142.358 27.4694 165.356C21.891 178.477 13.6033 190.125 3.20449 199.46C1.65132 200.655 0.181972 198.554 1.24536 196.659C10.0584 183.916 18.0528 170.57 25.1755 156.708C31.3017 142.049 35.6562 126.637 38.1376 110.833C40.7284 94.7657 41.9032 78.4779 41.647 62.1756Z"
                fill="#00ADEF"/>
        </svg>
    );
};
