export const InputAlertIcon = (props) => {
    return (
        <svg
            width={21}
            height={20}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.782 0c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm1 15h-2v-2h2v2zm0-4h-2V5h2v6z"
                fill="#F44336"
            />
        </svg>
    );
};
