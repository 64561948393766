export const CashierIcon = (props) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.73644 16.6154H8.72996C7.80603 15.4985 7.30154 14.0937 7.30375 12.6442C7.30375 10.5338 8.351 8.66739 9.95534 7.53845H3.8999V13.7788C3.8999 14.5311 4.19875 15.2526 4.73071 15.7846C5.26266 16.3165 5.98414 16.6154 6.73644 16.6154Z"
                fill="#005EB8"/>
            <path
                d="M17.5153 6.40385H3.8999V5.83654C3.8999 5.08424 4.19875 4.36276 4.73071 3.8308C5.26266 3.29885 5.98414 3 6.73644 3H14.6787C15.431 3 16.1525 3.29885 16.6845 3.8308C17.2164 4.36276 17.5153 5.08424 17.5153 5.83654V6.40385Z"
                fill="#005EB8"/>
            <path
                d="M13.5441 17.75C14.2146 17.75 14.8786 17.6179 15.498 17.3613C16.1175 17.1047 16.6803 16.7287 17.1544 16.2545C17.6286 15.7804 18.0047 15.2176 18.2612 14.5981C18.5178 13.9787 18.6499 13.3147 18.6499 12.6442C18.6499 11.9737 18.5178 11.3098 18.2612 10.6903C18.0047 10.0709 17.6286 9.50801 17.1544 9.0339C16.6803 8.55978 16.1175 8.18369 15.498 7.92711C14.8786 7.67052 14.2146 7.53845 13.5441 7.53845C12.19 7.53845 10.8913 8.07638 9.9338 9.0339C8.97628 9.99141 8.43835 11.2901 8.43835 12.6442C8.43835 13.9984 8.97628 15.297 9.9338 16.2545C10.8913 17.2121 12.19 17.75 13.5441 17.75ZM12.9768 10.375C12.9768 10.2245 13.0366 10.0802 13.143 9.97384C13.2494 9.86745 13.3937 9.80768 13.5441 9.80768C13.6946 9.80768 13.8389 9.86745 13.9453 9.97384C14.0517 10.0802 14.1114 10.2245 14.1114 10.375V12.0769H15.246C15.3965 12.0769 15.5408 12.1367 15.6472 12.2431C15.7536 12.3495 15.8134 12.4938 15.8134 12.6442C15.8134 12.7947 15.7536 12.939 15.6472 13.0454C15.5408 13.1518 15.3965 13.2115 15.246 13.2115H13.5441C13.3937 13.2115 13.2494 13.1518 13.143 13.0454C13.0366 12.939 12.9768 12.7947 12.9768 12.6442V10.375Z"
                fill="#005EB8"/>
        </svg>
    );
};
