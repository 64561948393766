interface IDebitCardIcon {
    className: string
}

export const DebitCardIcon = (props: IDebitCardIcon) => {
    return (
        <svg className={`${props.className}`} width="30" height="23" viewBox="0 0 30 23"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 3.75C0 2.75544 0.395088 1.80161 1.09835 1.09835C1.80161 0.395088 2.75544 0 3.75 0H26.25C27.2446 0 28.1984 0.395088 28.9016 1.09835C29.6049 1.80161 30 2.75544 30 3.75V5.625H0V3.75Z"
            />
            <path
                d="M0 9.375V18.75C0 19.7446 0.395088 20.6984 1.09835 21.4016C1.80161 22.1049 2.75544 22.5 3.75 22.5H26.25C27.2446 22.5 28.1984 22.1049 28.9016 21.4016C29.6049 20.6984 30 19.7446 30 18.75V9.375H0ZM5.625 13.125C5.12772 13.125 4.65081 13.3225 4.29917 13.6742C3.94754 14.0258 3.75 14.5027 3.75 15V16.875C3.75 17.3723 3.94754 17.8492 4.29917 18.2008C4.65081 18.5525 5.12772 18.75 5.625 18.75H7.5C7.99728 18.75 8.47419 18.5525 8.82582 18.2008C9.17746 17.8492 9.375 17.3723 9.375 16.875V15C9.375 14.5027 9.17746 14.0258 8.82582 13.6742C8.47419 13.3225 7.99728 13.125 7.5 13.125H5.625Z"
            />
        </svg>
    );
};
