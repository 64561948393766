import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IPinPadSlice {
    activeTransaction: boolean,
}

const initialState: IPinPadSlice = {
    activeTransaction: false,
};

export const pinpadSlice = createSlice({
    name: 'pinpad',
    initialState,
    reducers: {
        changeTransactionStatus(state, action: PayloadAction<{ active: boolean }>) {
            state.activeTransaction = action.payload.active;
            return state;
        },
    },
    extraReducers: {},
});

export const {changeTransactionStatus} = pinpadSlice.actions;
export default pinpadSlice.reducer;
