export const UseIcon = (props) => {
    return (
        <svg
            width={21}
            height={22}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M20.392 18.524l-7.219-7.087a1.96 1.96 0 00-.214-.182l1.28-1.324c.064-.064.125-.133.185-.201 1.698.63 3.689.274 5.056-1.064a4.618 4.618 0 001.377-3.708.396.396 0 00-.27-.343.42.42 0 00-.433.092L17.838 6.98l-2.344-.58-.665-2.375 2.311-2.269a.41.41 0 00.098-.425.414.414 0 00-.353-.264 4.805 4.805 0 00-3.777 1.351 4.613 4.613 0 00-1.084 4.96c-.07.058-.14.118-.204.182L9.936 9.337 6.48 5.944a1.044 1.044 0 00-.135-.114 3.17 3.17 0 00.116-.215c.73.137 2.066-1.255 3.2-2.37L6.36.005C4.885 1.456 3.806 2.428 3.945 3.145c-.34.16-.637.36-.851.57l-.465.458a1.915 1.915 0 00-.549 1.05c-.046.05-.093.105-.135.16l-.172.214c-.005.005-.019.028-.023.032l-.158.215c-.098.128-.186.27-.265.402-.033.05-.06.1-.093.15l-.014.028-.07.128c-.084.155-.172.315-.247.493l-.014.027c-.097.201-.19.416-.25.644l-.033.096c-.024.082-.052.16-.075.256l-.065.31a3.717 3.717 0 00-.079.557l-.01.114a2.492 2.492 0 00-.009.489c0 .05.005.096.005.164 0 .028 0 .055.005.078 0 .064.004.141.018.22l.042.25c.01.05.014.096.023.142.014.105.033.21.06.31l.168.576a.395.395 0 00.768-.055l.088-.585c.005-.045.028-.114.047-.187l.042-.146.06-.22A.697.697 0 011.731 10c.014-.027.023-.055.037-.082.019-.046.042-.091.06-.142.038-.091.075-.182.131-.27.023-.04.047-.077.07-.118.056-.1.111-.196.177-.278l.014-.014.148-.201c.028-.036.066-.073.098-.11.023-.027.047-.05.074-.082.089-.1.191-.192.289-.278l.051-.046c.074-.068.158-.128.247-.196.037-.028.07-.055.107-.078.046-.027.088-.055.13-.087.079-.054.149-.1.228-.141l.172-.096.176-.087c.06-.032.121-.055.177-.077l.098-.042c.032-.009.06-.022.088-.032.037-.013.07-.027.117-.036l.046-.01c.051.083.112.16.186.234l3.419 3.356L.768 17.98l-.009.009c-.907.89-1.056 2.196-.149 3.091.907.89 2.154.662 3.06-.228l.01-.01 6.437-6.657c.037.046.075.087.116.128l7.22 7.091a2.1 2.1 0 002.93 0c.818-.799.818-2.086.009-2.88zm-17.66 1.594a.87.87 0 01-1.215 0 .827.827 0 010-1.187.87.87 0 011.214 0 .827.827 0 010 1.187z"
                fill="#005EB8"/>
        </svg>
    );
};
