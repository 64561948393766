export const BellIcon = (props) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect y="0.5" width="24" height="24" fill="white" fillOpacity="0.01"/>
            <path d="M12.0001 2.90002C8.02368 2.90002 4.80013 6.12357 4.80013 10.1V14.403L3.9516 15.2515C3.60841 15.5947 3.50574 16.1108 3.69148 16.5592C3.87721 17.0077 4.31478 17.3 4.80013 17.3H19.2001C19.6855 17.3 20.1231 17.0077 20.3088 16.5592C20.4945 16.1108 20.3919 15.5947 20.0487 15.2515L19.2001 14.403V10.1C19.2001 6.12357 15.9766 2.90002 12.0001 2.90002Z" fill="white"/>
            <path d="M12.0001 22.1C10.0119 22.1 8.4001 20.4882 8.4001 18.5H15.6001C15.6001 20.4882 13.9883 22.1 12.0001 22.1Z" fill="white"/>
            <path d="M19.9002 5.30001C19.9002 7.01209 18.5123 8.40001 16.8002 8.40001C15.0881 8.40001 13.7002 7.01209 13.7002 5.30001C13.7002 3.58793 15.0881 2.20001 16.8002 2.20001C18.5123 2.20001 19.9002 3.58793 19.9002 5.30001Z" fill="#F8AA36" stroke="white"/>
        </svg>
    );
};
