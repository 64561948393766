import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';

const CategoriesAPI = {

    getCategories: (officeId) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.get(`/categories`, {
            params: {
                o: officeId
            },
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },

    getCategoryTree: (productsId: number[]) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
        return AxiosInstance.post(`/categories/tree`, {products: productsId}, {
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
};

export default CategoriesAPI;
