export const HidraulicaIcon = (props) => {
    return (
        <svg
            width={35}
            height={33}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g clipPath="url(#icono-hidraulica_svg__clip0)">
                <path
                    d="M30.6 7l-1-1.2C25.3 11.5 15.9 14 15.9 14l-.8-1.8s9.4-3 13.5-8.3L27.5 3c-3.8 5-13 7.9-13 7.9l-.3-.8c-.1-.3-.4-.4-.7-.3l-3.7 1.7c-.3.1-.4.4-.3.6v.1l-3.2 2.4c-.1.2-.2.4-.1.6l1.3 3.3c.1.2.3.3.5.3l4-.8v.1c.1.3.4.4.7.3l3.8-1.6c.3-.1.4-.4.3-.7l-.3-.8C27.6 11.9 30.6 7 30.6 7zM9.1 17.4l-1.1.2-.8-2.2.8-.7 1.1 2.7zm2.3-.5l-1.6.3-1.1-2.8 1.3-1 1.4 3.5zm3.9-1.1l-2.4 1-1.9-4.4 2.4-1 1.9 4.4zM30.7 14.5c-6 5.7-18.8 10.4-18.8 10.4l-.8-2.1s11.7-4 18.9-9.9l-.7-1.3C18 20 7.7 22.6 7.7 22.6l-.3-.8c-.2-.4-.6-.6-1-.4l-2.9 1.2c-.4.2-.6.6-.4 1l2.4 6c.2.4.6.6 1 .4l2.9-1.2c.4-.2.6-.6.4-1l-.2-.8s15.7-5.5 21.9-11.3l-.8-1.2zM10.3 23l.8 2.2-2 .7-.9-2.2 2.1-.7zm-4 5.6l-2.1-5.2 2.2-.9 2.1 5.2-2.2.9z"/>
            </g>
            <defs>
                <clipPath id="icono-hidraulica_svg__clip0">
                    <path transform="translate(3 3)" d="M0 0h28.5v27H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};
