import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FilterOptionModel, TypeFilter} from '../../models/filter-option.model';

interface IGroupedFilters {
    filters?: FilterOptionModel[];
    loadingFilters: boolean,
    rangePrices?: {
        min: number,
        max: number,
    },
    filterOptions?: [],
    getProduct: boolean
}

const initialState: IGroupedFilters = {
    filters: [],
    loadingFilters: false,
    rangePrices: {
        max: 0,
        min: 0,
    },
    filterOptions: [],
    getProduct: false,
};

export const groupedFiltersSlice = createSlice({
    name: 'groupedFilters',
    initialState,
    reducers: {
        initFilters(state, action: PayloadAction<FilterOptionModel[]>) {
            state.filters    = action.payload;
            state.getProduct = true;
            return state;
        },
        applyFilters(state, action: PayloadAction<FilterOptionModel[]>) {
            const office = state.filters.find(filter => filter !== null && filter.type === TypeFilter.TYPE_OFFICE);
            if (office) {
                state.filters = [
                    office,
                    ...action.payload,
                ];
            } else {
                state.filters = [
                    ...action.payload,
                ];
            }
            return state;
        },
        removeFilter(state, action: PayloadAction<FilterOptionModel>) {
            switch (action.payload.type) {
                case TypeFilter.TYPE_SORT:
                    state.filters = state.filters.filter(filter => filter.type !== TypeFilter.TYPE_SORT);
                    return state;
                case TypeFilter.TYPE_STOCK:
                case TypeFilter.TYPE_PRODUCT:
                case TypeFilter.TYPE_RANGE_PRICE:
                    state.filters = state.filters.filter(filter => filter != null && filter.value !== action.payload.value);
                    return state;
                case TypeFilter.TYPE_DYNAMIC_VALUE:
                    const dynamicFilters = state.filters.filter(filter => filter != null && filter.type === TypeFilter.TYPE_DYNAMIC_VALUE);
                    const generalFilters = state.filters.filter(filter => filter != null && filter.type !== TypeFilter.TYPE_DYNAMIC_VALUE);
                    const index          = dynamicFilters.findIndex(filter => Object.keys(filter.value)[0] === Object.keys(action.payload.value)[0]);
                    if (index > -1) {
                        dynamicFilters.splice(index, 1);
                    }
                    state.filters = [...generalFilters, ...dynamicFilters];
                    return state;
            }
        },
        setFilterOptionsData(state, action) {
            state.rangePrices.min = action.payload.maxMin.min;
            state.rangePrices.max = action.payload.maxMin.max;
            state.filterOptions   = action.payload.filterAttributes.data;
            return state;
        },
        addStockFilter(state) {
            state.filters.push(new FilterOptionModel('Stock en tienda', 'STOCK_STORE', TypeFilter.TYPE_STOCK));
            return state;
        },
        removeStockFilter(state) {
            state.filters = state.filters.filter((filter) => filter?.type !== TypeFilter.TYPE_STOCK && filter?.value !== 'STOCK_STORE');
            return state;
        },
    },
});

export const {
                 applyFilters,
                 initFilters,
                 removeFilter,
                 setFilterOptionsData,
                 addStockFilter,
                 removeStockFilter,
             } = groupedFiltersSlice.actions;

export default groupedFiltersSlice.reducer;
