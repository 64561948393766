export const AgricolaIcon = (props) => {
    return (
        <svg
            width={27}
            height={30}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g clipPath="url(#icono-agricola_svg__clip0)">
                <path
                    d="M22.713 27h-6.439v-3.14l.1-.1s1.881-4.355 1.881-8.912c0-3.848-1.486-5.67-4.655-5.67-3.864-.102-4.656 2.936-4.656 5.569 0 2.329 2.278 8.911 2.377 9.012V27H4.486v-4.152h.99c-.792-1.418-2.08-4.456-2.376-8.101-.298-2.836.792-6.076 2.773-8.405C7.26 4.924 9.736 3 13.6 3c3.17 0 5.844 1.114 7.726 3.24 1.981 2.228 2.972 5.266 2.674 8.507-.396 3.95-1.485 6.683-2.278 8.101h.99V27zm-5.25-1.215h4.061v-1.722H18.95v-1.215h1.386c.496-.81 2.08-3.747 2.477-8.202.297-2.836-.595-5.671-2.378-7.595-1.684-1.924-4.06-2.937-6.835-2.937-3.466 0-5.646 1.62-6.834 3.038-1.783 2.126-2.774 4.962-2.576 7.494.396 3.949 2.08 7.19 2.576 8.202H8.35v1.215H5.675v1.722h4.458v-1.722c-.298-.911-2.378-6.987-2.378-9.316 0-4.355 2.08-6.886 5.845-6.886 2.674 0 5.844 1.215 5.844 6.886 0 4.557-1.585 8.607-1.882 9.316v1.722h-.1z"
                />
                <path
                    d="M22.713 27h-6.439v-3.14l.1-.1s1.881-4.355 1.881-8.912c0-3.848-1.486-5.67-4.655-5.67-3.864-.102-4.656 2.936-4.656 5.569 0 2.329 2.278 8.911 2.377 9.012V27H4.486v-4.152h.99c-.792-1.418-2.08-4.456-2.376-8.101-.298-2.836.792-6.076 2.773-8.405C7.26 4.924 9.736 3 13.6 3c3.17 0 5.844 1.114 7.726 3.24 1.981 2.228 2.972 5.266 2.674 8.507-.396 3.95-1.485 6.683-2.278 8.101h.99V27zm-5.25-1.215h4.061v-1.722H18.95v-1.215h1.386c.496-.81 2.08-3.747 2.477-8.202.297-2.836-.595-5.671-2.378-7.595-1.684-1.924-4.06-2.937-6.835-2.937-3.466 0-5.646 1.62-6.834 3.038-1.783 2.126-2.774 4.962-2.576 7.494.396 3.949 2.08 7.19 2.576 8.202H8.35v1.215H5.675v1.722h4.458v-1.722c-.298-.911-2.378-6.987-2.378-9.316 0-4.355 2.08-6.886 5.845-6.886 2.674 0 5.844 1.215 5.844 6.886 0 4.557-1.585 8.607-1.882 9.316v1.722h-.1z"
                />
                <path
                    d="M22.713 27h-6.439v-3.14l.1-.1s1.881-4.355 1.881-8.912c0-3.848-1.486-5.67-4.655-5.67-3.864-.102-4.656 2.936-4.656 5.569 0 2.329 2.278 8.911 2.377 9.012V27H4.486v-4.152h.99c-.792-1.418-2.08-4.456-2.376-8.101-.298-2.836.792-6.076 2.773-8.405C7.26 4.924 9.736 3 13.6 3c3.17 0 5.844 1.114 7.726 3.24 1.981 2.228 2.972 5.266 2.674 8.507-.396 3.95-1.485 6.683-2.278 8.101h.99V27zm-5.25-1.215h4.061v-1.722H18.95v-1.215h1.386c.496-.81 2.08-3.747 2.477-8.202.297-2.836-.595-5.671-2.378-7.595-1.684-1.924-4.06-2.937-6.835-2.937-3.466 0-5.646 1.62-6.834 3.038-1.783 2.126-2.774 4.962-2.576 7.494.396 3.949 2.08 7.19 2.576 8.202H8.35v1.215H5.675v1.722h4.458v-1.722c-.298-.911-2.378-6.987-2.378-9.316 0-4.355 2.08-6.886 5.845-6.886 2.674 0 5.844 1.215 5.844 6.886 0 4.557-1.585 8.607-1.882 9.316v1.722h-.1z"
                />
                <path
                    d="M6.566 14.24H5.377v-1.924h1.19v1.925zm.495-3.544l-1.09-.506c.1-.203 1.19-1.924 1.685-2.228l.594 1.013-.396-.507.297.507c-.198.202-.99 1.417-1.09 1.721z"
                />
                <path
                    d="M6.566 14.24H5.377v-1.924h1.19v1.925zm.495-3.544l-1.09-.506c.1-.203 1.19-1.924 1.685-2.228l.594 1.013-.396-.507.297.507c-.198.202-.99 1.417-1.09 1.721z"
                />
                <path
                    d="M6.566 14.24H5.377v-1.924h1.19v1.925zm.495-3.544l-1.09-.506c.1-.203 1.19-1.924 1.685-2.228l.594 1.013-.396-.507.297.507c-.198.202-.99 1.417-1.09 1.721z"
                />
                <path
                    d="M21.524 13.835h-1.19v-1.924h1.19v1.925zm-1.585-3.544c-.595-.911-.99-1.418-1.189-1.721l-.198-.203.891-.709.1.102c.198.303.693.81 1.287 1.822l-.891.71zm-3.071-3.14l-.793-.303c-.594-.304-1.386-.506-1.683-.607l.297-1.216c.396.102 1.09.405 1.783.71l.891.303-.495 1.114zm-7.033 0L9.24 6.039l.198-.101c.891-.405 1.684-.81 2.377-.912l.198 1.216c-.495.1-1.387.506-1.981.81l-.198.1z"
                />
                <path
                    d="M21.524 13.835h-1.19v-1.924h1.19v1.925zm-1.585-3.544c-.595-.911-.99-1.418-1.189-1.721l-.198-.203.891-.709.1.102c.198.303.693.81 1.287 1.822l-.891.71zm-3.071-3.14l-.793-.303c-.594-.304-1.386-.506-1.683-.607l.297-1.216c.396.102 1.09.405 1.783.71l.891.303-.495 1.114zm-7.033 0L9.24 6.039l.198-.101c.891-.405 1.684-.81 2.377-.912l.198 1.216c-.495.1-1.387.506-1.981.81l-.198.1z"
                />
                <path
                    d="M21.524 13.835h-1.19v-1.924h1.19v1.925zm-1.585-3.544c-.595-.911-.99-1.418-1.189-1.721l-.198-.203.891-.709.1.102c.198.303.693.81 1.287 1.822l-.891.71zm-3.071-3.14l-.793-.303c-.594-.304-1.386-.506-1.683-.607l.297-1.216c.396.102 1.09.405 1.783.71l.891.303-.495 1.114zm-7.033 0L9.24 6.039l.198-.101c.891-.405 1.684-.81 2.377-.912l.198 1.216c-.495.1-1.387.506-1.981.81l-.198.1z"
                />
                <path
                    d="M7.095 16.502l-1.128.384.47 1.441 1.128-.384-.47-1.441z"
                />
                <path
                    d="M7.095 16.502l-1.128.384.47 1.441 1.128-.384-.47-1.441z"
                />
                <path
                    d="M7.095 16.502l-1.128.384.47 1.441 1.128-.384-.47-1.441zM20.429 16.54l-.5 1.537 1.127.383.5-1.537-1.127-.384z"
                />
            </g>
            <defs>
                <clipPath id="icono-agricola_svg__clip0">
                    <path transform="translate(3 3)" d="M0 0h21v24H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};
