import CartModel from '../../models/cart.model';
import CartItemModel from '../../models/cart-item.model';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CustomerModel} from '../../models/customer.model';
import Employee from '../../models/employees';

const initialState: CartModel[] = [];

export const cartsSlice = createSlice({
    name: 'carts',
    initialState,
    reducers: {
        addItemToCart(state, action: PayloadAction<{ product, quantity? }>) {
            const product  = action.payload.product;
            const quantity = action.payload.quantity;
            if (state.length === 0) {
                const cart = new CartModel({id: 1, items: [new CartItemModel({product, quantity, isModified: true})]});
                return [...state, cart];
            } else {
                const activeCartIndex = state.findIndex(c => c.active);
                state[activeCartIndex].addItem(new CartItemModel({product, quantity, isModified: true}));
                return [...state];
            }
        },
        removeItemFromCart(state, action: PayloadAction<{ cart, item }>) {
            const cart = action.payload.cart;
            const item = action.payload.item;
            const i    = state.findIndex(c => c.id === cart.id);
            if (i !== -1) {
                state[i].removeItem(item);
            }
            return [...state];
        },
        newCart(state) {
            const nextTab = Math.max(...state.map(s => s.id), 0) + 1;
            const newCart = new CartModel({id: nextTab});
            state.forEach(c => c.active = false);
            return [...state, newCart];
        },
        setActiveCart(state, action: PayloadAction<{ id }>) {
            const idCart   = action.payload.id;
            const newState = state.map(s => {
                s.active = idCart === s.id;
                return s;
            });
            return [...newState];
        },
        removeCart(state, action: PayloadAction<{ id }>) {
            let filterState = state.filter(cart => cart.id !== action.payload.id);
            if (filterState.length > 0) {
                state.forEach(c => c.active = false);
                filterState[0].active = true;
            }
            return [...filterState];
        },
        emptyCart(state, action: PayloadAction<{ id }>) {
            const activeCartIndex = state.findIndex(cart => cart.id === action.payload.id);
            if (activeCartIndex !== -1) {
                state[activeCartIndex].emptyCart();
                state.forEach(c => c.active = false);
                state[0].active = true;
            }
            return [...state];
        },
        setItemQtyCart(state, action: PayloadAction<{ item, qty }>) {
            const item            = action.payload.item;
            const activeCartIndex = state.findIndex(c => c.active);
            state[activeCartIndex].setQtyFromItem(item, action.payload.qty);
            return [...state];
        },
        updateItemsDiscount(state, action: PayloadAction<{ items: CartItemModel[] }>) {
            const items           = action.payload.items;
            const activeCartIndex = state.findIndex(c => c.active);
            items.forEach(item => {
                state[activeCartIndex].updateItemDiscount(item, item.discount);
            });
            return [...state];
        },
        updateShipping(state, action: PayloadAction<{ price: number, isDelivery: boolean, manuallySet: boolean }>) {
            const activeCartIndex = state.findIndex(c => c.active);
            if (activeCartIndex !== -1) {
                state[activeCartIndex].setShippingPriceAndType(action.payload.price, action.payload.isDelivery, action.payload.manuallySet);
            }
            return [...state];
        },
        setIsDelivery(state, action: PayloadAction<{ isDelivery: boolean }>) {
            const activeCartIndex = state.findIndex(c => c.active);
            if (activeCartIndex !== -1) {
                state[activeCartIndex].setIsDelivery(action.payload.isDelivery);
            }
            return [...state];
        },
        addCustomer(state, action: PayloadAction<{ customer: CustomerModel }>) {
            const activeCartIndex = state.findIndex(c => c.active);
            state[activeCartIndex].addCustomer(action.payload.customer);
            return [...state];
        },
        updateBillingDataCustomer(state, action: PayloadAction<{ props: any }>) {
            const activeCartIndex = state.findIndex(c => c.active);
            state[activeCartIndex].updateCustomerBillingData(action.payload.props);
            return [...state];
        },
        removeCustomer(state) {
            const activeCartIndex = state.findIndex(c => c.active);
            state[activeCartIndex].removeCustomer();
            return [...state];
        },
        restoreCarts(state, action: PayloadAction<{ carts: CartModel[] }>) {
            state = action.payload.carts.map(c => new CartModel({...c}));
            if (state.length > 0 && !state.some(el => el.active === true)) {
                state[0].active = true;
            }
            return [...state];
        },
        removeAllCarts(state) {
            state = initialState;
            return [...state];
        },
        restoreAnimation(state) {
            const activeCartIndex = state.findIndex(c => c.active);
            if (activeCartIndex !== -1) {
                state[activeCartIndex].setModifiedItems();
            }
            return [...state];
        },
        setItemWithdrawNow(state, action: PayloadAction<{ item, qty }>) {
            const item = action.payload.item;
            const activeCartIndex = state.findIndex(c => c.active);
            state[activeCartIndex].setWithdrawNowForItem(item, action.payload.qty);
            return [...state];
        },
        updateItem(state, action: PayloadAction<{
            product_id,
            sap_stock: number,
            stock_cds: number,
            sap_price: number,
            sap_cost: number
        }>) {
            state.forEach(cart => cart.updateItem(
                action.payload.product_id, action.payload.sap_stock, action.payload.stock_cds, action.payload.sap_price,
                action.payload.sap_cost
            ));
            return [...state];
        },
        setShouldClose(state, action: PayloadAction<{ value: boolean }>) {
            const activeCartIndex = state.findIndex(c => c.active);
            state[activeCartIndex].setShouldClose(action.payload.value);
            return [...state];
        },
        animatedProductsCart(state, action: PayloadAction<{ productIds: number[] }>) {
            const activeCartIndex = state.findIndex(c => c.active);
            state[activeCartIndex].setAllProductIsModified(false);
            state[activeCartIndex].setProductIsModified(action.payload.productIds);
            return [...state];
        },
        setLoaded(state, action: PayloadAction<{ rut }>) {
            const cartIndex = state.findIndex(c => c.active);
            const rut       = action.payload.rut;
            if (cartIndex !== -1) {
                state[cartIndex].setLoadedCustomer(rut);
            }
            return [...state];
        },
        updateLastSupplierSelection(state, action: PayloadAction<{ cartItemId, supplier }>) {
            const cartItemId      = action.payload.cartItemId;
            const supplier        = action.payload.supplier;
            const activeCartIndex = state.findIndex(c => c.active);
            state[activeCartIndex].setLastSupplierSelection(cartItemId, supplier);
            return [...state];
        },
        saveLastSuppliers(state) {
            const activeCartIndex = state.findIndex(c => c.active);
            state[activeCartIndex].setSuppliers();
            return [...state];
        },
        updateSupervisorAndItemsPendingApprove(state, action: PayloadAction<{ cart_id: number | null, supervisor: Employee,  discount_request_id: number | null, items: CartItemModel[]}> ) {

            let cart: CartModel = null;
            if(action.payload.cart_id) {
                cart = state.find(c => c.id === action.payload.cart_id);
            } else {
                cart = state.find(c => c.active);
            }
            cart.setSupervisorPendingApprove(action.payload.supervisor);
            cart.removePendingItems();
            cart.setDiscountRequestId(action.payload.discount_request_id);

            cart.items.forEach(item => {
                const newItem = action.payload.items?.find(i => i.id === item.id);
                if(newItem) {
                    item.setPendingApproveDiscount(newItem.pending_approve_discount);
                    item.setPendingApproveDiscountPct(newItem.pending_approve_discount_pct);
                    item.setPendingApproveQty(newItem.pending_approve_qty);
                    item.setAuthSupervisorAndDate(newItem.authorized_by, newItem.authorized_date);
                }
            });

            cart.calculateTotal();
            return [...state];
        },
        updateItemsDiscountAndSupervisor(state, action: PayloadAction<{ cart_id: number, items: CartItemModel[], supervisor: Employee, authorized_date: string }>) {
            const items           = action.payload.items;
            const cart = state.find(c => c.id === action.payload.cart_id);
            cart.setSupervisorPendingApprove(null);
            cart.setDiscountRequestId(null);
            items.forEach(item => {
                if (item.pending_approve === false && (item.discount_percentage_applied > item.discountMaxApproved || item.margin_with_discount < item.product.margin_min)) {
                    item.authorized_by = action.payload.supervisor;
                    item.authorized_date = action.payload.authorized_date;
                    item.setDiscountApproved(item.discount_percentage_applied);
                }
                cart.updateItemDiscount(item, item.discount);
            });
            cart.calculateTotal();
            return [...state];
        },
        updateDiscountStatus(state, action: PayloadAction<{ cart_id: number, status: 'approved' | 'rejected' | 'pending' | null }>) {
            const cart = state.find(c => c.id === action.payload.cart_id);
            cart.setSupervisorPendingApproveStatus(action.payload.status);
            return [...state];
        },
        removeApprovedDiscount(state, action: PayloadAction<{item }>) {
            const cartIndex = state.findIndex(c => c.active);
            const item = action.payload.item;
            if (cartIndex !== -1) {
                state[cartIndex].removeApprovedDiscount(item);
            }
            return [...state];
        },
    },
});

export const {
                 addItemToCart,
                 removeItemFromCart,
                 newCart,
                 setActiveCart,
                 removeCart,
                 setItemQtyCart,
                updateItemsDiscount,
                addCustomer,
                updateBillingDataCustomer,
                removeCustomer,
                emptyCart,
                updateShipping,
                restoreCarts,
                removeAllCarts,
                setIsDelivery,
                restoreAnimation,
                setItemWithdrawNow,
                updateItem,
                setShouldClose,
                animatedProductsCart,
                setLoaded,
                updateLastSupplierSelection,
                saveLastSuppliers,
                updateSupervisorAndItemsPendingApprove,
                updateDiscountStatus,
                updateItemsDiscountAndSupervisor,
                removeApprovedDiscount,

} = cartsSlice.actions;
export default cartsSlice.reducer;
