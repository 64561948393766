import {Logo} from './ui/Icons';

const Loader = () => {
    return (
        <div className="bg-opacity-90 fixed w-full h-full bg-white justify-center items-center flex flex-col z-50">
            <Logo className="flex animate-bounce"/>
            <p className="flex text-pdlm-blue font-bold mt-2">
                Cargando...
            </p>
        </div>
    );
};

export default Loader;
