export const ArrowIcon = (props) => {
    return (
        <svg
            width={12}
            height={20}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M11.293 17.293L4 10l7.293-7.293a1 1 0 000-1.414l-.586-.586a1 1 0 00-1.414 0L.707 9.293a1 1 0 000 1.414l8.586 8.586a1 1 0 001.414 0l.586-.586a1 1 0 000-1.414z"
                fill="#005EB8"
            />
        </svg>
    );
};
