export const AlertIcon = () => {
    return (
        <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.0862 22.8534L28.0882 22.8521L28.0423 22.7729C28.0199 22.7306 27.9981 22.6881 27.971 22.6491L15.5333 1.10575C15.3317 0.739777 14.9472 0.488647 14.4994 0.488647C14.0143 0.488647 13.5982 0.781357 13.4154 1.19914L13.4141 1.19848L1.07305 22.5746L1.08493 22.5815C0.911024 22.7908 0.815356 23.0541 0.814331 23.3263C0.814331 23.6404 0.939057 23.9416 1.16109 24.1638C1.38312 24.3859 1.68428 24.5108 1.99837 24.511H27.0015C27.3156 24.5109 27.6168 24.3861 27.8389 24.164C28.061 23.9419 28.1858 23.6407 28.1858 23.3266C28.1858 23.158 28.1495 22.9986 28.0862 22.8534ZM14.5116 22.2736C13.5632 22.2736 12.7953 21.5288 12.7953 20.5807C12.7953 19.6323 13.5629 18.8647 14.5116 18.8647C15.4366 18.8647 16.2045 19.6319 16.2045 20.5807C16.2048 20.8031 16.1613 21.0234 16.0763 21.2289C15.9913 21.4344 15.8666 21.6212 15.7094 21.7784C15.5521 21.9357 15.3654 22.0604 15.1598 22.1454C14.9543 22.2303 14.734 22.2739 14.5116 22.2736ZM16.2045 7.30249V16.6412H16.2039L16.2045 16.6464C16.2045 17.0335 15.891 17.3375 15.5135 17.3375L15.5089 17.3371V17.3375H13.4982C13.1108 17.3375 12.7976 17.0335 12.7976 16.6464L12.7983 16.6412H12.7976V7.32757C12.7973 7.31899 12.795 7.31074 12.795 7.30216C12.795 6.93355 13.0804 6.64348 13.4335 6.61642V6.61114H15.5086V6.61147C15.8877 6.61378 16.193 6.90748 16.2026 7.28434H16.2042V7.30249H16.2045Z"
                fill="#ED8B00"/>
        </svg>
    );
};

export default AlertIcon;
