export class InvoiceModel {
    name: string | null      = null;
    last_name: string | null = null;
    full_name: string | null = null;
    phone: string | null     = null;
    rut: string | null       = null;
    city                     = null;
    region                   = null;
    sapClient                = null;
    business                 = null;
    address                  = null;
    paymentCondition         = null;
    creditLine               = null;
    debt                     = null;
    risk                     = null;
    sellers: []              = [];


    constructor(props) {
        if (props) {
            this.name             = props.name ?? null;
            this.last_name        = props.last_name ?? null;
            this.full_name        = props.full_name || props.name + ' ' + props.last_name || null;
            this.phone            = props.phone ?? null;
            this.rut              = props.rut ?? null;
            this.business         = props.business ?? null
            this.address          = props.address ?? null
            this.sapClient        = props.sapClient ?? null
            this.city             = props.city ?? null
            this.region           = props.region ?? null
            this.paymentCondition = props.paymentCondition ?? null;
            this.creditLine       = props.creditLine ?? null;
            this.debt             = props.debt ?? null;
            this.risk             = props.risk ?? null;
            this.sellers          = props.sellers ?? [];
        }
    }
}