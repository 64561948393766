import {createAsyncThunk} from '@reduxjs/toolkit';
import ProductsAPI from '../pages/api/products';

export const getFilters = createAsyncThunk(
    'filters/get',
    async (params: { products_id, officeId }) => {
        const response = await ProductsAPI.getProductsFilterData(params.officeId, params.products_id);
        return response && response.data || [];
    },
);
