import {createSlice} from '@reduxjs/toolkit';
import {IRegion} from '../../interfaces/region';
import {GetRegions} from '../../services/regions.service';

interface IRegionSlice {
    data: IRegion[],
    isLoading: boolean;
}

const initialState: IRegionSlice = {
    data: [],
    isLoading: false,
};

export const regionsSlice = createSlice({
    name: 'regions',
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder.addCase(GetRegions.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(GetRegions.fulfilled, (state, action) => {
            state.data      = [...action.payload];
            state.isLoading = false;
        });
    }),
});

export default regionsSlice.reducer;
