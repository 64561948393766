export const CashWithdrawIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.4512 9.2256C12.336 7.9488 11.1264 6.6912 9.60002 6.72C7.96802 6.7488 6.72002 7.968 6.72002 9.6C6.72002 11.232 7.96802 12.4224 9.60002 12.48C9.96482 12.48 10.3392 12.4032 10.6944 12.2592C10.9536 11.2032 11.3856 10.2048 12.4512 9.2256ZM10.56 13.44H4.80002C4.80002 12.9308 4.59774 12.4424 4.23767 12.0824C3.8776 11.7223 3.38924 11.52 2.88002 11.52V7.68C3.94562 7.68 4.80002 6.8256 4.80002 5.76H14.4C14.4 6.26921 14.6023 6.75757 14.9624 7.11764C15.3224 7.47771 15.8108 7.68 16.32 7.68V7.7376C16.9632 7.7376 17.6064 7.8528 18.24 8.064V3.84H0.960022V15.36H10.8672C10.6632 14.7404 10.5595 14.0923 10.56 13.44Z"
                fill="#005EB8"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.32 19.2C19.5012 19.2 22.08 16.6212 22.08 13.44C22.08 10.2588 19.5012 7.67999 16.32 7.67999C13.1388 7.67999 10.56 10.2588 10.56 13.44C10.56 16.6212 13.1388 19.2 16.32 19.2ZM20.16 12.48H12.48V14.4H20.16V12.48Z"
                  fill="#005EB8"/>
        </svg>
    );
};
