export const LoadingCircleIcon = (props) => {
    return (
        <svg viewBox="25 25 50 50" {...props}>
            <circle
                cx={50}
                cy={50}
                r={20}
                fill="none"
                stroke="#005EB8"
                strokeWidth={2}/>
        </svg>
    );
};
