export const AcerosMetalurgiaIcon = (props) => {
    return (
        <svg
            width={29}
            height={29}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M25.761 6.29V3.366c0-.03 0-.092-.03-.122s-.03-.061-.06-.092l-.03-.03c-.03-.03-.06-.03-.09-.061 0 0-.029 0-.029-.03-.06-.031-.09-.031-.12-.031H13.067c-.09 0-.179.03-.269.122l-9.678 9.84c-.03.03-.06.06-.06.121-.03.03-.059.061-.059.122V15.886c0 .183.15.518.358.518h3.525c.896 0 .986-.03.986.883L3.896 22.68a.39.39 0 00-.12.274v2.68c0 .214.15.366.36.366h12.067c.06 0 .09 0 .149-.03.03-.03.06-.03.12-.061L25.88 15.49a.39.39 0 00.119-.274v-2.59c0-.03 0-.09-.03-.121a.272.272 0 00-.18-.183s-.029 0-.029-.03c-.03-.031-.09-.031-.12-.031l-2.658-.122h-.269c-.956-.06-1.194-.183-1.523-.944l4.48-4.661c.03-.061.09-.153.09-.244zM13.514 4.22H23.61l-7.945 8.072H5.599l7.915-8.072zM7.87 19.603v1.28c0 .913-.747 1.675-1.643 1.675h-.568l2.21-2.955zm7.02 5.087h-9.53v-1.036h1.554c1.314 0 2.39-1.462 2.39-2.802V17.5c0-1.34-.747-2.437-2.39-2.437h-2.39v-1.615h10.784v1.615h-2.181c-1.314 0-2.39.213-2.39 2.437v3.35c0 1.341 1.076 2.438 2.39 2.438h1.762v1.401zm10.125-9.748l-8.781 9.383v-1.25l8.781-9.321v1.188zm-2.539-1.706H24.178l-8.453 8.53h-2.599c-.896 0-1.135-.762-1.135-1.676v-1.706c0-.914 0-1.889 1.045-1.889l2.748-.122c.21 0 .3-.06.389-.121l4.182-4.204c.328.579.955 1.188 2.12 1.188zm2.27-7.129l-8.393 8.53v-1.28l8.393-8.681v1.431z"
            />
            <path
                d="M13.962 20.669c-.149-.061-.298-.03-.388.091a.39.39 0 00-.12.274c0 .214.15.366.359.366.21 0 .358-.152.358-.366.03-.182-.06-.304-.209-.365zM15.306 19.267a.387.387 0 00-.358.092l-.358.396a.383.383 0 000 .518c.12.122.388.122.507 0l.359-.396a.406.406 0 00.09-.366c0-.122-.09-.213-.24-.244zM16.382 17.866l-.359.396a.382.382 0 000 .518c.15.152.389.122.508 0l.359-.396a.383.383 0 000-.518.364.364 0 00-.508 0zM17.756 16.404l-.359.396c-.09.122-.12.274-.06.396.06.152.18.213.33.213.089 0 .208-.03.268-.121l.359-.397a.382.382 0 000-.517c-.18-.122-.419-.122-.538.03zM19.548 14.729a.387.387 0 00-.358.09l-.359.427c-.09.122-.12.274-.06.396.06.153.18.214.329.214.09 0 .21-.03.269-.122l.358-.396c.09-.092.12-.244.09-.366a.37.37 0 00-.269-.243zM20.444 13.57c-.15.062-.239.184-.239.336 0 .091.03.183.12.274.06.061.149.122.268.122.09 0 .18-.03.27-.122a.39.39 0 00.119-.274.375.375 0 00-.12-.274c-.149-.061-.298-.122-.418-.061z"
            />
        </svg>
    );
};
