interface ICashIcon {
    className: string;
}

export const CashIcon = (props: ICashIcon) => {
    return (
        <svg className={`${props.className}`} width="30" height="18" viewBox="0 0 30 18"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.5 0H1.5C0.671719 0 0 0.671719 0 1.5V16.5C0 17.3283 0.671719 18 1.5 18H28.5C29.3283 18 30 17.3283 30 16.5V1.5C30 0.671719 29.3283 0 28.5 0ZM2.25 15.75V12.75C3.90703 12.75 5.25 14.093 5.25 15.75H2.25ZM2.25 5.25V2.25H5.25C5.25 3.90703 3.90703 5.25 2.25 5.25ZM15 13.5C12.9286 13.5 11.25 11.4848 11.25 9C11.25 6.51469 12.9291 4.5 15 4.5C17.0709 4.5 18.75 6.51469 18.75 9C18.75 11.4858 17.0705 13.5 15 13.5ZM27.75 15.75H24.75C24.75 14.093 26.093 12.75 27.75 12.75V15.75ZM27.75 5.25C26.093 5.25 24.75 3.90703 24.75 2.25H27.75V5.25Z"/>
        </svg>
    );
};
