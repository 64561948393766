export const EyeIcon = (props) => {
    return (
        <svg
            width={22}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M21.083 7.395C18.86 2.715 15.503.36 11 .36 6.495.36 3.139 2.715.917 7.398a1.413 1.413 0 000 1.207C3.14 13.285 6.497 15.64 11 15.64c4.505 0 7.86-2.356 10.083-7.039.18-.38.18-.82 0-1.207zM11 13.953c-3.78 0-6.549-1.917-8.5-5.953C4.45 3.964 7.22 2.047 11 2.047c3.78 0 6.548 1.917 8.5 5.953-1.95 4.036-4.717 5.953-8.5 5.953zm-.094-10.078a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25zm0 6.75A2.624 2.624 0 018.281 8a2.624 2.624 0 012.625-2.625A2.624 2.624 0 0113.531 8a2.624 2.624 0 01-2.625 2.625z"
                fill="#666"/>
        </svg>
    );
};
