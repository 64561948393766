import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';
import {AxiosPromise} from 'axios';

export const getRegions = (): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`/regions`, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};
