export const BenefitsIcon = (props) => {
    return (
        <svg
            width={22}
            height={25}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g clipPath="url(#benefits-icon_svg__clip0)" fill="#005EB8">
                <path
                    d="M22 16.21a1.47 1.47 0 00-1.467-1.464A1.47 1.47 0 0022 13.281a1.47 1.47 0 00-1.467-1.465H14.52c.895-2.68.88-3.535.88-5.175a2.205 2.205 0 00-2.2-2.198h-.44c-.279 0-.63.22-.704.557-.723 2.822-1.858 6.06-4.723 6.714v10.6l2.538.84c.748.25 1.525.381 2.317.381h6.879a1.464 1.464 0 100-2.93h1.466A1.47 1.47 0 0022 19.142a1.47 1.47 0 00-1.467-1.465A1.47 1.47 0 0022 16.21zM3.667 10.352H.733a.732.732 0 00-.733.732v13.184c0 .405.328.732.733.732h2.934c1.212 0 2.2-.986 2.2-2.197V12.549c0-1.211-.988-2.197-2.2-2.197zm0 11.718a.732.732 0 110-1.465.732.732 0 110 1.465zM10.09 3.228L9.055 2.192a.732.732 0 10-1.036 1.035l1.036 1.036a.73.73 0 001.037 0 .736.736 0 000-1.035zM18.387 2.192a.73.73 0 00-1.036 0l-1.037 1.036a.727.727 0 000 1.035.734.734 0 001.037 0l1.036-1.035a.736.736 0 000-1.036zM13.2 0a.726.726 0 00-.733.732v1.514c0 .41.322.733.733.733.41 0 .733-.323.733-.733V.732A.726.726 0 0013.2 0z"/>
            </g>
            <defs>
                <clipPath id="benefits-icon_svg__clip0">
                    <path fill="#fff" d="M0 0h22v25H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};
