export const BankCheckSkyBlueIcon = () => {
    return (
        <svg width="41" height="28" viewBox="0 0 41 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.7002 23.8593V3.7334H37.3246V17.037V17.3781L32.354 23.8593H3.7002Z" fill="#49A4E5"/>
            <path
                d="M29.82 22.1537L5.55197 21.9263V5.66639H35.8626V15.2177M32.354 20.9029V17.037H37.3246M37.3246 17.037V17.3781L32.354 23.8593H3.7002V3.7334H37.3246V17.037ZM10.7175 8.39533V19.1974M8.67075 17.037H11.6921C13.3489 16.9233 13.4464 13.8532 11.6921 13.7395H9.8403C7.89106 13.7395 7.89106 10.6694 9.8403 10.6694H12.6667"
                stroke="white"/>
            <path
                d="M18.418 11.6931H30.2109M16.4688 14.422H27.4819M16.4688 17.0373H24.0708M29.0413 14.422H30.2109M16.4688 11.6931H17.151"
                stroke="white"/>
        </svg>
    );
};
