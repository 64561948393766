export interface LoginResponse {
    auth: Auth;
}

export interface Auth {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: number;
}

export class ConvertAuth {
    public static toAuth(json: string): Auth {
        return JSON.parse(json);
    }

    public static authToJson(value: Auth): string {
        return JSON.stringify(value);
    }
}
