export enum TypesEmployees {
    ZONE_MANAGER   = 'ZONE_MANAGER',
    BRANCH_MANAGER = 'BRANCH_MANAGER',
    SELLER         = 'SELLER'
}

class Employee {
    id: number             = null;
    name: string           = null;
    email: string          = null;
    discount_limit: number = 0;
    job: TypesEmployees    = null;
    printer_id             = null;

    constructor(props) {
        if (props) {
            this.id             = props.id || null;
            this.name           = props.name || null;
            this.email          = props.email || null;
            this.discount_limit = props.discount_limit || 0;
            this.job            = this.defineRole(props.job);
            this.printer_id     = props.printer_id || null;
        }
    }

    defineRole(value) {
        switch (value) {
            case TypesEmployees.ZONE_MANAGER:
                return TypesEmployees.ZONE_MANAGER;
            case TypesEmployees.BRANCH_MANAGER:
                return TypesEmployees.BRANCH_MANAGER;
            case TypesEmployees.SELLER:
                return TypesEmployees.SELLER;
        }
    }
}

export default Employee;
