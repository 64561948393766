export const BillingDataIcon = (props) => {
    return (
        <svg width="15" height="21" viewBox="0 0 15 21" fillRule="nonzero"
             xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.3191 6.09316C14.4101 6.19316 14.5011 6.32174 14.5922 6.47888H9.98822V1.42174C10.1313 1.52174 10.2483 1.62174 10.3394 1.72174L14.3191 6.09316ZM9.67609 7.85031H14.9823V19.1646C14.9823 19.4503 14.8913 19.6932 14.7092 19.8932C14.5271 20.0932 14.306 20.1932 14.0459 20.1932H0.936396C0.676286 20.1932 0.455192 20.0932 0.273115 19.8932C0.0910385 19.6932 0 19.4503 0 19.1646V2.02174C0 1.73602 0.0910385 1.49316 0.273115 1.29316C0.455192 1.09316 0.676286 0.993164 0.936396 0.993164H8.73969V6.82174C8.73969 7.10745 8.83073 7.35031 9.01281 7.55031C9.19489 7.75031 9.41598 7.85031 9.67609 7.85031ZM11.2367 15.736V15.0503C11.2367 14.9503 11.2075 14.8682 11.149 14.8039C11.0904 14.7396 11.0157 14.7074 10.9246 14.7074H4.05771C3.96668 14.7074 3.89189 14.7396 3.83337 14.8039C3.77485 14.8682 3.74558 14.9503 3.74558 15.0503V15.736C3.74558 15.836 3.77485 15.9182 3.83337 15.9825C3.89189 16.0467 3.96668 16.0789 4.05771 16.0789H10.9246C11.0157 16.0789 11.0904 16.0467 11.149 15.9825C11.2075 15.9182 11.2367 15.836 11.2367 15.736ZM11.2367 12.9932V12.3075C11.2367 12.2074 11.2075 12.1253 11.149 12.061C11.0904 11.9967 11.0157 11.9646 10.9246 11.9646H4.05771C3.96668 11.9646 3.89189 11.9967 3.83337 12.061C3.77485 12.1253 3.74558 12.2074 3.74558 12.3075V12.9932C3.74558 13.0932 3.77485 13.1753 3.83337 13.2396C3.89189 13.3039 3.96668 13.336 4.05771 13.336H10.9246C11.0157 13.336 11.0904 13.3039 11.149 13.2396C11.2075 13.1753 11.2367 13.0932 11.2367 12.9932ZM11.2367 10.2503V9.56459C11.2367 9.46459 11.2075 9.38245 11.149 9.31816C11.0904 9.25388 11.0157 9.22174 10.9246 9.22174H4.05771C3.96668 9.22174 3.89189 9.25388 3.83337 9.31816C3.77485 9.38245 3.74558 9.46459 3.74558 9.56459V10.2503C3.74558 10.3503 3.77485 10.4325 3.83337 10.4967C3.89189 10.561 3.96668 10.5932 4.05771 10.5932H10.9246C11.0157 10.5932 11.0904 10.561 11.149 10.4967C11.2075 10.4325 11.2367 10.3503 11.2367 10.2503Z"
                fill="#005EB8"/>
        </svg>
    );
};
