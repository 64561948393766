export const ConstruccionIcon = (props) => {
    return (
        <svg
            width={34}
            height={34}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M30.533 11.87a.467.467 0 00.467-.467V7.67a.463.463 0 00-.327-.439c-.015-.01-19.806-3.235-19.806-3.235a1.395 1.395 0 00-2.707.133S3.167 7.31 3.157 7.325A.46.46 0 003 7.67v3.732a.467.467 0 00.467.467H5.8v1.4a.466.466 0 00.467.466H7.2v13.066H3.467a.467.467 0 00-.467.466v3.266a.467.467 0 00.467.467H15.6a.467.467 0 00.467-.467v-3.266a.467.467 0 00-.467-.466h-3.733V13.736h.933a.466.466 0 00.467-.467v-1.4h11.666v1.867h-2.8a.467.467 0 00-.466.466v1.4a.467.467 0 00.466.467h8.4a.467.467 0 00.467-.467v-1.4a.467.467 0 00-.467-.466h-2.8v-1.867h2.8zm-.466-.934h-.7l-2.1-2.8h2.8v2.8zm-19.24-6.001l13.935 2.268H10V5.72a1.407 1.407 0 00.827-.785zm.573 6H7.667l1.866-2.488 1.867 2.489zM9.533 3.938a.467.467 0 110 .933.467.467 0 010-.933zM8.34 5.123c.168.273.425.48.728.587v1.493H5.069l3.27-2.08zm-4.406 5.813V8.447L5.8 10.936H3.933zm.934-2.8H8.6l-1.867 2.489-1.866-2.489zm10.266 19.598v2.333h-11.2v-2.333h11.2zm-7-12.887v-1.111h2.224l-2.224 1.111zm2.8-.356v2.222L8.71 15.602l2.223-1.111zm-.576 2.977L8.133 18.58v-2.223l2.224 1.111zm.576.755v2.223L8.71 19.335l2.223-1.112zm-.576 2.978l-2.224 1.112V20.09l2.224 1.111zm.576.755v2.223L8.71 23.068l2.223-1.112zm-.486 3.025l-2.314 1.446v-2.604l2.314 1.158zm.486.793V26.8h-1.64l1.64-1.026zm1.4-12.972h-5.6v-.933h5.6v.933zm0-2.177l-1.866-2.489H14.2l-1.867 2.489zm.934.31l1.866-2.488L17 10.936h-3.733zm2.8-2.799H19.8l-1.867 2.489-1.866-2.489zm2.8 2.8l1.866-2.489 1.867 2.489h-3.733zm2.8-2.8H25.4l-1.867 2.489-1.866-2.489zm8.4 6.533v.466H22.6v-.466h7.467zm-4.2-.934V11.87h.933v1.867h-.933zm-1.4-2.8l1.866-2.488 1.867 2.489h-3.733z"
                />
        </svg>
    );
};
