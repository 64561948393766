export const EyeCloseIcon = (props) => {
    return (
        <svg
            width={23}
            height={21}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M1 1.447L2.28 0l16.724 19.048-1.27 1.458-3.08-3.509c-1.151.433-2.371.661-3.652.661-5 0-9.272-3.543-11.002-8.544.69-2.005 1.79-3.771 3.19-5.172L1 1.447zm10.002 4.25c.796 0 1.56.36 2.122 1 .563.641.879 1.51.879 2.417 0 .388-.057.773-.17 1.14l-3.83-4.364c.32-.129.659-.194 1-.194zm0-5.127c5.001 0 9.272 3.543 11.003 8.544-.817 2.362-2.204 4.412-4.001 5.913l-1.42-1.63c1.383-1.09 2.498-2.564 3.24-4.283-.808-1.88-2.064-3.464-3.623-4.571-1.56-1.108-3.36-1.695-5.199-1.695-1.09 0-2.16.205-3.16.57l-1.54-1.743a10.64 10.64 0 014.7-1.105zM2.18 9.114c.81 1.88 2.064 3.463 3.624 4.571 1.56 1.108 3.36 1.695 5.198 1.695.69 0 1.37-.08 2-.24l-2.28-2.608c-.696-.085-1.345-.44-1.84-1.003a3.706 3.706 0 01-.88-2.096L4.6 5.548c-.99.968-1.82 2.176-2.42 3.566z"
                fill="#666"/>
        </svg>
    );
};
