/* React */
import React, {ReactElement, ReactNode, useEffect} from 'react';
import {AppProps} from 'next/app';
/* Styles */
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import '../styles/style.scss';
import "quill/dist/quill.snow.css";

/* Contexts */
import {AuthContextProvider, ProtectRoute, publicRoutes} from '../contexts/AuthProvider';
import MegaMenuProvider from '../contexts/MegaMenuProvider';
/* Others */
import ReactNotification from 'react-notifications-component';
import {Provider} from 'react-redux';
import store from '../redux/store';
import TagManager from 'react-gtm-module';
import {NextPage} from 'next';
import moment from 'moment/moment';

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

const tagManagerArgs = {
    gtmId: 'GTM-KLQ7NVC',
};


try {
    const isSupported = 'BroadcastChannel' in window;
    // disable in local because auto reload will trigger the alert
    const isLocal = process.env.APP_ENV === 'local';
    if(isSupported && !isLocal && !publicRoutes.includes(window.location.pathname)) {
        const channel = new BroadcastChannel('tab');
        let isOriginal = true;
        channel.postMessage('another-tab');
        channel.addEventListener('message', (msg) => {
            if (msg.data === 'another-tab' && isOriginal) {
                channel.postMessage('already-open');
            }
            if (msg.data === 'already-open') {
                isOriginal = false;
                const message = 'No puedes tener dos pestañas abiertas';
                alert(message);
                window.location.href = '/404?message='+message;
            }
        });
    }
} catch(e) {
    console.log('error', e);
}

const MyApp = ({Component, pageProps}: AppPropsWithLayout) => {
    const getLayout = Component.getLayout ?? ((page) => page);

    moment.locale('es', {
            months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
            monthsShort: 'Ene._Feb._Mar._Abr._May._Jun._Jul._Ago._Sep._Oct._Nov._Dic.'.split('_'),
            weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
            weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
            weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
        },
    );

    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
    }, []);

    return (
        <>
            <style jsx global>{`
              html, body {
                margin: 0;
                height: 100%;
                font-family: Roboto, sans-serif;
              }

              #__next {
                height: 100%;
              }
            `}</style>
            <Provider store={store}>
                <AuthContextProvider>
                    <ProtectRoute>
                        <MegaMenuProvider>
                            <ReactNotification className="font-roboto"/>
                            {/*<Head>*/}
                            {/*    <title>POS - Prodalam</title>*/}
                            {/*</Head>*/}
                            {getLayout(<Component {...pageProps} />)}
                        </MegaMenuProvider>
                    </ProtectRoute>
                </AuthContextProvider>
            </Provider>
        </>
    );
};

export default MyApp;
