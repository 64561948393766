import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ModalDiscountModel from '../../models/modal-discount.model';
import CartItemModel, { DEFAULT_DISCOUNT_LIMIT } from '../../models/cart-item.model';

export interface IProductFiltersSlice {
    modalDiscount: ModalDiscountModel
    initState: boolean,
    showPriceWeb: boolean,
}

const initialState: IProductFiltersSlice = {
    modalDiscount: null,
    initState: true,
    showPriceWeb: false,
};

export const modalDiscountSlice = createSlice({
        name: 'modalDiscounts',
        initialState,
        reducers: {
            initModal(state, action: PayloadAction<{ cart: ModalDiscountModel }>) {
                state.initState         = action.payload.cart.supervisorPendingApprove === null;
                state.showPriceWeb      = false;
                state.modalDiscount     = action.payload.cart;
                return state;
            },
            setMaxDiscountEmployee(state, action: PayloadAction<number | null>) {
                state.modalDiscount?.items?.map(item => item?.setDiscountMaxEmployee(action.payload ?? DEFAULT_DISCOUNT_LIMIT));
                return state;
            },
            showPriceWeb(state, action: PayloadAction<boolean>) {
                state.showPriceWeb = action.payload;
                return state;
            },
            resetModal(state) {
                state = null;
                return state;
            },
            updateProductDiscount(state, action: PayloadAction<{ cartItemId; discountValue, marginWithDiscountValue, discountPercentage, qty}>) {
                const id                 = action.payload.cartItemId;
                const discount           = action.payload.discountValue;
                const discountPercentage = action.payload.discountPercentage;
                const mwd                = action.payload.marginWithDiscountValue;
                const qty                = action.payload.qty;
                const index              = state.modalDiscount.items.findIndex(item => item.id === id);

                state.modalDiscount.items[index].setDiscount(discount, qty);
                state.modalDiscount.items[index].setDiscountPercentageApplied(discountPercentage);
                state.modalDiscount.items[index].setMarginWithDiscount(mwd);
                state.modalDiscount.calculateTotal();
                state.modalDiscount.calculateMarginTotal();
                return state;
            },
            updateProductLastMarginMatched(state, action: PayloadAction<{ cartItemId, matched}>) {
                const id      = action.payload.cartItemId;
                const matched = action.payload.matched;
                const index   = state.modalDiscount.items.findIndex(item => item.id === id);
                const item: CartItemModel = state.modalDiscount.items[index];

                item.setLastMarginMatched(matched);
                item.setModifiedDiscount(true);
                return {...state};
            },
            updateProductMargin(state, action: PayloadAction<{ cartItemId, marginValue }>) {
                const id     = action.payload.cartItemId;
                const margin = action.payload.marginValue;
                const index  = state.modalDiscount.items.findIndex(item => item.id === id);

                state.modalDiscount.items[index].setMargin(margin);
                return {...state};
            },
            resetPendingDiscount(state, action: PayloadAction<{cart?}>) {
                const cart = action.payload?.cart;
                if(state.modalDiscount) {
                    state.modalDiscount.selectedRequestType = null;
                    state.modalDiscount.items.forEach(item => {
                        const cartItem = cart?.items.find(c => c.id === item.id);
                        item.resetPendingApprove();
                        item.setDiscountPercentageApplied(cartItem?.discount_percentage_applied ?? 0);
                    });
                }
                return {...state};
            },
            changeInitState(state, action: PayloadAction<boolean>) {
                state.initState = action.payload;
                return state;
            },
            changeWebPriceMatched(state, action: PayloadAction<{ cart, matched, discountLimit? }>) {
                const cart    = action.payload.cart;
                const matched = action.payload.matched;
                const i       = state.modalDiscount.items.findIndex(c => c.id === cart.id);
                state.modalDiscount.items[i].setPriceWebMatched(matched);
                return state;
            },
            modifiedDiscount(state, action: PayloadAction<{ cart, modified }>) {
                const cart     = action.payload.cart;
                const modified = action.payload.modified;

                const i = state.modalDiscount.items.findIndex(c => c.id === cart.id);
                state.modalDiscount.items[i].setModifiedDiscount(modified);

                return {...state};
            },
            setSelectedRequestType(state, action: PayloadAction<{ type: 'PASSWORD' | 'NOTIFICATION' | null }>) {
                state.modalDiscount.selectedRequestType = action.payload.type;
                return {...state};
            },
        },
    },
);

export const {
                 initModal,
                 resetModal,
                 setMaxDiscountEmployee,
                 updateProductDiscount,
                 updateProductLastMarginMatched,
                 resetPendingDiscount,
                 updateProductMargin,
                 changeInitState,
                 changeWebPriceMatched,
                 showPriceWeb,
                 modifiedDiscount,
                 setSelectedRequestType,
             } = modalDiscountSlice.actions;

export default modalDiscountSlice.reducer;
