import {Category} from '../../interfaces';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getCategories, getCategoriesTree} from '../../services/categories.service';

interface ICategorySlice {
    isLoading: boolean,
    categories: Category[],
    currentCategory: Category,
    categorySections: Category[],
    loadingCategoryTree: boolean,
}

const initialState: ICategorySlice = {
    categories: [],
    isLoading: false,
    categorySections: [],
    currentCategory: null,
    loadingCategoryTree: false
};

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCurrentCategory(state, action: PayloadAction<{ category }>) {
            state.currentCategory = action.payload.category;
        },
        setLoadingCategoryTree(state, action: PayloadAction<{ loadingCategoryTree }>) {
            state.loadingCategoryTree = action.payload.loadingCategoryTree;
        },
        removeCurrentCategory(state) {
            state.currentCategory = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCategories.pending, (state) => {
            state.categories = [];
            state.isLoading  = true;
            return state;
        });
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
            state.isLoading  = false;
            return state;
        });
        builder.addCase(getCategoriesTree.pending, (state) => {
            state.categorySections    = [];
            state.loadingCategoryTree = true;
            return state;
        });
        builder.addCase(getCategoriesTree.fulfilled, (state, action) => {
            let payload = action.payload;
            if (typeof payload === 'object' && payload !== null) {
                payload = Object.values(payload);
            }
            state.categorySections    = payload;
            state.loadingCategoryTree = false;
            return state;
        });
    },
});

export const {setCurrentCategory, removeCurrentCategory, setLoadingCategoryTree} = categoriesSlice.actions;
export default categoriesSlice.reducer;
