import {now} from 'moment/moment';

export default class GroupedProductModel {
    id: number            = null;
    code: string          = null;
    type: string          = null;
    title: string         = null;
    brand: string         = null;
    image: string         = null;
    price: number         = null;
    sap_price: number     = null;
    sap_cost: number      = null;
    margin_min: number    = null;
    qty_selected?: number = null; // Apply
    products_id: []       = [];// only for cross sell selection
    grouped_products: []  = [];
    variants: []          = [];
    lastUpdate: number    = null;
    categoryId: number    = null;

    constructor(props) {
        if (props) {
            this.id               = props.id || null;
            this.code             = props.code || null;
            this.type             = props.type || null;
            this.title            = props.title || null;
            this.image            = props.image || null;
            this.price            = props.price || null;
            this.sap_price        = props.sap_price || null;
            this.sap_cost         = props.sap_cost || null;
            this.margin_min       = props.margin_min || null;
            this.qty_selected     = props.qty_selected || null;
            this.products_id      = props.grouped_products ? props.grouped_products.map(gm => gm.id) : [];
            this.grouped_products = props.grouped_products ? props.grouped_products : [];
            this.variants         = props.variants ? props.variants : [];
            this.lastUpdate       = now();
            this.categoryId       = props.categoryId || null;
        }
    }
}
