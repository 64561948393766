import {InvoiceModel} from './invoice.model';

export class CustomerModel {
    id               = null;
    name             = null;
    last_name        = null;
    full_name        = null;
    rut              = null;
    user_id          = null;
    email            = null;
    phone            = null;
    business         = null;
    address          = null;
    city             = null;
    region           = null;
    sapClient        = null;
    paymentCondition = null;
    creditLine       = null;
    debt             = null;
    risk             = null;
    type             = null;
    isCompany        = null;
    sellers: []      = [];
    loaded           = null;
    isVolume= false;
    invoice_data: InvoiceModel = new InvoiceModel({});
    linked_customer  = null

    constructor(props) {
        if (props) {
            this.id               = props.id ?? null;
            this.name             = props.name ?? null;
            this.last_name        = props.last_name ?? null;
            this.full_name        = props.full_name || props.name + ' ' + props.last_name || null;
            this.rut              = props.rut ?? null;
            this.user_id          = props.user_id ?? null;
            this.email            = props.email ?? null;
            this.phone            = props.phone ?? null;
            this.business         = props.business ?? null;
            this.address          = props.address ?? null;
            this.city             = props.city ?? null;
            this.region           = props.region ?? null;
            this.sapClient        = props.sapClient ?? null;
            this.paymentCondition = props.paymentCondition ?? null;
            this.creditLine       = props.creditLine ?? null;
            this.debt             = props.debt ?? null;
            this.risk             = props.risk ?? null;
            this.type             = props.type ?? null;
            this.isCompany        = props.isCompany ?? null;
            this.sellers          = props.sellers ?? [];
            this.loaded           = null;
            this.isVolume         = props.isVolume ?? false
            this.invoice_data     = new InvoiceModel(props);
            this.linked_customer  = props.linked_customer ?? null;
            // if (props.linked_customer) {
            //     this.name         = props.linked_customer.name ?? null;
            //     this.last_name    = props.linked_customer.last_name ?? null;
            //     this.full_name    = props.full_name || props.linked_customer.name + ' ' + props.linked_customer.last_name || null;
            //     this.email        = props.linked_customer.email ?? null;
            //     this.phone        = props.linked_customer.phone ?? null;
            //     this.rut          = props.linked_customer.rut ?? null;
            // }
        }
    }

    setLoaded(rut) {
        this.loaded = rut;
    }
}
