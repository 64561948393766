import {createContext, useContext, useEffect, useState} from 'react';
import AuthService from '../pages/api/auth';
import {IAuthContext} from '../interfaces';
import Loader from '../components/Loader';
import {useRouter} from 'next/router';

export const AuthContext = createContext<IAuthContext>(
    {
        user: null,
        isLoading: false,
        isLoggedIn: false,
        login: () => () => {
        },
        logout: () => {
        },
    },
);

export const AuthContextProvider = ({children}) => {
    const auth = useProviderAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const useProviderAuth = () => {
    const authService                 = new AuthService();
    const [user, setUser]             = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading]   = useState(true);

    useEffect(() => {
        if (authService.authTokenExist()) {
            authService.me()
                .then(r => {
                    setUser(r.data.data);
                    setIsLoggedIn(true);
                })
                .catch(e => {
                    setUser(null);
                    setIsLoggedIn(false);
                    console.error('error', e);
                })
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, []);
    /* Login Handler */
    const loginHandler = (authData) => authService.setAuth(authData);
    /* Logout Handler */
    const logoutHandler = () => authService.logOut();
    return {
        user,
        isLoading,
        login: loginHandler,
        logout: logoutHandler,
        isLoggedIn,
    };
};

export const useAuth = () => useContext(AuthContext);

export const publicRoutes = [
    '/login',
    '/password-recovery',
    '/change-password',
    '/maintenance-mode',
    '/404',
];

export const ProtectRoute = ({children}) => {
    const {isLoggedIn, isLoading} = useAuth();
    const router                  = useRouter();

    const redirectProdesk = () => {
        const authService = new AuthService();
        const accessToken = authService.getAccessToken();
        window.open(`${process.env.PRODESK_BASE_URL}/?token=${accessToken}`, '_self');
    };

    if (isLoading) {
        return Loader();
    } else if (isLoggedIn && window.location.pathname.includes('login')) {
        if (router.query['from'] === 'prodesk') {
            redirectProdesk();
        } else {
            window.location.href = '/';
        }
        return Loader();
    } else if (!isLoggedIn && !publicRoutes.includes(router.pathname)) {
        window.location.href = '/login';
        return Loader();
    }
    return children;
};
