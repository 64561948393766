import {createAsyncThunk} from '@reduxjs/toolkit';
import ProductsAPI from '../pages/api/products';
import {RootState} from '../redux/store';
import Utils from '../utils/utils';
import moment from 'moment/moment';

export const getProducts = createAsyncThunk(
    'products/get',
    async (params: Object, {dispatch, getState}) => {
        const response  = await ProductsAPI.getProductsByParams(params);
        const data      = response && response.data || [];
        const rootState = getState() as RootState;

        data.data.forEach(({code, type}: any) => {
            const product    = rootState.products.productsGroup.find(p => p.code === code);
            let callDispatch = true;
            if (product) {
                callDispatch = moment(product.lastUpdate).isBefore(moment().subtract(30, 'minutes'));
            }
            if (type === 'GRUPO' && callDispatch) {
                dispatch(getProductGroup(code));
            }
        });

        return data;
    },
);

export const getProductGroup = createAsyncThunk(
    'product/group',
    async (code: any, {getState}) => {
        const rootState      = getState() as RootState;
        const office         = rootState.office;
        const groupedFilters = rootState.groupedFilters;
        const params         = Utils.transformFilters(groupedFilters.filters);
        const response       = await ProductsAPI.getGroupedProduct(code as string, {o: office.id, ...params});

        return response && response.data.data || [];
    },
);
