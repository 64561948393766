import {IOffice} from '../../interfaces';
import {createSlice} from '@reduxjs/toolkit';
import {getOffices} from '../../services/offices.service';

const initialState: IOffice[] = [];

export const officesSlice = createSlice({
    name: 'offices',
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder.addCase(getOffices.fulfilled, (state, action) => {
            return [...state, ...action.payload];
        });
    })
});

export default officesSlice.reducer;
