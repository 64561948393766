export const SalesSummaryIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.2884 2.61053V4.05895C13.2884 4.48 13.6253 4.81684 14.0463 4.81684C14.4674 4.81684 14.8042 4.48 14.8042 4.05895V0.757895C14.8042 0.336842 14.4674 0 14.0463 0H10.7621C10.3411 0 10.0042 0.336842 10.0042 0.757895C10.0042 1.17895 10.3411 1.51579 10.7621 1.51579H12.2105L8.2358 5.50737L6.38317 3.65474C6.08001 3.35158 5.59159 3.35158 5.30528 3.65474L0.286328 8.67369C-0.0168295 8.97684 -0.0168295 9.46526 0.286328 9.75158C0.437907 9.90316 0.640013 9.97053 0.825276 9.97053C1.01054 9.97053 1.21264 9.90316 1.36422 9.75158L5.84422 5.27158L7.69685 7.12421C8.00001 7.42737 8.48843 7.42737 8.77475 7.12421L13.2884 2.61053Z"
                fill="#005EB8"/>
            <path
                d="M2.35789 12.379H0.572632C0.252632 12.379 0 12.6316 0 12.9516V15.4105C0 15.7305 0.252632 15.9832 0.572632 15.9832H2.35789C2.67789 15.9832 2.93053 15.7305 2.93053 15.4105V12.9684C2.93053 12.6484 2.67789 12.379 2.35789 12.379Z"
                fill="#005EB8"/>
            <path
                d="M6.61894 10.4758H4.81683C4.49683 10.4758 4.2442 10.7284 4.2442 11.0484V15.4274C4.2442 15.7474 4.49683 16 4.81683 16H6.6021C6.9221 16 7.17473 15.7474 7.17473 15.4274V11.0484C7.19157 10.7284 6.93894 10.4758 6.61894 10.4758Z"
                fill="#005EB8"/>
            <path
                d="M10.88 8.55582H9.07788C8.75788 8.55582 8.50525 8.80845 8.50525 9.12845V15.4274C8.50525 15.7474 8.75788 16 9.07788 16H10.88C11.2 16 11.4526 15.7474 11.4526 15.4274V9.12845C11.4526 8.82529 11.1831 8.55582 10.88 8.55582Z"
                fill="#005EB8"/>
            <path
                d="M15.1242 6.65262H13.3389C13.0189 6.65262 12.7663 6.90525 12.7663 7.22525V15.4274C12.7663 15.7474 13.0189 16 13.3389 16H15.1242C15.4442 16 15.6968 15.7474 15.6968 15.4274V7.22525C15.6968 6.90525 15.4442 6.65262 15.1242 6.65262Z"
                fill="#005EB8"/>
        </svg>
    );
};