export const CashRegisterIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.75 18H6.3855C5.16404 16.5234 4.49708 14.6663 4.5 12.75C4.5 9.96 5.8845 7.4925 8.0055 6H0V14.25C0 15.2446 0.395088 16.1984 1.09835 16.9017C1.80161 17.6049 2.75544 18 3.75 18Z"
                fill="#005EB8"/>
            <path
                d="M18 4.5H0V3.75C0 2.75544 0.395088 1.80161 1.09835 1.09835C1.80161 0.395088 2.75544 0 3.75 0H14.25C15.2446 0 16.1984 0.395088 16.9016 1.09835C17.6049 1.80161 18 2.75544 18 3.75V4.5Z"
                fill="#005EB8"/>
            <path
                d="M12.75 19.5C13.6364 19.5 14.5142 19.3254 15.3331 18.9862C16.1521 18.647 16.8962 18.1498 17.523 17.523C18.1498 16.8962 18.647 16.1521 18.9862 15.3331C19.3254 14.5142 19.5 13.6364 19.5 12.75C19.5 11.8636 19.3254 10.9858 18.9862 10.1669C18.647 9.34794 18.1498 8.60382 17.523 7.97703C16.8962 7.35023 16.1521 6.85303 15.3331 6.51381C14.5142 6.17459 13.6364 6 12.75 6C10.9598 6 9.2429 6.71116 7.97703 7.97703C6.71116 9.2429 6 10.9598 6 12.75C6 14.5402 6.71116 16.2571 7.97703 17.523C9.2429 18.7888 10.9598 19.5 12.75 19.5ZM12 9.75C12 9.55109 12.079 9.36032 12.2197 9.21967C12.3603 9.07902 12.5511 9 12.75 9C12.9489 9 13.1397 9.07902 13.2803 9.21967C13.421 9.36032 13.5 9.55109 13.5 9.75V12H15C15.1989 12 15.3897 12.079 15.5303 12.2197C15.671 12.3603 15.75 12.5511 15.75 12.75C15.75 12.9489 15.671 13.1397 15.5303 13.2803C15.3897 13.421 15.1989 13.5 15 13.5H12.75C12.5511 13.5 12.3603 13.421 12.2197 13.2803C12.079 13.1397 12 12.9489 12 12.75V9.75Z"
                fill="#005EB8"/>
        </svg>
    );
};