import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ProductModel from '../../models/product.model';
import {getProductGroup, getProducts} from '../../services/products.service';
import GroupedProductModel from '../../models/grouped-product.model';

export interface IProductSlice {
    products: ProductModel[],
    productsIds: [],
    productsGroup: GroupedProductModel[],
    filteredProducts: any[],
    currentPage: number,
    totalPages: number,
    isLoading: boolean
}

const initialState: IProductSlice = {
    products: [],
    productsIds: [],
    productsGroup: [],
    filteredProducts: [],
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
};

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProducts(state, action: PayloadAction<IProductSlice>) {
            state = action.payload;
            return state;
        },
        resetProducts(state) {
            state = initialState;
            return state;
        },
        setFilteredProducts(state, action: PayloadAction<[]>) {
            state.filteredProducts = action.payload;
            return state;
        },
        updateFilteredProduct(state, action: PayloadAction<{
            product_id,
            sap_stock: number,
            stock_cds: number,
            sap_price: number,
            sap_cost: number
        }>) {
            const index = state.filteredProducts?.findIndex(p => p.id === action.payload.product_id);
            if (index > -1) {
                state.filteredProducts[index] = {...state.filteredProducts[index], ...action.payload};
            }
            return state;
        },
    },
    extraReducers: builder => {
        builder.addCase(getProducts.pending, (state) => {
            state.isLoading = true;
            return state;
        });
        builder.addCase(getProducts.fulfilled, (state, action) => {
            state.currentPage = action.payload.meta.current_page;
            state.totalPages  = action.payload.meta.last_page;
            state.products    = action.payload.data.map(product => new ProductModel(product));
            state.productsIds = action.payload.productIds;
            state.isLoading   = false;
            return state;
        });
        builder.addCase(getProductGroup.fulfilled, (state, action) => {
            const product = new GroupedProductModel(action.payload);
            const index   = state.productsGroup.findIndex(p => p.code === product.code);
            if (index > -1) {
                const productsGroup  = state.productsGroup;
                productsGroup[index] = product;
                state.productsGroup  = productsGroup;
            } else {
                state.productsGroup.push(product);
            }
            return state;
        });
    },
});

export const {setProducts, resetProducts, setFilteredProducts, updateFilteredProduct} = productsSlice.actions;

export default productsSlice.reducer;
