export const UserMenuIcon = (props) => {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="22.5" cy="22.5" r="22.5" fill="#8AC7F3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M39.78 22.5C39.78 32.0435 32.0435 39.78 22.5 39.78C12.9565 39.78 5.22 32.0435 5.22 22.5C5.22 12.9565 12.9565 5.21997 22.5 5.21997C32.0435 5.21997 39.78 12.9565 39.78 22.5ZM26.82 16.02C26.82 18.4058 24.8859 20.34 22.5 20.34C20.1141 20.34 18.18 18.4058 18.18 16.02C18.18 13.6341 20.1141 11.7 22.5 11.7C24.8859 11.7 26.82 13.6341 26.82 16.02ZM22.4999 24.66C18.142 24.66 14.3869 27.2411 12.6801 30.9579C15.0567 33.7148 18.5745 35.46 22.5 35.46C26.4253 35.46 29.9431 33.7148 32.3197 30.9581C30.6129 27.2411 26.8578 24.66 22.4999 24.66Z"
                  fill="white"/>
        </svg>
    );
};
