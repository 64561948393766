export const ClavosFijacionesIcon = (props) => {
    return (
        <svg
            width={24}
            height={35}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#icono-clavos-fijaciones_svg__clip0)">
                <path
                    d="M16.799 31.922c.27-.116.192-.581.192-.581s-.424-5.195-.462-5.544l-3.777-9.77L10.4 9.824l.27-.078c.54-.233 1.08-.737 1.272-1.202.193-.465.347-1.047-.077-2.016-.347-.775-.655-1.008-1.156-1.202-.463-.194-1.31-.039-1.696.116L4.388 7.265c-.424.155-.887.349-1.272 1.163-.27.581-.077 1.434.115 1.822.386.892.771 1.163 1.234 1.357.462.194 1.04.233 1.426.078l.462-.194 6.283 15.857 3.546 4.42c.308.387.617.154.617.154zM4.542 9.746a1.07 1.07 0 010-.775.99.99 0 01.54-.504L9.436 6.76c.27-.117.5-.155.77 0 .617.349.54.969.425 1.28a.99.99 0 01-.54.503L5.737 10.25c-.27.078-.925.194-1.195-.504zm3.16 1.28l1.388-.543 5.859 14.848.77 3.916-2.235-3.373-5.782-14.849zM16.066 21.648c.193 0 .309-.271.309-.271s1.349-3.218 1.426-3.45l.77-6.98.502-4.419.231-.039c.386.04.848-.077 1.118-.31.27-.232.54-.504.578-1.24.038-.543-.077-.815-.308-1.086-.232-.271-.771-.427-1.041-.465L16.336 3c-.308-.039-.655-.078-1.118.271-.308.272-.5.815-.5 1.125-.04.659.115.93.308 1.202.23.271.54.465.848.504l.347.038-1.195 11.438.732 3.721c.039.35.308.35.308.35zM15.604 4.59a.887.887 0 01.231-.466.96.96 0 01.501-.155l3.122.35c.193.038.347.077.463.232.27.387.038.736-.154.891a.96.96 0 01-.502.156l-3.122-.35c-.154-.038-.578-.154-.54-.658zm1.503 1.744l1.002.117-1.118 10.661-.77 2.56-.27-2.715 1.156-10.623z"/>
            </g>
            <defs>
                <clipPath id="icono-clavos-fijaciones_svg__clip0">
                    <path transform="translate(3 3)" d="M0 0h18v29H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};
