import {createSlice} from '@reduxjs/toolkit';
import {IPaymentOption} from '../../interfaces/payment-option';
import {getOfficePaymentOptions} from '../../services/payment-options.service';

interface paymentOptionsState {
    options: IPaymentOption[],
    isLoading: boolean
}

const initialState = {
    options: [],
    isLoading: false,
} as paymentOptionsState;

export const paymentOptionsSlice = createSlice({
    name: 'paymentOptions',
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder.addCase(getOfficePaymentOptions.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOfficePaymentOptions.fulfilled, (state, action) => {
            state.options   = action.payload;
            state.isLoading = false;
        });
    }),
});

export default paymentOptionsSlice.reducer;
