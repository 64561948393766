export enum PaymentOption {
    CASH          = 'CASH',
    DEBIT         = 'DEBIT',
    CREDIT        = 'CREDIT',
    BANK_CHECK    = 'BANK_CHECK',
    FUTURE_PAY    = 'FUTURE_PAY',
    CREDIT_LINE   = 'CREDIT_LINE',
    BANK_DEPOSIT  = 'BANK_DEPOSIT',
    WIRE_TRANSFER = 'WIRE_TRANSFER',
}
