import {AxiosPromise} from 'axios';
import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';

export const getHolidays = (): AxiosPromise => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.get(`/holidays`, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
};
