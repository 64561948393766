export const ArrowForward = (props) => (
    <svg {...props} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.5 0.500004H10.5C10.2348 0.500004 9.98043 0.605361 9.79289 0.792897C9.60536 0.980434 9.5 1.23479 9.5 1.5C9.5 1.76522 9.60536 2.01957 9.79289 2.20711C9.98043 2.39465 10.2348 2.5 10.5 2.5H18.5V18.5H10.5C10.2348 18.5 9.98043 18.6054 9.79289 18.7929C9.60536 18.9804 9.5 19.2348 9.5 19.5C9.5 19.7652 9.60536 20.0196 9.79289 20.2071C9.98043 20.3946 10.2348 20.5 10.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5V1.5C20.5 1.23479 20.3946 0.980434 20.2071 0.792897C20.0196 0.605361 19.7652 0.500004 19.5 0.500004Z"
            fill="#005EB8"/>
        <path
            d="M10.793 13.793C10.6975 13.8853 10.6213 13.9956 10.5689 14.1176C10.5165 14.2396 10.4889 14.3708 10.4877 14.5036C10.4866 14.6364 10.5119 14.7681 10.5622 14.891C10.6125 15.0139 10.6867 15.1255 10.7806 15.2194C10.8745 15.3133 10.9862 15.3875 11.109 15.4378C11.2319 15.4881 11.3636 15.5134 11.4964 15.5123C11.6292 15.5111 11.7604 15.4835 11.8824 15.4311C12.0044 15.3787 12.1148 15.3025 12.207 15.207L16.207 11.207C16.3001 11.1141 16.374 11.0038 16.4244 10.8823C16.4748 10.7608 16.5008 10.6305 16.5008 10.499C16.5008 10.3675 16.4748 10.2372 16.4244 10.1157C16.374 9.99425 16.3001 9.88389 16.207 9.791L12.207 5.791C12.0184 5.60885 11.7658 5.50805 11.5036 5.51033C11.2414 5.51261 10.9906 5.61778 10.8052 5.80319C10.6198 5.98859 10.5146 6.23941 10.5123 6.5016C10.51 6.7638 10.6108 7.0164 10.793 7.205L13.086 9.5H1.5C1.23478 9.5 0.98043 9.60536 0.792893 9.7929C0.605357 9.98043 0.5 10.2348 0.5 10.5C0.5 10.7652 0.605357 11.0196 0.792893 11.2071C0.98043 11.3946 1.23478 11.5 1.5 11.5H13.086L10.793 13.793Z"
            fill="#005EB8"/>
    </svg>
);
