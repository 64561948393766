export const DownloadableInformationIcon = (props) => {
    return (
        <svg
            width={22}
            height={22}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g clipPath="url(#downloadable-information_svg__clip0)">
                <path
                    d="M18.795 3.204a10.969 10.969 0 00-15.543 0 10.969 10.969 0 000 15.544 10.969 10.969 0 0015.543 0c4.257-4.257 4.257-11.24 0-15.544z"
                    fill="#005EB8"/>
                <path
                    d="M12.435 15.783A1.41 1.41 0 0111 17.217a1.41 1.41 0 01-1.435-1.434v-5.74A1.41 1.41 0 0111 8.61a1.41 1.41 0 011.435 1.434v5.74zM10.952 7.604c-.813 0-1.387-.574-1.339-1.291 0-.765.526-1.34 1.387-1.34.86 0 1.387.575 1.387 1.34 0 .67-.574 1.291-1.435 1.291z"
                    fill="#fff"/>
            </g>
            <defs>
                <clipPath id="downloadable-information_svg__clip0">
                    <path fill="#fff" d="M0 0h22v22H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};
