export const AlambresCercosIcon = (props) => {
    return (
        <svg
            width={30}
            height={30}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M25.792 3H4.208C3.54 3 3 3.54 3 4.208v21.584C3 26.46 3.54 27 4.208 27h21.584C26.46 27 27 26.46 27 25.792V4.208C27 3.54 26.46 3 25.792 3zm-.445 21.87a.42.42 0 01-.413.413H5.194a.42.42 0 01-.414-.413V5.162a.42.42 0 01.413-.414h19.74a.42.42 0 01.414.414V24.87z"
                />
            <path
                d="M7.736 24.107h-.095c-.318-.063-.572-.35-.509-.699 1.526-10.013.032-16.657 0-16.72a.636.636 0 01.446-.732.636.636 0 01.73.445c.064.286 1.558 6.898 0 17.166 0 .318-.254.54-.572.54zM22.073 24.107c-.286 0-.509-.19-.572-.477-.064-.286-1.558-6.898 0-17.165a.637.637 0 01.699-.509c.318.064.572.35.509.7-1.526 10.013-.032 16.657 0 16.72a.636.636 0 01-.445.731h-.191z"
                />
            <path
                d="M15.302 8.913c-2.543 0-5.595-.191-9.06-.7-.318-.063-.572-.35-.508-.7.063-.317.35-.571.7-.508 10.012 1.526 16.656.032 16.72 0a.636.636 0 01.73.445.636.636 0 01-.444.731c-.223.064-3.18.732-8.138.732zM6.338 22.867c-.286 0-.509-.19-.572-.476a.636.636 0 01.445-.731c.286-.064 6.898-1.558 17.165 0 .318.063.572.35.509.699-.064.318-.35.572-.7.508-10.013-1.525-16.657-.031-16.72 0h-.127z"
                />
            <path
                d="M12.536 23.948a.625.625 0 01-.604-.604V6.56c0-.318.287-.604.604-.604.318 0 .604.286.604.604v16.784c0 .35-.286.604-.604.604zM17.432 23.948a.625.625 0 01-.604-.604V6.56c0-.318.286-.604.604-.604s.604.286.604.604v16.784c0 .35-.286.604-.604.604z"
                />
            <path
                d="M23.44 13.013H6.656a.625.625 0 01-.604-.604c0-.318.286-.604.604-.604H23.44c.318 0 .604.286.604.604a.603.603 0 01-.604.604zM23.44 18.131H6.656a.625.625 0 01-.604-.604c0-.318.286-.604.604-.604H23.44c.318 0 .604.286.604.604a.604.604 0 01-.604.604z"
                />
        </svg>
    );
};
