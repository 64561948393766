export const BankCheckWhiteIcon = () => {
    return (
        <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.700195 20.8592V0.733276H34.3246V14.0368V14.378L29.354 20.8592H0.700195Z" fill="white"/>
            <path
                d="M26.82 19.1536L2.55197 18.9262V2.66627H32.8626V12.2175M29.354 17.9028V14.0368H34.3246M34.3246 14.0368V14.378L29.354 20.8592H0.700195V0.733276H34.3246V14.0368ZM7.71745 5.39521V16.1972M5.67075 14.0368H8.69207C10.3489 13.9231 10.4464 10.8531 8.69207 10.7394H6.8403C4.89106 10.7394 4.89106 7.66932 6.8403 7.66932H9.66669"
                stroke="#49A4E5"/>
            <path
                d="M15.418 8.69299H27.2109M13.4688 11.4219H24.4819M13.4688 14.0371H21.0708M26.0413 11.4219H27.2109M13.4688 8.69299H14.151"
                stroke="#49A4E5"/>
        </svg>
    );
};
