interface ICreditCardIcon {
    className?: string
}

export const CreditCardIcon = ({className}: ICreditCardIcon) => {
    return (
        <svg className={className || ''} width="30" height="23" viewBox="0 0 30 23" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.75 0.5C2.75544 0.5 1.80161 0.895088 1.09835 1.59835C0.395088 2.30161 0 3.25544 0 4.25V6.125H30V4.25C30 3.25544 29.6049 2.30161 28.9016 1.59835C28.1984 0.895088 27.2446 0.5 26.25 0.5H3.75Z"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M30 9.87534H0V19.2503C0 20.2449 0.395088 21.1987 1.09835 21.902C1.80161 22.6052 2.75544 23.0003 3.75 23.0003H26.25C27.2446 23.0003 28.1984 22.6052 28.9016 21.902C29.6049 21.1987 30 20.2449 30 19.2503V9.87534ZM3.75 17.3753C3.75 16.8781 3.94754 16.4011 4.29918 16.0495C4.65081 15.6979 5.12772 15.5003 5.625 15.5003H7.5C7.99728 15.5003 8.47419 15.6979 8.82582 16.0495C9.17746 16.4011 9.375 16.8781 9.375 17.3753C9.375 17.8726 9.17746 18.3495 8.82582 18.7012C8.47419 19.0528 7.99728 19.2503 7.5 19.2503H5.625C5.12772 19.2503 4.65081 19.0528 4.29918 18.7012C3.94754 18.3495 3.75 17.8726 3.75 17.3753ZM13.125 15.5003C12.6277 15.5003 12.1508 15.6979 11.7992 16.0495C11.4475 16.4011 11.25 16.8781 11.25 17.3753C11.25 17.8726 11.4475 18.3495 11.7992 18.7012C12.1508 19.0528 12.6277 19.2503 13.125 19.2503H15C15.4973 19.2503 15.9742 19.0528 16.3258 18.7012C16.6775 18.3495 16.875 17.8726 16.875 17.3753C16.875 16.8781 16.6775 16.4011 16.3258 16.0495C15.9742 15.6979 15.4973 15.5003 15 15.5003H13.125Z"
            />
        </svg>
    );
};
