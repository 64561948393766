const FileIcon = () => {
    return (
        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4 4.18656H16L22 10.4366V18.7699C22 19.9157 21.1 20.8532 20 20.8532H4C2.9 20.8532 2 19.9261 2 18.7803V6.2699C2 5.12406 2.9 4.18656 4 4.18656ZM4 6.2699V18.7803H20V11.4782H15V6.2699H4Z"
                  fill="#005EB8"/>
        </svg>
    )
}

export default FileIcon;