import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface LoadingState {
    isLoading: boolean
}

const initialState: LoadingState = {
    isLoading: false,
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
});

export const {setLoading} = loadingSlice.actions;

export default loadingSlice.reducer;
