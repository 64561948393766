export const CreditLineSkyBlueIcon = (props) => {
    return (
        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="14.5" cy="14" r="14" fill="#49A4E5"/>
            <path
                d="M3.74758 13.1272L6.52236 10.3524M3.74758 13.1272L1.6665 9.65874M3.74758 13.1272C2.24984 1.63319 22.0832 -3.61683 25.9458 12.4335"
                stroke="white" strokeWidth="1.5"/>
            <path
                d="M25.583 14.4667L22.6663 17.5001M25.583 14.4667L27.333 18.0834M25.583 14.4667C26.7497 27.4167 5.16634 30.9167 3.74742 15.2084"
                stroke="white" strokeWidth="1.5"/>
            <path
                d="M16.3345 20.3426V19.1361C17.8813 18.7957 19.026 17.5273 19.1497 16.0424C19.2734 14.743 18.7166 13.5365 17.6029 12.7321C16.9841 12.299 16.2416 12.1134 15.5301 12.1134H14.5711C14.2617 12.1134 13.9833 11.9897 13.7667 11.7422C13.5501 11.4947 13.4573 11.2162 13.4883 10.9069C13.5501 10.35 14.0451 9.9169 14.6639 9.9169H15.9013C16.2107 9.9169 16.4891 10.1644 16.551 10.4428C16.6129 10.6903 16.8294 10.8759 17.0769 10.8759H18.4382C18.5928 10.8759 18.7475 10.8141 18.8403 10.6903C18.9331 10.5666 18.995 10.4428 18.9641 10.2881C18.8094 8.83412 17.7266 7.72039 16.3035 7.50384V6.35918C16.3035 6.08075 16.056 5.83325 15.7776 5.83325H14.3545C14.0761 5.83325 13.8286 6.08075 13.8286 6.35918V7.53477C12.0652 7.90601 10.8587 9.54566 11.0133 11.3709C11.199 13.1653 12.8077 14.5883 14.6948 14.5883H15.592C15.9013 14.5883 16.1798 14.7121 16.3963 14.9596C16.6129 15.2071 16.7057 15.4855 16.6748 15.7949C16.6129 16.3517 16.1179 16.7849 15.4992 16.7849H14.2617C13.9833 16.7849 13.6739 16.5374 13.612 16.2589C13.5501 16.0114 13.3336 15.8258 13.0861 15.8258H11.6939C11.5393 15.8258 11.3846 15.8877 11.2918 16.0114C11.199 16.1352 11.1371 16.2589 11.168 16.4136C11.3227 17.8676 12.4055 18.9814 13.8286 19.1979V20.3426C13.8286 20.621 14.0761 20.8685 14.3545 20.8685H15.7776C16.087 20.8685 16.3345 20.621 16.3345 20.3426Z"
                fill="white"/>
        </svg>
    );
};
