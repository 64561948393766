export const ArrowLeft = (props) => {
    const w = props.width || 129;
    const h = props.height || 130;
    return (
        <svg width={w} height={h} viewBox="0 0 129 130" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M40.6079 50.8769C35.4409 51.014 30.4915 51.4479 25.2844 51.8427C20.0773 52.2374 15.8613 53.0467 11.0609 53.3715L11.9319 54.559C14.1802 55.2359 16.3368 56.184 18.3542 57.3823C18.794 57.7295 19.1082 58.2108 19.2491 58.7533C19.3901 59.2957 19.3501 59.8699 19.1353 60.3887C18.9204 60.9075 18.5424 61.3428 18.0585 61.6286C17.5746 61.9144 17.0111 62.0352 16.4534 61.9726C14.1947 61.0727 12.0736 59.8623 10.1512 58.3763L3.10448 55.3262C2.69154 55.1452 2.33362 54.8592 2.06638 54.4966C1.79914 54.1339 1.63192 53.7073 1.5814 53.2594C1.53087 52.8114 1.59881 52.3576 1.77842 51.9433C1.95804 51.5291 2.24308 51.1687 2.60511 50.8982C5.15883 49.1291 7.87005 47.5991 10.7042 46.3277C13.3714 44.8755 16.2466 43.8448 19.2275 43.2723C19.6117 43.2058 20.0066 43.2764 20.3432 43.4717C20.6798 43.6669 20.9365 43.9745 21.0684 44.3405C21.2003 44.7065 21.199 45.1076 21.0647 45.4735C20.9304 45.8394 20.6716 46.1468 20.3338 46.342C18.267 48.1966 16.0224 49.8429 13.6325 51.257L21.0734 49.2239C25.2153 48.3026 29.4143 47.6636 33.6413 47.3114C42.1711 46.5447 50.7454 46.4382 59.2877 46.9928C75.7369 48.3049 91.7553 52.848 106.425 60.3619C114.795 64.0939 122.225 69.6385 128.179 76.5954C128.942 77.6345 127.601 78.6175 126.393 77.9061C118.264 72.01 109.751 66.6617 100.909 61.8965C91.5577 57.798 81.7271 54.8848 71.6454 53.2247C61.3966 51.4914 51.0069 50.7055 40.6079 50.8769Z"
                fill="#00ADEF"/>
        </svg>
    );
};
