export const DebitCarAltIcon = (props) => {
    return (
        <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0 4C0 3.00544 0.395088 2.05161 1.09835 1.34835C1.80161 0.645088 2.75544 0.25 3.75 0.25H26.25C27.2446 0.25 28.1984 0.645088 28.9016 1.34835C29.6049 2.05161 30 3.00544 30 4V5.875H0V4Z"
                fill="#49A4E5"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 9.625V19C0 19.9946 0.395088 20.9484 1.09835 21.6516C1.80161 22.3549 2.75544 22.75 3.75 22.75H26.25C27.2446 22.75 28.1984 22.3549 28.9016 21.6516C29.6049 20.9484 30 19.9946 30 19V9.625H0ZM5.625 13.375C5.12772 13.375 4.65081 13.5725 4.29917 13.9242C3.94754 14.2758 3.75 14.7527 3.75 15.25V17.125C3.75 17.6223 3.94754 18.0992 4.29917 18.4508C4.65081 18.8025 5.12772 19 5.625 19H7.5C7.99728 19 8.47419 18.8025 8.82582 18.4508C9.17746 18.0992 9.375 17.6223 9.375 17.125V15.25C9.375 14.7527 9.17746 14.2758 8.82582 13.9242C8.47419 13.5725 7.99728 13.375 7.5 13.375H5.625Z"
                  fill="#49A4E5"/>
        </svg>
    );
};
