import {createSlice} from '@reduxjs/toolkit';
import {IDeliveryPrice} from '../../interfaces/delivery-price';
import {getDeliveryPrices} from '../../services/delivery-prices.service';

interface IDeliverPricesSlice {
    data: IDeliveryPrice[],
    price_min: number,
    price_max: number
}

const initialState: IDeliverPricesSlice = {
    data: [],
    price_min: 4990,
    price_max: 59990,
};

export const deliveryPricesSlice = createSlice({
    name: 'deliveryPrices',
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder.addCase(getDeliveryPrices.fulfilled, (state, action) => {
            state.data      = [...action.payload.data];
            state.price_min = action.payload.price_min;
            state.price_max = action.payload.price_max;
        });
    }),
});

export default deliveryPricesSlice.reducer;
