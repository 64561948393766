export const CabosCadenasIcon = (props) => {
    return (
        <svg
            width={39}
            height={35}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g clipPath="url(#icono-cabosycadenas_svg__clip0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4 26.3l2.4.5h1v1.5h-.5-.1c-.1 0-.1 0-.2.1l-.6.3c-.3.2-.6.4-.8.5 0 0-.1 0-.1.1-2.8 1.8-4.6 2.3-5.9 2-1.3-.3-1.8-1.5-1.9-2.3-.1-.7-.1-1.3 0-1.8.2-.6.6-1 1.2-1.2.6-.2 1.3-.3 2.2-.2.9-.1 2 .1 3.3.5zm1.7 1.3l-2-.4c-1.2-.4-2.3-.6-3.1-.7-.8-.1-1.4 0-1.7.1-.3.1-.5.3-.6.6-.1.3-.1.7 0 1.3.1.7.5 1.3 1.2 1.5.8.2 2.3-.1 5.1-1.8l.1-.1c.2-.1.5-.3.8-.5.1.1.2.1.2 0z"
                />
                <path
                    d="M16 28.3v-1c13.4 0 18.3-6.3 18.3-10.5h1c-.1 4.6-5.2 11.5-19.3 11.5z"
                />
                <path
                    d="M19.3 24.9c-9 0-16-3.6-16-8.2v-5.2c0-4.6 7-8.2 16-8.2s16 3.6 16 8.2v5.3c0 4.5-7.1 8.1-16 8.1zm0-20.6c-8.3 0-15 3.2-15 7.2v5.3c0 4 6.7 7.2 15 7.2s15-3.2 15-7.2v-5.3c-.1-4-6.8-7.2-15-7.2z"
                />
                <path
                    d="M19.2 19.5c-9 0-16-3.5-16-8h1c0 3.8 6.9 7 15 7s15-3.2 15-7h1c0 4.5-7 8-16 8z"
                />
                <path
                    d="M19.2 21.3c-9 0-16-3.5-16-8h1c0 3.8 6.9 7 15 7s15-3.2 15-7h1c0 4.5-7 8-16 8z"
                />
                <path
                    d="M19.2 23.1c-9 0-16-3.5-16-8h1c0 3.8 6.9 7 15 7s15-3.2 15-7h1c0 4.5-7 8-16 8z"
                />
                <path
                    d="M19.2 24.9c-9 0-16-3.5-16-8h1c0 3.8 6.9 7 15 7s15-3.2 15-7h1c0 4.5-7 8-16 8zM19.2 14.4c-3.6 0-9.8-.6-9.8-2.9 0-2.1 5-3.3 9.8-3.3 4.7 0 9.6 1.2 9.8 3.2 0 .3 0 .7-.4 1.1-1.3 1.4-5.7 1.9-9.4 1.9zm0-5.2c-5.3 0-8.8 1.4-8.8 2.3 0 .7 2.9 1.9 8.8 1.9 4.2 0 7.8-.7 8.6-1.6.2-.1.2-.2.2-.3 0-.8-3.4-2.3-8.8-2.3z"
                />
                <path
                    d="M19.3 16.2c-6.7 0-11.7-2-11.7-4.7s5.3-4.9 11.7-4.9c6.4 0 11.7 2.2 11.7 4.9s-5.1 4.7-11.7 4.7zm0-8.6c-6.1 0-10.7 2.1-10.7 3.9 0 1.7 4.4 3.7 10.7 3.7 6.3 0 10.7-2 10.7-3.7 0-1.9-4.6-3.9-10.7-3.9z"
                />
                <path
                    d="M19.3 17.9c-7.8 0-13.9-2.8-13.9-6.4C5.4 7.9 11.6 5 19.3 5s13.9 2.9 13.9 6.5c-.1 3.6-6.2 6.4-13.9 6.4zm0-11.9c-7 0-12.9 2.5-12.9 5.5 0 2.9 5.9 5.4 12.9 5.4s12.9-2.5 12.9-5.4c-.1-3-6-5.5-12.9-5.5zM16.8 26.8h-2v1.8h2v-1.8z"
                />
                <path d="M3.5 18.6H3v-8.1l7.8.5-.1.5-7.2-.5v7.6z"/>
                <path
                    d="M4.5 20.3H4v-8.8l7.8.5v.5L4.5 12v8.3zM19.5 25.1H19l1-5.8-1.5-6.1h.5l1.5 6v.1l-1 5.8z"
                />
                <path
                    d="M20.5 25.1H20l1-5.8-1.5-6.1h.5l1.5 6v.1l-1 5.8zM20.4 9.3h-.5V5.4l-3.3-2.2.7-.2 3.2 2.1v4.2h-.1zm-1 0h-.5V5.9l-3.5-2.5.6-.3 3.5 2.5v3.7h-.1zM35.5 18.7H35V11l-7.5.5V11l8-.5v8.2z"
                />
                <path d="M34.5 20.9H34V12l-7.6.5-.1-.5 8.2-.5v9.4z"/>
            </g>
            <defs>
                <clipPath id="icono-cabosycadenas_svg__clip0">
                    <path transform="translate(3 3)" d="M0 0h32.5v28.2H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};
