import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IUiSlice {
    disableFilters: boolean;
}

const initialState: IUiSlice = {
    disableFilters: false,
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setDisableFilter(state, action: PayloadAction<{ disabled: boolean }>) {
            state.disableFilters = action.payload.disabled;
        },
    },
});

export const {setDisableFilter} = uiSlice.actions;
export default uiSlice.reducer;
