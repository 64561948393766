export const ProductIcon = () => {
    return (
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.833252 0.649902H19.1666V4.18324H0.833252V0.649902ZM16.4166 5.06657H1.74992V14.7832C1.74992 15.2518 1.94307 15.7011 2.28689 16.0325C2.63071 16.3638 3.09702 16.5499 3.58325 16.5499H16.4166C16.9028 16.5499 17.3691 16.3638 17.7129 16.0325C18.0568 15.7011 18.2499 15.2518 18.2499 14.7832V5.06657H16.4166ZM13.6666 10.3666H6.33325V8.5999H13.6666V10.3666Z"
                fill="#005EB8"/>
        </svg>
    );
};

