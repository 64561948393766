import {createSlice} from '@reduxjs/toolkit';

interface IOrdersListSlice {
    page: number;
    orders: any[];
    searchTerm: string;
    searchColumn: string;
    ticketsChecked: boolean;
    logisticStatus: string;
    invoicesChecked: boolean;
    officeId: number;
    seller: string;
    customer: string;
    sku: string;
}

const initialState = {
    page: 1,
    orders: [],
    searchTerm: '',
    searchColumn: 'order',
    ticketsChecked: true,
    logisticStatus: 'TODOS',
    invoicesChecked: false,
    officeId: null,
    seller: null,
    customer: null,
    sku: null,
} as IOrdersListSlice;

export const ordersListSlice = createSlice({
    name: 'orders-list',
    initialState,
    reducers: {
        addOrderToPay(state, action) {
            const order = action.payload;
            state.orders.push(order);
            return state;
        },
        addMultipleOrdersToPay(state, action) {
            const ordersToAdd = action.payload || [];
            state.orders      = [...new Set([...state.orders, ...ordersToAdd])];
            return state;
        },
        removeOrderToPay(state, action) {
            const orderCode = action.payload;
            state.orders    = state.orders.filter(o => o.code !== orderCode);
            return state;
        },
        removeMultipleOrdersToPay(state, action) {
            const ordersToRemove = action.payload || [];
            const codes          = ordersToRemove.map(o => o.code);
            state.orders         = state.orders.filter(o => !codes.includes(o.code));
            return state;
        },
        emptyOrdersToPay(state) {
            state.orders = [];
            return state;
        },
        updateFilter(state, action) {
            const filterCode  = action.payload.filterCode;
            const filterValue = action.payload.filterValue;
            switch (filterCode) {
                case 'page':
                    state.page = filterValue;
                    break;
                case 'orders':
                    state.page   = 1;
                    state.orders = filterValue;
                    break;
                case 'searchTerm':
                    state.page       = 1;
                    state.searchTerm = filterValue;
                    break;
                case 'searchColumn':
                    state.page         = 1;
                    state.searchColumn = filterValue;
                    break;
                case 'ticketsChecked':
                    state.page           = 1;
                    state.ticketsChecked = filterValue;
                    break;
                case 'logisticStatus':
                    state.page           = 1;
                    state.logisticStatus = filterValue;
                    break;
                case 'invoicesChecked':
                    state.page            = 1;
                    state.invoicesChecked = filterValue;
                    break;
                case 'seller':
                    state.page   = 1;
                    state.seller = filterValue;
                    break;
                case 'customer':
                    state.page     = 1;
                    state.customer = filterValue;
                    break;
                case 'sku':
                    state.page = 1;
                    state.sku  = filterValue;
                    break;
            }
            return state;
        },
        resetFilters(state) {
            state.page            = 1;
            state.orders          = [];
            state.searchTerm      = '';
            state.searchColumn    = 'order';
            state.ticketsChecked  = true;
            state.logisticStatus  = 'TODOS';
            state.invoicesChecked = false;
            state.seller          = null;
            state.customer        = null;
            state.sku             = null;
            return state;
        },
        setPage(state, action) {
            state.page = action.payload || 1;
            return state;
        },
        setOfficeId(state, action) {
            state.officeId = action.payload;
            return state;
        },
    },
});

export const {
                 setPage,
                 setOfficeId,
                 updateFilter,
                 resetFilters,
                 addOrderToPay,
                 removeOrderToPay,
                 emptyOrdersToPay,
                 addMultipleOrdersToPay,
                 removeMultipleOrdersToPay,
             } = ordersListSlice.actions;

export default ordersListSlice.reducer;
