import {IHoliday} from '../../interfaces/holiday';
import {createSlice} from '@reduxjs/toolkit';
import {GetHolidays} from '../../services/holidays.service';

interface holidaysState {
    days: IHoliday[],
    isLoading: boolean
}

const initialState = {
    days: [],
    isLoading: false,
} as holidaysState;

export const holidaysSlice = createSlice({
    name: 'holidays',
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder.addCase(GetHolidays.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(GetHolidays.fulfilled, (state, action) => {
            state.days      = action.payload;
            state.isLoading = false;
        });
    })
});

export default holidaysSlice.reducer;
