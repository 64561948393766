export const MixedPaymentIcon = (props) => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.0785 15.5594L15.4744 16.1812C15.5308 16.2944 15.7004 16.3508 15.9268 16.2376C16.0963 16.1244 16.0963 15.9549 16.0399 15.8417L15.644 15.2198C16.4354 14.5416 16.605 13.6369 16.0963 12.9019C15.5308 12.0541 14.5698 12.1104 13.6656 12.28C13.496 12.054 13.3829 11.8277 13.2133 11.6017C13.1001 11.3758 12.9306 11.2058 12.8174 10.9798C13.1001 10.9235 13.3829 10.8667 13.5524 10.8667H13.6088C13.6652 10.8667 13.722 10.8667 13.7784 10.8103C13.948 10.6971 13.948 10.4711 13.948 10.2448V10.1316C13.948 9.90566 13.7784 9.79248 13.6088 9.79248C13.3829 9.79248 12.8174 9.84887 12.3087 10.1316L12.1955 10.0184C12.0823 9.90526 11.9127 9.84887 11.7996 9.96205L11.7432 10.0184C11.63 10.1316 11.5736 10.3012 11.6868 10.4144L11.7432 10.5275C10.9517 11.2626 10.9517 12.1105 11.2345 12.6191C11.2909 12.7323 11.3476 12.7887 11.404 12.9019C11.9695 13.6369 12.8174 13.6369 13.6651 13.4106C13.8347 13.6366 14.0043 13.8629 14.1175 14.0889C14.2306 14.3148 14.4002 14.5412 14.5698 14.7107C14.1175 14.8239 13.6652 14.8239 13.5524 14.7671C13.3828 14.7671 13.2696 14.8803 13.2132 15.1063V15.5022C13.2132 15.7849 13.3828 15.7849 13.496 15.8413C13.6088 15.8421 14.287 15.9549 15.0785 15.5594L15.0785 15.5594ZM12.0823 12.0544C11.9691 11.8849 11.9127 11.6021 12.1955 11.263L12.9305 12.3372C12.5346 12.3936 12.2518 12.2804 12.0823 12.0545V12.0544ZM14.2871 13.2414C14.7394 13.2414 14.9653 13.2978 15.1349 13.5242C15.3045 13.7501 15.2481 14.0896 15.0217 14.3156L14.2871 13.2414Z"
                fill="#49A4E5"/>
            <path
                d="M31.8126 17.8207H18.0186L27.0072 11.7715C27.4595 11.4324 27.5727 10.8105 27.2899 10.3582L21.1844 1.36998C20.8453 0.917668 20.2234 0.804491 19.7711 1.08724L0.436874 14.0898C-0.0154381 14.4289 -0.128614 15.0508 0.15413 15.5031L6.20334 24.4917C6.54247 24.944 7.16434 25.0572 7.61665 24.7744L13.1568 21.0432C13.2132 21.0996 13.3263 21.0996 13.4395 21.0996H32.4345C32.7173 21.0996 33 20.8736 33 20.5341V18.8948C32.9996 18.3297 32.4909 17.8206 31.8126 17.8206L31.8126 17.8207ZM9.82133 15.6159C8.35158 13.4676 8.9735 10.5282 11.1215 9.11487C13.2698 7.64511 16.2092 8.26704 17.6225 10.415C19.0923 12.5634 18.4704 15.5028 16.3224 16.9161C14.174 18.3298 11.2347 17.7643 9.82133 15.6159Z"
                fill="#49A4E5"/>
            <path
                d="M32.4335 23.2478H13.4385C13.1558 23.2478 12.873 23.4738 12.873 23.8133V30.9363C12.873 31.5582 13.3818 32.0669 14.0036 32.0669H31.8114C32.4333 32.0669 32.942 31.5582 32.942 30.9363L32.9424 23.813C32.9988 23.5306 32.716 23.2479 32.4337 23.2479L32.4335 23.2478ZM24.0101 26.3572C24.0101 26.4136 23.9537 26.4704 23.8969 26.4704L21.5789 26.47C21.5226 26.47 21.4658 26.4136 21.4658 26.3568V25.0567C21.4658 25.0003 21.5222 24.9435 21.5789 24.9435H23.8969C23.9533 24.9435 24.0101 24.9999 24.0101 25.0567V26.3572ZM27.5717 26.3572C27.5717 26.4136 27.5153 26.4704 27.4585 26.4704H25.1406C25.0842 26.4704 25.0274 26.414 25.0274 26.3572V25.0571C25.0274 25.0007 25.0838 24.9439 25.1406 24.9439H27.4585C27.5149 24.9439 27.5717 25.0003 27.5717 25.0571V26.3572ZM31.133 26.3572C31.133 26.4136 31.0766 26.4704 31.0198 26.4704H28.7019C28.6455 26.4704 28.5887 26.414 28.5887 26.3572V25.0571C28.5887 25.0007 28.6451 24.9439 28.7019 24.9439H31.0198C31.0762 24.9439 31.133 25.0003 31.133 25.0571V26.3572Z"
                fill="#49A4E5"/>
        </svg>
    );
};
