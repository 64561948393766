export const PersonBoxIcon = (props) => {
    return (
        <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="11.1408" cy="4.37273" r="4.37273" fill="#005EB8"/>
            <path
                d="M17.0076 14.4068L5.00195 13.7949C5.00195 13.7949 6.17104 9.51192 10.8474 9.60605C15.5238 9.70018 17.0076 14.4068 17.0076 14.4068Z"
                fill="#005EB8" stroke="#005EB8"/>
            <path
                d="M10.6755 11.4435L17.0951 13.8159V21.6775L11.0942 24.05L4.72119 21.6775V13.8159L7.60533 12.6668L8.39615 12.3517L10.6755 11.4435Z"
                fill="white"/>
            <path
                d="M4.72119 13.8159V21.6775L11.0942 24.05M4.72119 13.8159L11.0942 16.421M4.72119 13.8159L7.60533 12.6668M17.0951 13.8159L10.6755 11.4435L8.39615 12.3517M17.0951 13.8159V21.6775L11.0942 24.05M17.0951 13.8159L14.5366 14.9266M11.0942 24.05V16.421M11.0942 16.421L14.5366 14.9266M14.5366 14.9266V16.421L13.8388 16.8396V15.2295L7.60533 12.6668M14.5366 14.9266L8.39615 12.3517M8.39615 12.3517L7.60533 12.6668"
                stroke="#005EB8"/>
            <path
                d="M4.31861 18.3415L4.22898 14.4068C2.48115 15.8823 0.553966 19.1016 1.09178 19.5934C2.16741 20.5771 4.76679 21.516 5.25977 21.6055C5.65417 21.677 6.94905 20.9348 6.51466 19.6828C6.23786 18.8851 4.85642 18.4756 4.31861 18.3415Z"
                fill="#005EB8" stroke="#005EB8"/>
            <path
                d="M20.0225 18.4678L17.4174 14.7929L16.8592 18.3748C15.7462 18.7469 15.6997 18.7935 14.2576 19.3517C12.8156 19.9099 14.0681 22.3754 15.7461 21.7706C17.4242 21.1659 18.6699 20.2821 19.6503 19.9099C20.6307 19.5378 20.0225 18.4678 20.0225 18.4678Z"
                fill="#005EB8" stroke="#005EB8"/>
        </svg>
    );
};
