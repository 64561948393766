import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import BankCheckModel from '../../models/bank-check.model';

const initialState: BankCheckModel[] = [];

export const withdrawalSlice = createSlice({
    name: 'withdrawal',
    initialState,
    reducers: {
        addBankChecks(state, action: PayloadAction<{ bankChecks: BankCheckModel[] }>) {
            const bankChecks = action.payload.bankChecks;
            let newState     = [...(state), ...bankChecks].sort((x, y) => (new Date(x.bank_check_reception_date)) > (new Date(y.bank_check_reception_date)) ? -1 : 1);
            return [...newState];
        },
        addBankCheck(state, action: PayloadAction<{ bankCheck: BankCheckModel }>) {
            const bankCheck = action.payload.bankCheck;
            let newState    = [...(state), bankCheck].sort((x, y) => (new Date(x.bank_check_reception_date)) > (new Date(y.bank_check_reception_date)) ? -1 : 1);
            return [...newState];
        },
        removeBankCheck(state, action: PayloadAction<{ id }>) {
            const id          = action.payload.id;
            let filteredState = state.filter(bc => bc.id !== id);
            return [...filteredState];
        },
        removeAllBankChecks() {
            return [];
        },
        toggleSelectedValue(state, action: PayloadAction<{ id, value }>) {
            const id       = action.payload.id;
            const value    = action.payload.value;
            const newState = state.map(bc => {
                if (bc.id === id) {
                    bc.selected = value;
                    return bc;
                } else {
                    return bc;
                }
            });
            return [...newState];
        },
        toggleAll(state, action: PayloadAction<{ value }>) {
            const value = action.payload.value;
            state.map(bc => bc.selected = value);
        },
    },
});

export const {
                 addBankCheck,
                 removeBankCheck,
                 addBankChecks,
                 removeAllBankChecks,
                 toggleSelectedValue,
                 toggleAll,
             } = withdrawalSlice.actions;

export default withdrawalSlice.reducer;
