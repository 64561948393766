import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FilterOptionModel, TypeFilter} from '../../models/filter-option.model';
import {Category} from '../../interfaces';
import {getFilters} from '../../services/filters.service';
import Utils from '../../utils/utils';

export interface IProductFiltersSlice {
    getProducts: boolean,
    getFilters: boolean,
    filters: FilterOptionModel[]
    loadingFilters: boolean,
    rangePrices?: {
        min: number,
        max: number,
    },
    filterOptions?: []
}

// Default filters
const initialState: IProductFiltersSlice = {
    getProducts: false,
    getFilters: true,
    filters: [],
    loadingFilters: false,
    rangePrices: {
        max: 0,
        min: 0,
    },
    filterOptions: [],
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        initFilters(state, action: PayloadAction<FilterOptionModel[]>) {
            state.filters     = action.payload;
            state.getProducts = true;
            return state;
        },
        addStockFilter(state) {
            state.filters.push(new FilterOptionModel('Stock en tienda', 'STOCK_STORE', TypeFilter.TYPE_STOCK));
            state.getFilters = true;
            return state;
        },
        removeStockFilter(state) {
            state.filters    = state.filters.filter((filter) => filter?.type !== TypeFilter.TYPE_STOCK && filter?.value !== 'STOCK_STORE');
            state.getFilters = true;
            return state;
        },
        AddOrderFilter(state, action: PayloadAction<{ order: FilterOptionModel }>) {
            const index = state.filters.findIndex(filter => filter != null && filter?.type === TypeFilter.TYPE_SORT);
            if (index >= 0) {
                state.filters[index] = action.payload.order;
            } else {
                state.filters.push(action.payload.order);
            }
            state.getFilters = false;
            return state;
        },
        onChangeOffice(state, action: PayloadAction<FilterOptionModel>) {
            const index = state.filters.findIndex(filter => filter != null && filter?.type === TypeFilter.TYPE_OFFICE);
            if (index >= 0) {
                state.filters[index] = action.payload;
            } else {
                state.filters.push(action.payload);
            }
            state.getFilters = true;
            return state;
        },
        onChangePage(state, action: PayloadAction<{ pageNumber: number }>) {
            const index   = state.filters.findIndex(filter => filter != null && filter?.type === TypeFilter.TYPE_PAGE);
            const pageObj = new FilterOptionModel('Page', action.payload.pageNumber, TypeFilter.TYPE_PAGE, false);
            if (index >= 0) {
                state.filters[index] = pageObj;
            } else {
                state.filters.push(pageObj);
            }
            state.getFilters = false;
            return state;
        },
        onChangeCategory(state, action: PayloadAction<{ category: Category, shouldBeDisplayed?: boolean }>) {
            const index       = state.filters.findIndex(filter => filter != null && filter?.type === TypeFilter.TYPE_CATEGORY);
            const categoryObj = new FilterOptionModel(action.payload.category.label, action.payload.category.id, TypeFilter.TYPE_CATEGORY, !!action.payload.shouldBeDisplayed);
            if (index >= 0) {
                state.filters[index] = categoryObj;
            } else {
                state.filters.push(categoryObj);
            }
            state.getFilters = true;
            return state;
        },
        removeAllFilters(state) {
            state.filters    = initialState.filters;
            state.getFilters = true;
            return state;
        },
        removeAllFiltersExceptStocks(state) {
            const stockStore = state.filters.find(filter => filter.type === TypeFilter.TYPE_STOCK && filter.value === 'STOCK_STORE');
            state.filters    = [
                ...(stockStore ? [stockStore] : []),
            ];
            state.getFilters = true;
            return state;
        },
        removeAllFiltersExceptStock(state, action: PayloadAction<{ keepStockStoreFilter: boolean }>) {
            let stockStore = new FilterOptionModel(Utils.defineTypeStockLabel('STOCK_STORE'), 'STOCK_STORE', TypeFilter.TYPE_STOCK);
            if (action.payload.keepStockStoreFilter) {
                state.filters = [
                    ...initialState.filters,
                    stockStore,
                ];
            } else {
                state.filters = [
                    ...initialState.filters,
                ];
            }
            state.getFilters = true;
            return state;
        },
        applyFilters(state, action: PayloadAction<FilterOptionModel[]>) {
            const office     = state.filters.find(filter => filter !== null && filter?.type === TypeFilter.TYPE_OFFICE);
            const category   = state.filters.find(filter => filter !== null && filter?.type === TypeFilter.TYPE_CATEGORY);
            const oldFilter  = [...state.filters.filter(f => f)];
            state.filters    = [
                office,
                category,
                new FilterOptionModel('Page', 1, TypeFilter.TYPE_PAGE, false),
                new FilterOptionModel('Qty', 5, TypeFilter.TYPE_QTY, false),
                ...action.payload.filter(filter => filter !== null),
                ...oldFilter.filter(filter => filter.type === TypeFilter.TYPE_SORT),
            ]
            ;
            state.getFilters = true;
            return state;
        },
        handleFilterByText(state, action: PayloadAction<string>) {
            const stockStore = state.filters.find(filter => filter.type == TypeFilter.TYPE_STOCK && filter.value === 'STOCK_STORE');
            state.filters    = [
                ...(stockStore ? [stockStore] : []),
                new FilterOptionModel('Text Search', action.payload, TypeFilter.TYPE_TEXT_SEARCH, true),
            ];
            state.getFilters = true;
            return state;
        },
        removeFilter(state, action: PayloadAction<FilterOptionModel>) {
            switch (action.payload.type) {
                case TypeFilter.TYPE_SORT:
                    state.filters = state.filters.filter(filter => filter?.type !== TypeFilter.TYPE_SORT);
                    break;
                case TypeFilter.TYPE_TEXT_SEARCH:
                    state.filters = state.filters.filter(filter => filter?.type !== TypeFilter.TYPE_TEXT_SEARCH);
                    break;
                case TypeFilter.TYPE_STOCK:
                case TypeFilter.TYPE_PRODUCT:
                case TypeFilter.TYPE_RANGE_PRICE:
                    state.filters = state.filters.filter(filter => filter !== null && filter !== undefined && filter?.value !== action.payload.value);
                    break;
                case TypeFilter.TYPE_DYNAMIC_VALUE:
                    const dynamicFilters = state.filters.filter(filter => filter !== null && filter?.type === TypeFilter.TYPE_DYNAMIC_VALUE);
                    const generalFilters = state.filters.filter(filter => filter !== null && filter?.type !== TypeFilter.TYPE_DYNAMIC_VALUE);
                    const index          = dynamicFilters.findIndex(filter => Object.keys(filter.value)[0] === Object.keys(action.payload.value)[0]);
                    if (index > -1) {
                        dynamicFilters.splice(index, 1);
                    }
                    state.filters = [...generalFilters, ...dynamicFilters];
                    break;
                case TypeFilter.TYPE_CATEGORY:
                    state.filters = state.filters.filter(filter => filter?.type !== TypeFilter.TYPE_CATEGORY);
                    break;
            }
            /* If some filter is removed, came back to page 1 */
            const index   = state.filters.findIndex(filter => filter !== null && filter?.type === TypeFilter.TYPE_PAGE);
            const pageObj = new FilterOptionModel('Page', 1, TypeFilter.TYPE_PAGE, false);
            if (index >= 0) {
                state.filters[index] = pageObj;
            } else {
                state.filters.push(pageObj);
            }
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFilters.pending, (state) => {
            state.loadingFilters = true;
        });
        builder.addCase(getFilters.fulfilled, (state, action) => {
            state.loadingFilters  = false;
            state.rangePrices.min = action.payload.maxMin.min;
            state.rangePrices.max = action.payload.maxMin.max;
            state.filterOptions   = action.payload.filterAttributes.data;
            return state;
        });
    },
});

export const {
                 initFilters,
                 removeStockFilter,
                 removeAllFiltersExceptStock,
                 addStockFilter,
                 AddOrderFilter,
                 onChangePage,
                 onChangeCategory,
                 removeAllFilters,
                 applyFilters,
                 handleFilterByText,
                 removeFilter,
                 removeAllFiltersExceptStocks,
             } = filtersSlice.actions;

export default filtersSlice.reducer;
