export const ArrowsRotate = (props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.7603 0.000428954C17.4657 0.00618786 17.1852 0.128615 16.9809 0.34077C16.7767 0.552925 16.6651 0.837522 16.6708 1.13182V2.57956C14.8626 0.958464 12.5027 0.0154671 10.0018 0.0154671C4.49223 0.0154671 0.0041195 4.50883 0.0041195 10.0114V10.0116C-0.0226663 10.3211 0.082058 10.6278 0.29244 10.8568C0.502821 11.0859 0.79968 11.2161 1.11092 11.2161C1.42216 11.2161 1.71902 11.0859 1.9294 10.8568C2.13996 10.6278 2.2445 10.321 2.21772 10.0116C2.21772 5.70807 5.69316 2.23713 10.002 2.23713C12.0616 2.23713 13.9942 3.05861 15.4273 4.4566L13.3309 4.45642C13.032 4.44992 12.7431 4.5638 12.5294 4.77261C12.3157 4.98142 12.1953 5.26751 12.1953 5.56608C12.1953 5.86462 12.3157 6.15072 12.5294 6.35955C12.7432 6.56837 13.032 6.68244 13.3309 6.67593H17.7776C18.0722 6.67704 18.3553 6.56112 18.5646 6.35379C18.7737 6.14665 18.8918 5.86484 18.8931 5.57055V1.13159C18.899 0.829889 18.7815 0.538582 18.5677 0.325137C18.354 0.111693 18.0626 -0.00572962 17.7603 0.000215238L17.7603 0.000428954ZM18.8758 8.86302H18.8757C18.5795 8.86637 18.2968 8.98786 18.0907 9.2002C17.8844 9.41274 17.7719 9.69862 17.7776 9.99439C17.7776 14.2979 14.3109 17.7603 10.0019 17.7603C7.94141 17.7603 6.00745 16.9393 4.57426 15.5408H6.66189C7.0594 15.5408 7.42641 15.3292 7.62522 14.9855C7.82388 14.6418 7.82388 14.2182 7.62522 13.8746C7.42637 13.5309 7.05936 13.3193 6.66189 13.3193H2.22625C1.93124 13.3182 1.64777 13.4343 1.43849 13.642C1.22922 13.8499 1.11129 14.1321 1.11073 14.4269V18.8657C1.10422 19.1642 1.21843 19.4527 1.42751 19.6662C1.63659 19.8796 1.92284 20 2.2218 20C2.5209 20 2.80718 19.8796 3.01628 19.6662C3.22535 19.4527 3.33957 19.1642 3.33305 18.8657V17.4244C5.14258 19.0404 7.50493 19.9797 10.0021 19.9797C15.5117 19.9797 19.9998 15.4971 19.9998 9.99473V9.99455C20.0057 9.69433 19.8895 9.40434 19.6776 9.19104C19.4657 8.97795 19.1763 8.85961 18.8755 8.86315L18.8758 8.86302Z"
                  fill="#005EB8"/>
        </svg>
    );
};
