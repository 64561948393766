import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';

const OfficesAPI = {
    getOffices: () => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.get(`/offices`, {
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
};

export default OfficesAPI;
