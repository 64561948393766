import { DeliveryTypeEnum } from '../enums/DeliveryType.enum';
import Utils from '../utils/utils';
import CartItemModel from './cart-item.model';
import {CustomerModel} from './customer.model';
import Employee from './employees';
import {InvoiceModel} from './invoice.model';

type ObjCalculateTotalProps = {
    items: CartItemModel[],
    shipping: number,
    subtotal: number,
    discount: number,
    total: number,
    tax: number,
    totalMargin?: number,
    totalWeight?: number,
    totalWithoutTax: number,
}

export default class CartModel {
    id: number               = null;
    active                   = true;
    customer: CustomerModel  = null;
    items: CartItemModel[]   = [];
    tax                      = 0;
    total                    = 0;
    subtotal                 = 0;
    shipping                 = 0;
    discount                 = 0;
    totalWithoutTax          = 0;
    totalMargin              = 0;
    totalWeight              = 0;
    isDelivery               = false;
    shippingPriceManuallySet = false;
    shouldClose              = true;
    supervisorPendingApprove: Employee = null;
    supervisorPendingApproveStatus: 'approved' | 'rejected' | 'pending' | null = null;
    discount_request_id: number = null;

    constructor(props) {
        if (props) {
            this.id         = props.id || 1;
            this.items      = props.items ? props.items.map((item) => new CartItemModel({
                ...item,
                isModified: false,
            })) : [];
            this.active     = props.active !== undefined && props.active !== null ? props.active : true;
            this.isDelivery = props.isDelivery || false;
            this.discount   = props.discount || 0;
            this.supervisorPendingApprove = props.supervisorPendingApprove || null;
            this.supervisorPendingApproveStatus = props.supervisorPendingApproveStatus || null;
            this.discount_request_id = props.discount_request_id || null;
            this.addCustomer(props.customer || null);
            this.calculateTotal();
        }
    }

    removeItem(item: CartItemModel) {
        const itemIndex = this.items.findIndex(i => i.id === item.id);
        if (itemIndex !== -1) {
            this.items.splice(itemIndex, 1);
            if(this.items.length === 0) {
                this.setShippingPriceAndType(0, false, false);
            }
            this.calculateTotal();
        }
    }

    subtractOneFromItem(item: CartItemModel) {
        const itemIndex = this.items.findIndex(i => i.id === item.id);
        if (itemIndex !== -1) {
            this.items[itemIndex].subtractOne();
            this.calculateTotal();
        }
    }

    addItem(item: CartItemModel) {
        const itemIndex = this.items.findIndex(i => i.id === item.id);
        this.items.forEach(item => item.setIsModified(false));
        if (itemIndex !== -1) {
            const itemOld = this.items[itemIndex];
            itemOld.addOne();
            this.items = this.items.filter(i => i.id !== item.id);
            this.items.unshift(itemOld);
        } else {
            this.items.unshift(item);
        }
        this.calculateTotal();
    }

    setQtyFromItem(item: CartItemModel, qty) {
        const itemIndex = this.items.findIndex(i => i.id === item.id);
        if (itemIndex !== -1) {
            this.items[itemIndex].setQty(qty);
            this.calculateTotal();
        }
    }

    setLastSupplierSelection(cartItemId, supplier) {
        const itemIndex = this.items.findIndex(i => i.id === cartItemId);
        if (itemIndex !== -1) {
            this.items[itemIndex].setLastSupplierSelection(supplier);
            this.calculateTotal();
        }
    }

    setShippingPriceAndType(price: number, isDelivery = false, manuallySet = false) {
        if (isDelivery) {
            this.isDelivery = true;
            this.shipping   = price;
        } else {
            this.isDelivery = false;
            this.shipping   = 0;
        }
        this.shippingPriceManuallySet = manuallySet;

        const sale = JSON.parse(localStorage.getItem('pos.prodalam.sale_' + this.id));
        if(sale && sale.delivery) {
            sale.delivery.type = isDelivery ?  DeliveryTypeEnum.DELIVERY : DeliveryTypeEnum.PICKUP;
            localStorage.setItem('pos.prodalam.sale_' + this.id, JSON.stringify(sale));
        }

        this.calculateTotal();
    }

    updateItemDiscount(item: CartItemModel, discount: number) {
        const itemIndex = this.items.findIndex(i => i.id === item.id);
        if (itemIndex !== -1) {
            this.items[itemIndex].setDiscount(discount, this.items[itemIndex].quantity);
            this.items[itemIndex].setAuthSupervisorAndDate(item.authorized_by, item.authorized_date);
            this.items[itemIndex].setDiscountPercentageApplied(item.discount_percentage_applied);
            this.items[itemIndex].setDiscountApproved(item.discountMaxApproved);
            this.items[itemIndex].setPendingApprove(item.pending_approve);
            this.items[itemIndex].setPendingApproveDiscount(item.pending_approve_discount);
            this.items[itemIndex].setPendingApproveDiscountPct(item.pending_approve_discount_pct);
            this.items[itemIndex].setPendingApproveQty(item.pending_approve_qty);
            this.calculateTotal();
        }
    }

    removeApprovedDiscount(item: CartItemModel) {
        const itemIndex = this.items.findIndex(i => i.id === item.id);
        if (itemIndex !== -1) {
            this.items[itemIndex].setDiscount(0, 0);
            this.items[itemIndex].setDiscountPercentageApplied(0);
            this.items[itemIndex].removeSupervisorAndDate();
            this.items[itemIndex].setDiscountApproved(0);
            this.calculateTotal();
        }
    }
    removeAllApprovedDiscounts() {
        this.items.forEach(item => {
            if (item.discount_percentage_applied > item.discountMaxEmployee) {
                item.setDiscount(0, 0);
                item.setDiscountPercentageApplied(0);
                item.removeSupervisorAndDate();
                item.setDiscountApproved(0);
            }
        });
        this.calculateTotal();
    }

    setIsDelivery(isDelivery) {
        this.isDelivery = isDelivery;
    }

    addCustomer(customer) {
        this.customer = customer ? new CustomerModel(customer) : null;
    }

    updateCustomerBillingData(customer) {
        this.customer.invoice_data = new InvoiceModel(customer);
    }

    removeCustomer() {
        if (this.customer) {
            this.customer = null;
            this.removeAllApprovedDiscounts()
        }
    }

    emptyCart() {
        this.items = [];
        this.calculateTotal();
    }

    calculateTotal() {
        CartModel.calculateTotals(this);
    }

    static calculateTotals(obj: ObjCalculateTotalProps) {
        const tax       = Utils.get_tax();
        let total       = obj.shipping / tax;
        let subtotal    = 0;
        let discount    = 0;
        let totalCost   = 0;
        let totalWeight = 0;

        obj.items.forEach((item) => {
            const discountFactor       = 1 - (item.discount_percentage_applied / 100);
            const netPrice             = item.product.net_price;
            const netPriceWithDiscount = Math.round(netPrice * discountFactor); // this is the price which is sent to sap
            subtotal += (netPrice * item.quantity);
            total    += (netPriceWithDiscount * item.quantity);
            const _discount = item.discount;
            if(_discount > 0) {
                discount += _discount;
            }
            totalCost   += item.product.sap_cost > 1 ? (item.product.sap_cost * item.quantity) : 0;
            totalWeight += item.product.weight * item.quantity;
        });

        obj.totalMargin = Utils.roundNumber(totalCost === 0 ? 0 : (1 - (totalCost / total)) * 100, 2);
        obj.totalWeight = Utils.roundNumber(totalWeight, 2);

        obj.totalWithoutTax = Math.round(total);
        obj.subtotal        = Math.round(subtotal * tax);
        obj.discount        = Math.round(discount);
        obj.total           = Math.round(total * tax);
        obj.tax             = Math.round(total * (tax-1));
    }

    setModifiedItems() {
        this.items.forEach(item => item.setIsModified(false));
    }

    setWithdrawNowForItem(item: CartItemModel, qty) {
        const itemIndex = this.items.findIndex(i => i.id === item.id);
        if (itemIndex !== -1) {
            this.items[itemIndex].setWithdrawNow(qty);
        }
    }

    updateItem(product_id: number, sap_stock: number, stock_cds: number, sap_price: number, sap_cost: number) {
        const itemIndex = this.items.findIndex(i => i.id === product_id);
        if (itemIndex !== -1) {
            this.items[itemIndex].updateItem(sap_stock, stock_cds, sap_price, sap_cost);
        }
    }

    setShouldClose(value) {
        this.shouldClose = value;
    }

    setAllProductIsModified(value) {
        setTimeout(_ => this.items.forEach(item => item.setIsModified(value)));
    }

    setProductIsModified(productIds) {
        productIds.forEach(id => this.items.find(i => i.id === id).setIsModified(true));
    }

    setLoadedCustomer(rut) {
        this.customer?.setLoaded(rut);
    }

    setSuppliers() {
        this.items.forEach(item => {
            item.setSupplier();
        });
    }

    setSupervisorPendingApprove(supervisor: Employee) {
        this.supervisorPendingApprove = supervisor;
        this.setSupervisorPendingApproveStatus(supervisor ? 'pending' : null);
    }

    setDiscountRequestId(discount_request_id: number) {
        this.discount_request_id = discount_request_id;
    }

    setSupervisorPendingApproveStatus(status: 'approved' | 'rejected' | 'pending' | null) {
        this.supervisorPendingApproveStatus = status;
    }

    removePendingItems() {
        this.items.forEach(item => {
            item.resetPendingApprove();
        })
    }
}

