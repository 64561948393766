export const SeguridadPerimetralIcon = (props) => {
    return (
        <svg
            width={42}
            height={31}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g clipPath="url(#icono-seguridadperimetral_svg__clip0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.292 3l-4.026 2.162v2.895h.997v2.651h-2.751V9.24h-1.396v1.468H24.05l-.04-1.468h-1.395l.04 1.468h-3.39V9.321H17.87v1.387h-3.867V9.321h-1.395v1.387h-2.352v-2.65h.996V4.753L7.266 3 3 4.754v3.303h.757v14.845a.534.534 0 00-.518.53v4.079c0 .285.24.53.518.53h6.499c.279 0 .518-.245.518-.53v-3.67h1.834v3.18h1.395v-3.18h3.867v3.18h1.396v-3.18h3.548l.04 3.18h1.395l-.04-3.18h3.907v3.18h1.396v-3.18h1.953v3.67c0 .285.24.53.518.53h6.499c.279 0 .518-.245.518-.53v-4.079c0-.285-.24-.53-.518-.53h-.24V8.057h.718V5.161L35.292 3zM4.674 24.33H9.38v2.243H4.674v-2.244zm4.186-1.428V8.057H5.153v14.845H8.86zm3.748-.53h-2.352V12.135h2.352v10.237zm5.262 0h-3.867V12.135h3.867v10.237zm4.944 0h-3.548V12.135h3.389l.159 10.237zm5.302 0H24.21l-.16-10.237h4.067v10.237zm4.186 0h-2.75V12.135h2.75v10.237zm.598 1.957h4.705v2.244H32.9v-2.244zm3.987-1.427V8.057h-3.19v14.845h3.19zm.718-17.088v.816H32.7v-.775l2.591-1.346 2.313 1.305zm-27.748.04v.776H4.395v-.775l2.87-1.346 2.592 1.346z"/>
                <path
                    d="M13.485 6.263l1.714 3.058h-3.428l1.714-3.058zM18.588 6.263l1.714 3.058h-3.468l1.754-3.058zM23.532 6.263l1.714 3.058h-3.469l1.755-3.058zM28.834 6.263l1.714 3.058H27.08l1.754-3.058z"/>
            </g>
            <defs>
                <clipPath id="icono-seguridadperimetral_svg__clip0">
                    <path transform="translate(3 3)" d="M0 0h36v25H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};
