import axios from 'axios';
import AuthService from './auth';

const AxiosInstance = axios.create({
    baseURL: `${process.env.SERVER_BASE_URL}`,
    responseType: 'json',
});

AxiosInstance.interceptors.request.use(
    value => {
        const versionHeader: any = document?.head?.querySelector("[name=version-info]");
        value.headers.Version = versionHeader?.content;
        const mm_bypass = localStorage.getItem('mm_bypass');
        if (mm_bypass) {
            value.headers['X-MM-Bymass'] = mm_bypass;
        }
        return value;
    },
    error => {
        return Promise.reject(error);
    },
);

AxiosInstance.interceptors.response.use(
    (response) => {
        check_maintenance_mode(response?.data?.maintenance_mode);
        return response;
    },
    function (error) {
        const remember_me   = localStorage.getItem('remember_me');
        let originalRequest = error.config;

        if (JSON.parse(remember_me) === true && error.response.status === 401 && !originalRequest._retry) {
            const authService      = new AuthService();
            originalRequest._retry = true;

            authService.tokenRefresh().then(
                (response) => {
                    if (response.status === 200) {
                        const auth = response.data;
                        authService.setAuth(response.data);
                        /*Override header from last call with new credentials*/
                        originalRequest.headers = {
                            'Authorization': `${auth?.auth.token_type} ${auth?.auth.access_token}`,
                            'Content-Type': 'application/json',
                        };
                        return AxiosInstance(originalRequest);
                    }
                },
            );
        }

        return Promise.reject(error);
    },
);

const maintenance_mode_url = '/maintenance-mode';
function check_maintenance_mode(request_maintenance_mode: boolean) {
    if (request_maintenance_mode && !window.location.pathname.startsWith('/maintenance-mode')) {
        window.location.href = maintenance_mode_url;
    }
}

export default AxiosInstance;
