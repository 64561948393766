const CommentIcon = (props) => {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8 0.972656C3.58125 0.972656 0.0281251 3.88266 0.0281251 7.47266C0.0281251 9.02328 0.695313 10.4408 1.80844 11.557C1.41781 13.1311 0.1125 14.5342 0.0968751 14.5498C0.0265626 14.6202 0.00703138 14.729 0.0500001 14.8228C0.0890314 14.9165 0.175656 14.9717 0.277219 14.9717C2.34753 14.9717 3.87409 13.9792 4.67097 13.3658C5.69066 13.7486 6.82784 13.9717 8.02722 13.9717C12.446 13.9717 15.9991 11.0614 15.9991 7.49984C15.9991 3.93828 12.4188 0.972656 8 0.972656ZM3.97188 8.47266C3.41719 8.47266 2.97188 8.02734 2.97188 7.50078C2.97188 6.97422 3.41719 6.50078 3.97188 6.50078C4.52656 6.50078 4.97188 6.94609 4.97188 7.50078C4.97188 8.05547 4.55313 8.47266 3.97188 8.47266ZM8 8.47266C7.44531 8.47266 7.02813 8.02734 7.02813 7.50078C7.02813 6.97422 7.47344 6.50078 8 6.50078C8.52656 6.50078 8.97188 6.94609 8.97188 7.50078C8.97188 8.05547 8.55625 8.47266 8 8.47266ZM11.9719 8.47266C11.4172 8.47266 10.9719 8.02734 10.9719 7.50078C10.9719 6.97422 11.4172 6.50078 11.9719 6.50078C12.5266 6.50078 12.9719 6.94609 12.9719 7.50078C12.9719 8.05547 12.5531 8.47266 11.9719 8.47266Z"
                fill="#005EB8"/>
        </svg>
    );
};

export default CommentIcon;