import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import CashRegister from '../../models/cash-register';

interface cashRegisterState {
    cashRegister: CashRegister,
    opened: boolean,
    openModal: boolean
}

const initialState = {
    cashRegister: null,
    opened: false,
    openModal: false,
} as cashRegisterState;

export const cashRegisterSlice = createSlice({
    name: 'cash-register',
    initialState,
    reducers: {
        initCashRegister(state, action: PayloadAction<CashRegister>) {
            state.cashRegister = action.payload;
            state.opened       = true;
            state.openModal    = false;
            return state;
        },
        finishCashRegister(state) {
            state.cashRegister = null;
            state.opened       = false;
            state.openModal    = false;
            return state;
        },
        openModal(state) {
            state.openModal = true;
            return state;
        },
        closeModal(state) {
            state.openModal = false;
            return state;
        },
        showModal(state, action: PayloadAction<boolean>) {
            state.openModal = action.payload;
            return state;
        },
        updateCashRegister(state, action: PayloadAction<CashRegister>) {
            state.cashRegister = action.payload;
            return state;
        },
    },
});

export const {
                 initCashRegister,
                 finishCashRegister,
                 openModal,
                 showModal,
                 updateCashRegister,
             } = cashRegisterSlice.actions;

export default cashRegisterSlice.reducer;
