interface IWireTransferIcon {
    className: string;
}

export const WireTransferIcon = (props: IWireTransferIcon) => {
    return (
        <svg className={`${props.className}`} width="29" height="28" viewBox="0 0 29 28"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.83337 9.33334V14.0012H4.00004V21H2.83337V24.5H21.5L25 24.5012V24.5H26.1667V21H25V14.0012H26.1667V9.33334L14.5 2.33334L2.83337 9.33334ZM7.50004 21V14.0012H9.83337V21H7.50004ZM13.3334 21V14.0012H15.6667V21H13.3334ZM21.5 21H19.1667V14.0012H21.5V21ZM16.8334 9.33334C16.8333 9.63983 16.7729 9.94331 16.6555 10.2264C16.5381 10.5096 16.3662 10.7668 16.1494 10.9835C15.9326 11.2002 15.6753 11.372 15.3921 11.4892C15.1089 11.6065 14.8054 11.6667 14.4989 11.6667C14.1924 11.6666 13.8889 11.6061 13.6058 11.4888C13.3226 11.3714 13.0654 11.1994 12.8487 10.9827C12.632 10.7659 12.4602 10.5086 12.343 10.2254C12.2258 9.94217 12.1655 9.63866 12.1655 9.33217C12.1657 8.71318 12.4117 8.1196 12.8495 7.68201C13.2873 7.24443 13.881 6.99868 14.5 6.99884C15.119 6.99899 15.7126 7.24503 16.1502 7.68284C16.5878 8.12064 16.8335 8.71434 16.8334 9.33334Z"
            />
        </svg>
    );
};
