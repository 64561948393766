export const GeneralFilterIcon = (props) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="20" height="20" transform="translate(0.5)" fill="white" fillOpacity="0.01"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4.5 3.9375C4.5 3.41973 4.91574 3 5.42857 3H16.5714C17.0843 3 17.5 3.41973 17.5 3.9375V6.74999C17.5 6.99863 17.4022 7.23709 17.228 7.4129L12.8571 11.8258V15.1875C12.8571 15.4361 12.7593 15.6746 12.5852 15.8504L10.728 17.7254C10.4625 17.9935 10.0631 18.0737 9.71608 17.9286C9.3691 17.7835 9.14286 17.4417 9.14286 17.0625V11.8258L4.77197 7.4129C4.59783 7.23709 4.5 6.99863 4.5 6.74999V3.9375Z"
                  />
        </svg>
    );
};
