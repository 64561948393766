export class FilterOptionModel {
    label: string              = null;
    value: any                 = null;
    type: TypeFilter           = null;
    shouldBeDisplayed: boolean = true;
    key: string                = null;
    attributeCode: string      = null;

    constructor(label: string, value: any, type: TypeFilter, shouldBeDisplayed?: boolean, key?: string, attributeCode: string = null) {
        this.label             = label;
        this.value             = value;
        this.type              = type;
        this.shouldBeDisplayed = shouldBeDisplayed ?? true;
        this.key               = key ?? null;
        this.attributeCode     = attributeCode || null;
    }
}

export enum TypeFilter {
    TYPE_STOCK         = 'TYPE_STOCK',
    TYPE_SORT          = 'TYPE_SORT',
    TYPE_PRODUCT       = 'TYPE_PRODUCT',
    TYPE_RANGE_PRICE   = 'TYPE_RANGE_PRICE',
    TYPE_DYNAMIC_VALUE = 'TYPE_DYNAMIC_VALUE',
    TYPE_OFFICE        = 'TYPE_OFFICE',
    TYPE_PAGE          = 'TYPE_PAGE',
    TYPE_QTY           = 'TYPE_QTY',
    TYPE_CATEGORY      = 'TYPE_CATEGORY',
    TYPE_TEXT_SEARCH   = 'TYPE_TEXT_SEARCH',
}
