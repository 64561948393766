// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.APP_ENV || 'local';

if (ENV !== 'local') {
    Sentry.init({
        dsn: SENTRY_DSN || 'https://747109df35aa48d2a9b2566d727ee6b2@o558618.ingest.sentry.io/6314163',
        // Adjust this value in production, or use tracesSampler for greater control
        environment: ENV,
        tracesSampleRate: 0.1,
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}
