import ProductModel from './product.model';
import Employee from './employees';
import Utils from '../utils/utils';

export const DEFAULT_DISCOUNT_LIMIT = 10;
export const MAX_DISCOUNT_LIMIT = 90;

class CartItemModel {
    id: number                          = null;
    product: ProductModel               = null;
    quantity                            = 1;
    total: number                       = null;
    discount: number                    = 0;
    isModified                          = false;
    discount_percentage_applied: number = 0;
    discountMaxApproved                 = 0;
    discountMaxEmployee                 = 0;
    authorized_by: Employee             = null;
    authorized_date: string             = null;
    margin: number                      = 0; // Modal Discount
    margin_with_discount: number        = 0; // Modal Discount
    price_web_matched: boolean          = false;
    modifiedDiscount                    = false;
    withdraw_now                        = 0;
    supply_from                         = null;
    last_supplier_selection             = null;
    last_margin_matched                 = false;
    discountedQuantity                  = 0;
    pending_approve                     = false;
    pending_approve_discount: number | null     = 0;
    pending_approve_discount_pct: number | null = 0;
    pending_approve_qty: number | null          = 0;

    constructor(props) {
        if (props) {
            this.id                          = props.product.id || null;
            this.product                     = props.product && new ProductModel(props.product) || null;
            this.quantity                    = props.quantity || 1;
            this.discount                    = props.discount || 0;
            this.discount_percentage_applied = props.discount_percentage_applied || 0;
            this.discountMaxApproved         = props.discountMaxApproved || 0;
            this.discountMaxEmployee         = props.discountMaxEmployee || DEFAULT_DISCOUNT_LIMIT;
            this.isModified                  = props.isModified || false;
            this.authorized_by               = props.authorized_by ? new Employee(props.authorized_by) : null;
            this.authorized_date             = props.authorized_date || null;
            this.withdraw_now                = props.withdraw_now || 0;
            this.discountedQuantity          = props.discountedQuantity || 0;
            this.pending_approve             = props.pending_approve || false;
            this.pending_approve_discount    = props.pending_approve_discount || 0;
            this.pending_approve_discount_pct = props.pending_approve_discount_pct || 0;
            this.pending_approve_qty         = props.pending_approve_qty || 0;
            this.calculateTotal();
            this.checkSupplier();
        }
    }

    checkSupplier() {
        let value = null;

        if (this.product.sap_stock > 0 && this.product.stock_cds > 0 && this.quantity > this.product.sap_stock && this.quantity <= this.product.sap_stock + this.product.stock_cds) {
            value = 'BOTH';
        } else if (this.product.sap_stock > 0 && this.quantity <= this.product.sap_stock) {
            value = 'STORE';
        } else if (this.product.stock_cds > 0 &&  this.quantity <= this.product.stock_cds) {
            value = 'CDS'
        }

        this.supply_from             = value;
        this.last_supplier_selection = value;
    }

    addOne() {
        this.quantity += 1;
        this.calculateTotal();
        this.isModified = true;
    }

    subtractOne() {
        this.quantity = this.quantity !== 1 ? this.quantity - 1 : this.quantity;
        this.calculateTotal();
    }

    calculateTotal() {
        this.total = CartItemModel.calculateDiscount(this, 'pctToValue', 100);
        return this.total;
    }

    setQty(qty) {
        this.quantity = qty === 0 ? '0' : qty;
        this.calculateTotal();
        this.discount = CartItemModel.calculateDiscount(this, 'pctToValue', this.discount_percentage_applied);
        this.checkSupplier();
    }

    setLastSupplierSelection(supplier) {
        this.last_supplier_selection = supplier;
    }

    setSupplier() {
        this.supply_from = this.last_supplier_selection;
    }

    static calculateDiscount(item: CartItemModel, convert: 'pctToValue' | 'valueToPct', value: number) {
        if(convert === 'pctToValue') {
            return Math.round(Math.round(item.product.net_price * value / 100) * item.quantity * Utils.get_tax());
        }
        if(convert === 'valueToPct') {
            return value / Utils.get_tax() * 100 / item.product.net_price / item.quantity;
        }
        return 0;
    }

    setLastMarginMatched(matched) {
        this.last_margin_matched = matched;
    }

    setDiscount(discount, qty) {
        this.discount           = discount;
        this.discountedQuantity = qty;
    }

    setDiscountPercentageApplied(discountPercentage) {
        this.discount_percentage_applied = discountPercentage;
    }

    setDiscountMaxEmployee(discountMaxEmployee) {
        this.discountMaxEmployee = discountMaxEmployee;
    }

    setDiscountApproved(discountMaxApproved) {
        this.discountMaxApproved = discountMaxApproved;
    }

    setAuthSupervisorAndDate(supervisor, date) {
        this.authorized_by   = supervisor;
        this.authorized_date = date;
    }

    removeSupervisorAndDate() {
        this.authorized_by   = null;
        this.authorized_date = null;
    }

    setIsModified(value: boolean) {
        this.isModified = value;
    }

    setMargin(margin: number) {
        this.margin = margin;
    }

    setMarginWithDiscount(marginWithDiscount: number) {
        this.margin_with_discount = marginWithDiscount;
    }

    setPriceWebMatched(matched: boolean) {
        this.price_web_matched = matched;
    }

    setModifiedDiscount(modified: boolean) {
        this.modifiedDiscount = modified;
    }

    setWithdrawNow(qty: number) {
        this.withdraw_now = qty;
    }

    updateItem(sap_stock: number, stock_cds: number, sap_price: number, sap_cost) {
        this.product.setSapStock(sap_stock);
        this.product.setStockCDS(stock_cds);
        this.product.setSapPrice(sap_price);
        this.product.setSapCost(sap_cost);
        this.calculateTotal();
    }

    calculateMargin() {
        const priceWithOutIVA = this.product.sap_price / 1.19;
        return Number(((priceWithOutIVA - this.product.sap_cost) / priceWithOutIVA) * 100);
    }

    resetPendingApprove() {
        this.setPendingApprove(false);
        this.setPendingApproveDiscount(null);
        this.setPendingApproveDiscountPct(null);
        this.setPendingApproveQty(null);
    }

    setPendingApprove(value: boolean) {
        this.pending_approve = value;
    }

    setPendingApproveDiscount(value: number | null) {
        this.pending_approve_discount = value;
    }

    setPendingApproveDiscountPct(value: number | null) {
        this.pending_approve_discount_pct = value;
    }

    setPendingApproveQty(value: number | null) {
        this.pending_approve_qty = value;
    }

}

export default CartItemModel;
