export const WithdrawIcon = (props) => {
    return (
        <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18.2678 9.9186L18.1677 9.60961C18.0321 9.1866 17.5754 8.95383 17.1539 9.09025L9.01436 11.7286C8.94697 11.7501 8.88863 11.7793 8.83439 11.8116C8.47651 11.3678 7.97358 11.0451 7.39073 10.9181C7.3743 10.8308 7.35005 10.7344 7.31677 10.6323L4.41343 1.69239C4.2764 1.27082 3.8162 0.823569 3.39072 0.697837L1.12508 0.0326069C0.699811 -0.0925089 0.249477 0.153614 0.124156 0.579501L0.0327328 0.890544C-0.0925884 1.31602 0.153123 1.76656 0.578805 1.89147L1.86098 2.26908C2.28646 2.39358 2.74645 2.84103 2.88389 3.26261L5.38785 10.9653C5.42113 11.067 5.46222 11.1587 5.50804 11.2392C4.82945 11.6577 4.35364 12.3846 4.29427 13.2392C4.19668 14.6461 5.26171 15.8705 6.66921 15.9679C8.07158 16.0657 9.29089 15.0075 9.39649 13.6082C9.47045 13.6039 9.54708 13.5924 9.62741 13.5663L17.7478 10.9347C18.17 10.7975 18.4038 10.3412 18.2678 9.9186ZM6.77008 14.5185C6.16176 14.4764 5.70198 13.9476 5.74409 13.3395C5.78641 12.7311 6.31502 12.2714 6.92293 12.3135C7.53064 12.3556 7.99084 12.8842 7.94851 13.4925C7.9066 14.1002 7.37779 14.5604 6.77008 14.5185Z"/>
            <path
                d="M8.24981 9.95129C8.38602 10.3729 8.84232 10.6071 9.2643 10.4705L15.828 8.35458C16.25 8.21857 16.484 7.76208 16.3478 7.33989L14.6248 1.98703C14.4883 1.56443 14.0325 1.33207 13.6105 1.46992L12.2319 1.92067C11.8106 2.05893 11.5778 2.51687 11.714 2.93844L11.8975 3.50465C12.0343 3.92601 11.8009 4.38272 11.3787 4.51872L10.5438 4.78724C10.1218 4.92283 9.66451 4.68821 9.52809 4.26685L9.34463 3.70023C9.20821 3.27866 8.75069 3.04322 8.32829 3.17799L7.04694 3.586C6.62413 3.72036 6.38972 4.17583 6.52572 4.59802L8.24981 9.95129Z"/>
            <path
                d="M10.0224 3.6648C10.1135 3.94646 10.4165 4.10034 10.6971 4.00666L10.7645 3.98467C11.0447 3.89181 11.2003 3.58447 11.1097 3.30321L10.9712 2.87199C10.8806 2.59012 10.5771 2.43583 10.2965 2.52971L10.2293 2.55211C9.94869 2.64559 9.79337 2.95252 9.88356 3.23336L10.0224 3.6648Z"/>
        </svg>
    );
};
