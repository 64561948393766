import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';

const ProductsAPI = {
    getProducts: (params: any, currentPage?: number, currentOffice?: number) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.get(`/products`, {
            params: {
                ...params,
                page: currentPage,
                qty: 5,
                o: currentOffice ?? 2,
            },
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
    getProductsByParams: (params: any) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.get(`/products`, {
            params: {
                ...params,
            },
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
    getProductsByCode: (code: String, params: any) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.get('/products', {
            params: {
                code: code,
                ...params,
            },
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
    getProductById: (id: number, params?: any) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.get('/products/' + id, {
            params: params,
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
    getProductsFilterData: (office, products_id = [], code?) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.post('/products/filters', JSON.stringify({
            o: office,
            code: code,
            products_id: products_id,
        }), {
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
    getProductGroupFilterData: (code: string | string[], products_id: number[] = null) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.get(`/products/group/${code}/filters`, {
            params: products_id && products_id !== void 0 ? {products_id: products_id.join('|')} : null,
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
    getGroupedProduct: (code, params) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.get(`/products/group/${code}`, {
            params: params,
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
    updateProductsStock: (data: any) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.post(`/products/stock`, data, {
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
    checkProductsGroupedStock: (data: any) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.post(`/products/stock/grouped`, data, {
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
};

export default ProductsAPI;
export const fetcher     = (params?) => url => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    if (params) {
        return AxiosInstance.get(url, {
            params: params,
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        }).then(res => res.data);
    } else {
        return AxiosInstance.get(url, {
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        }).then(res => res.data);
    }

};
export const postFetcher = data => url => {
    const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);
    return AxiosInstance.post(url, data, {
        headers: {
            'Authorization': `${auth?.token_type} ${auth?.access_token}`,
            'Content-Type': 'application/json',
        },
    }).then(res => res.data);
};
