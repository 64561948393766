import {createSlice} from '@reduxjs/toolkit';
import {ICity} from '../../interfaces/city';
import {GetCities} from '../../services/cities.service';


interface ICitySlice {
    data: ICity[],
    isLoading: boolean;
}

const initialState: ICitySlice = {
    data: [],
    isLoading: false,
};

export const citiesSlice = createSlice({
    name: 'cities',
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder.addCase(GetCities.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(GetCities.fulfilled, (state, action) => {
            state.data      = [...action.payload];
            state.isLoading = false;
        });
    }),
});

export default citiesSlice.reducer;
