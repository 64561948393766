import {createAsyncThunk} from '@reduxjs/toolkit';
import CategoriesAPI from '../pages/api/categories';

export const getCategories = createAsyncThunk(
    'categories/get',
    async (officeId: number) => {
        const response = await CategoriesAPI.getCategories(officeId);
        return response && response.data && response.data.data || [];
    }
);

export const getCategoriesTree = createAsyncThunk(
    'categories/tree',
    async (productsId: number[]) => {
        const response = await CategoriesAPI.getCategoryTree(productsId);
        return response && response.data && response.data || [];
    }
);
