class ProductModel {
    id: number                     = null;
    code: string                   = null;
    type: string                   = null;
    title: string                  = null;
    brand: string                  = null;
    image: string                  = null;
    price: number                  = null;
    price_before: number           = null;
    sap_price: number              = null;
    net_price: number              = null;
    sap_cost: number               = null;
    margin_min: number             = null;
    stock: number                  = null;
    sap_stock: number              = null;
    stock_cds: number              = null;
    supply_center_name: string     = null;
    margin: number                 = null;
    weight: number                 = null;
    group_members_quantity: number = null;
    qty_selected?: number          = null;
    variant_summary?: object       = null;

    constructor(props) {
        if (props) {
            this.id                     = props.id || null;
            this.code                   = props.code || null;
            this.type                   = props.type || null;
            this.title                  = props.title || null;
            this.image                  = props.image || null;
            this.stock                  = props.stock || null;
            this.sap_stock              = props.sap_stock || null;
            this.stock_cds              = props.stock_cds || null;
            this.supply_center_name     = props.supply_center_name || null;
            this.sap_cost               = props.sap_cost || null;
            this.price                  = props.price || null;
            this.price_before           = props.price_before || null;
            this.margin_min             = props.margin_min || null;
            this.sap_price              = props.sap_price || null; // net price + tax
            this.net_price              = props.net_price || Math.round(props.sap_price / 1.19); // unitary net price
            this.group_members_quantity = props.group_members_quantity || null;
            this.qty_selected           = props.qty_selected || null;
            this.variant_summary        = props.variant_summary || null;
            this.weight                 = props.weight || null;
            this.calculateMargin();
        }
    }
    setSapStock(qty: number) {
        this.sap_stock = qty;
    }
    setStockCDS(qty: number) {
        this.stock_cds = qty;
    }
    setSapPrice(price: number) {
        this.sap_price = price;
        this.calculateMargin();
    }

    setSapCost(cost: number) {
        this.sap_cost = cost;
        this.calculateMargin();
    }

    calculateMargin() {
        if (this.sap_price && this.sap_cost) {
            this.margin = Number(((1 - (this.sap_cost / (this.sap_price / 1.19))) * 100).toFixed(2));
        }
    }

}

export default ProductModel;
