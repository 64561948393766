export const SearchIcon = (props) => {
    if (props.size === 'lg') {
        return (
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M53.2656 46.6865L42.7496 36.1722C42.275 35.6977 41.6316 35.434 40.9565 35.434H39.2372C42.1484 31.7114 43.8782 27.029 43.8782 21.9354C43.8782 9.81818 34.0583 0 21.9391 0C9.81986 0 0 9.81818 0 21.9354C0 34.0525 9.81986 43.8707 21.9391 43.8707C27.0336 43.8707 31.7168 42.1412 35.4401 39.2305V40.9495C35.4401 41.6245 35.7038 42.2677 36.1784 42.7423L46.6944 53.2565C47.6859 54.2478 49.2892 54.2478 50.2701 53.2565L53.2551 50.272C54.2465 49.2807 54.2465 47.6778 53.2656 46.6865ZM21.9391 35.434C14.4819 35.434 8.43812 29.4018 8.43812 21.9354C8.43812 14.4794 14.4714 8.43668 21.9391 8.43668C29.3963 8.43668 35.4401 14.4689 35.4401 21.9354C35.4401 29.3913 29.4068 35.434 21.9391 35.434Z"
                    fill="#005EB8"/>
            </svg>
        );
    }
    return (
        <svg
            width={18}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M12.5 11h-.79l-.28-.27A6.47 6.47 0 0013 6.5 6.5 6.5 0 106.5 13c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L17.49 16l-4.99-5zm-6 0C4.01 11 2 8.99 2 6.5S4.01 2 6.5 2 11 4.01 11 6.5 8.99 11 6.5 11z"
                fill="#005EB8"/>
        </svg>
    );
};
