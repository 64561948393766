import {Auth, ConvertAuth} from '../../interfaces';
import AxiosInstance from './axios';

const PaymentOptionsAPI = {
    getOfficePaymentOptions: (id: number) => {
        const auth: Auth = ConvertAuth.toAuth(localStorage.getItem('auth')!);

        return AxiosInstance.get(`/offices/${id}/payment-options`, {
            headers: {
                'Authorization': `${auth?.token_type} ${auth?.access_token}`,
                'Content-Type': 'application/json',
            },
        });
    },
};

export default PaymentOptionsAPI;
