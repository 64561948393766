export const SpecificationIcon = (props) => {
    return (
        <svg
            width={25}
            height={25}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M24.4 10.2l-2.2-.3c-.2-.9-.6-1.7-1-2.4l1.4-1.8c.2-.3.2-.7-.1-.9l-2.3-2.3c-.2-.2-.7-.3-.9-.1l-1.8 1.4c-.7-.4-1.5-.8-2.4-1L14.8.6c0-.3-.4-.6-.7-.6h-3.3c-.3 0-.7.3-.7.6l-.2 2.3c-.9.2-1.7.6-2.4 1L5.7 2.5c-.2-.3-.6-.2-.9 0L2.5 4.8c-.2.3-.3.7 0 .9l1.4 1.8c-.4.7-.8 1.5-1 2.4l-2.2.3c-.3 0-.6.4-.6.7v3.3c0 .3.3.7.6.7l2.2.3c.2.8.6 1.7 1 2.4l-1.4 1.8c-.2.3-.2.7.1.9l2.3 2.3c.2.2.7.3.9.1l1.8-1.4c.7.4 1.5.8 2.4 1l.3 2.2c0 .3.4.6.7.6h3.3c.3 0 .7-.3.7-.6l.3-2.2c.9-.2 1.7-.6 2.4-1l1.8 1.4c.3.2.7.2.9-.1l2.3-2.3c.2-.2.3-.7.1-.9l-1.4-1.8c.4-.7.8-1.5 1-2.4l2.2-.3c.3 0 .6-.4.6-.7v-3.3c-.2-.4-.5-.7-.8-.7zm-11.9 8.6c-3.5 0-6.3-2.8-6.3-6.3S9 6.3 12.5 6.3s6.2 2.8 6.2 6.3-2.7 6.2-6.2 6.2z"
                fill="#005EB8"/>
        </svg>
    );
};
