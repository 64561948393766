import {ReactNode, createContext, useContext, useEffect, useState} from 'react';
import {Category} from '../interfaces';

export const MegaMenuContext = createContext<IMegaMenuContext>({});

interface IMegaMenuContext {
    isAuthenticated?: ReactNode,
    setInitialCategory?: any,
    initialCategory?: any,
    sections?: Category[] | undefined,
    setSections?: any,
    openMegaMenu?: boolean,
    setOpenMegaMenu?: any,
}

interface FilterProviderProps {
    children: ReactNode
}

const MegaMenuProvider = ({children}: FilterProviderProps) => {

    const [initialCategory, setInitialCategory] = useState<Category>();
    const [sections, setSections]               = useState<Category[]>();

    useEffect(() => {
    }, [initialCategory]);

    return (
        <MegaMenuContext.Provider value={{
            setInitialCategory,
            initialCategory,
            sections,
            setSections,
        }}>
            {children}
        </MegaMenuContext.Provider>
    );
};

export default MegaMenuProvider;

export const useMegaMenu = () => useContext(MegaMenuContext);
